@font-face {
  font-family: "Phosphor-Duotone";
  src: url("./Phosphor-Duotone.woff2") format("woff2"),
    url("./Phosphor-Duotone.woff") format("woff"),
    url("./Phosphor-Duotone.ttf") format("truetype"),
    url("./Phosphor-Duotone.svg#Phosphor-Duotone") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ph-duotone {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Phosphor-Duotone" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ph-duotone.ph-address-book:before {
  content: "\e900";
  opacity: 0.2;
}
.ph-duotone.ph-address-book:after {
  content: "\e901";
  margin-left: -1em;
}
.ph-duotone.ph-airplane:before {
  content: "\e902";
  opacity: 0.2;
}
.ph-duotone.ph-airplane:after {
  content: "\e903";
  margin-left: -1em;
}
.ph-duotone.ph-airplane-in-flight:before {
  content: "\e904";
  opacity: 0.2;
}
.ph-duotone.ph-airplane-in-flight:after {
  content: "\e905";
  margin-left: -1em;
}
.ph-duotone.ph-airplane-landing:before {
  content: "\e906";
  opacity: 0.2;
}
.ph-duotone.ph-airplane-landing:after {
  content: "\e907";
  margin-left: -1em;
}
.ph-duotone.ph-airplane-takeoff:before {
  content: "\e908";
  opacity: 0.2;
}
.ph-duotone.ph-airplane-takeoff:after {
  content: "\e909";
  margin-left: -1em;
}
.ph-duotone.ph-airplane-tilt:before {
  content: "\e90a";
  opacity: 0.2;
}
.ph-duotone.ph-airplane-tilt:after {
  content: "\e90b";
  margin-left: -1em;
}
.ph-duotone.ph-airplay:before {
  content: "\e90c";
  opacity: 0.2;
}
.ph-duotone.ph-airplay:after {
  content: "\e90d";
  margin-left: -1em;
}
.ph-duotone.ph-air-traffic-control:before {
  content: "\e90e";
  opacity: 0.2;
}
.ph-duotone.ph-air-traffic-control:after {
  content: "\e90f";
  margin-left: -1em;
}
.ph-duotone.ph-alarm:before {
  content: "\e910";
  opacity: 0.2;
}
.ph-duotone.ph-alarm:after {
  content: "\e911";
  margin-left: -1em;
}
.ph-duotone.ph-alien:before {
  content: "\e912";
  opacity: 0.2;
}
.ph-duotone.ph-alien:after {
  content: "\e913";
  margin-left: -1em;
}
.ph-duotone.ph-align-bottom:before {
  content: "\e914";
  opacity: 0.2;
}
.ph-duotone.ph-align-bottom:after {
  content: "\e915";
  margin-left: -1em;
}
.ph-duotone.ph-align-bottom-simple:before {
  content: "\e916";
  opacity: 0.2;
}
.ph-duotone.ph-align-bottom-simple:after {
  content: "\e917";
  margin-left: -1em;
}
.ph-duotone.ph-align-center-horizontal:before {
  content: "\e918";
  opacity: 0.2;
}
.ph-duotone.ph-align-center-horizontal:after {
  content: "\e919";
  margin-left: -1em;
}
.ph-duotone.ph-align-center-horizontal-simple:before {
  content: "\e91a";
  opacity: 0.2;
}
.ph-duotone.ph-align-center-horizontal-simple:after {
  content: "\e91b";
  margin-left: -1em;
}
.ph-duotone.ph-align-center-vertical:before {
  content: "\e91c";
  opacity: 0.2;
}
.ph-duotone.ph-align-center-vertical:after {
  content: "\e91d";
  margin-left: -1em;
}
.ph-duotone.ph-align-center-vertical-simple:before {
  content: "\e91e";
  opacity: 0.2;
}
.ph-duotone.ph-align-center-vertical-simple:after {
  content: "\e91f";
  margin-left: -1em;
}
.ph-duotone.ph-align-left:before {
  content: "\e920";
  opacity: 0.2;
}
.ph-duotone.ph-align-left:after {
  content: "\e921";
  margin-left: -1em;
}
.ph-duotone.ph-align-left-simple:before {
  content: "\e922";
  opacity: 0.2;
}
.ph-duotone.ph-align-left-simple:after {
  content: "\e923";
  margin-left: -1em;
}
.ph-duotone.ph-align-right:before {
  content: "\e924";
  opacity: 0.2;
}
.ph-duotone.ph-align-right:after {
  content: "\e925";
  margin-left: -1em;
}
.ph-duotone.ph-align-right-simple:before {
  content: "\e926";
  opacity: 0.2;
}
.ph-duotone.ph-align-right-simple:after {
  content: "\e927";
  margin-left: -1em;
}
.ph-duotone.ph-align-top:before {
  content: "\e928";
  opacity: 0.2;
}
.ph-duotone.ph-align-top:after {
  content: "\e929";
  margin-left: -1em;
}
.ph-duotone.ph-align-top-simple:before {
  content: "\e92a";
  opacity: 0.2;
}
.ph-duotone.ph-align-top-simple:after {
  content: "\e92b";
  margin-left: -1em;
}
.ph-duotone.ph-amazon-logo:before {
  content: "\e92c";
  opacity: 0.2;
}
.ph-duotone.ph-amazon-logo:after {
  content: "\e92d";
  margin-left: -1em;
}
.ph-duotone.ph-anchor:before {
  content: "\e92e";
  opacity: 0.2;
}
.ph-duotone.ph-anchor:after {
  content: "\e92f";
  margin-left: -1em;
}
.ph-duotone.ph-anchor-simple:before {
  content: "\e930";
  opacity: 0.2;
}
.ph-duotone.ph-anchor-simple:after {
  content: "\e931";
  margin-left: -1em;
}
.ph-duotone.ph-android-logo:before {
  content: "\e932";
  opacity: 0.2;
}
.ph-duotone.ph-android-logo:after {
  content: "\e933";
  margin-left: -1em;
}
.ph-duotone.ph-angular-logo:before {
  content: "\e934";
  opacity: 0.2;
}
.ph-duotone.ph-angular-logo:after {
  content: "\e935";
  margin-left: -1em;
}
.ph-duotone.ph-aperture:before {
  content: "\e936";
  opacity: 0.2;
}
.ph-duotone.ph-aperture:after {
  content: "\e937";
  margin-left: -1em;
}
.ph-duotone.ph-apple-logo:before {
  content: "\e938";
  opacity: 0.2;
}
.ph-duotone.ph-apple-logo:after {
  content: "\e939";
  margin-left: -1em;
}
.ph-duotone.ph-apple-podcasts-logo:before {
  content: "\e93a";
  opacity: 0.2;
}
.ph-duotone.ph-apple-podcasts-logo:after {
  content: "\e93b";
  margin-left: -1em;
}
.ph-duotone.ph-app-store-logo:before {
  content: "\e93c";
  opacity: 0.2;
}
.ph-duotone.ph-app-store-logo:after {
  content: "\e93d";
  margin-left: -1em;
}
.ph-duotone.ph-app-window:before {
  content: "\e93e";
  opacity: 0.2;
}
.ph-duotone.ph-app-window:after {
  content: "\e93f";
  margin-left: -1em;
}
.ph-duotone.ph-archive-box:before {
  content: "\e940";
  opacity: 0.2;
}
.ph-duotone.ph-archive-box:after {
  content: "\e941";
  margin-left: -1em;
}
.ph-duotone.ph-archive:before {
  content: "\e942";
  opacity: 0.2;
}
.ph-duotone.ph-archive:after {
  content: "\e943";
  margin-left: -1em;
}
.ph-duotone.ph-archive-tray:before {
  content: "\e944";
  opacity: 0.2;
}
.ph-duotone.ph-archive-tray:after {
  content: "\e945";
  margin-left: -1em;
}
.ph-duotone.ph-armchair:before {
  content: "\e946";
  opacity: 0.2;
}
.ph-duotone.ph-armchair:after {
  content: "\e947";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-arc-left:before {
  content: "\e948";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-arc-left:after {
  content: "\e949";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-arc-right:before {
  content: "\e94a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-arc-right:after {
  content: "\e94b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-double-up-left:before {
  content: "\e94c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-double-up-left:after {
  content: "\e94d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-double-up-right:before {
  content: "\e94e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-double-up-right:after {
  content: "\e94f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-down-left:before {
  content: "\e950";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-down-left:after {
  content: "\e951";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-down-right:before {
  content: "\e952";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-down-right:after {
  content: "\e953";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-left-down:before {
  content: "\e954";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-left-down:after {
  content: "\e955";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-left-up:before {
  content: "\e956";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-left-up:after {
  content: "\e957";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-right-down:before {
  content: "\e958";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-right-down:after {
  content: "\e959";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-right-up:before {
  content: "\e95a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-right-up:after {
  content: "\e95b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-up-left:before {
  content: "\e95c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-up-left:after {
  content: "\e95d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-up-right:before {
  content: "\e95e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-up-right:after {
  content: "\e95f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-down:before {
  content: "\e960";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-down:after {
  content: "\e961";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-down-left:before {
  content: "\e962";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-down-left:after {
  content: "\e963";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-down-right:before {
  content: "\e964";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-down-right:after {
  content: "\e965";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-left:before {
  content: "\e966";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-left:after {
  content: "\e967";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-right:before {
  content: "\e968";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-right:after {
  content: "\e969";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-up:before {
  content: "\e96a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-up:after {
  content: "\e96b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-up-left:before {
  content: "\e96c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-up-left:after {
  content: "\e96d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-up-right:before {
  content: "\e96e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-up-right:after {
  content: "\e96f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-clockwise:before {
  content: "\e970";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-clockwise:after {
  content: "\e971";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-counter-clockwise:before {
  content: "\e972";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-counter-clockwise:after {
  content: "\e973";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-down:before {
  content: "\e974";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-down:after {
  content: "\e975";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-down-left:before {
  content: "\e976";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-down-left:after {
  content: "\e977";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-down-right:before {
  content: "\e978";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-down-right:after {
  content: "\e979";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-down-left:before {
  content: "\e97a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-down-left:after {
  content: "\e97b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-down-right:before {
  content: "\e97c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-down-right:after {
  content: "\e97d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-left-down:before {
  content: "\e97e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-left-down:after {
  content: "\e97f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-left:before {
  content: "\e980";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-left:after {
  content: "\e981";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-left-up:before {
  content: "\e982";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-left-up:after {
  content: "\e983";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-right-down:before {
  content: "\e984";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-right-down:after {
  content: "\e985";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-right:before {
  content: "\e986";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-right:after {
  content: "\e987";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-right-up:before {
  content: "\e988";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-right-up:after {
  content: "\e989";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-up-left:before {
  content: "\e98a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-up-left:after {
  content: "\e98b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-up-right:before {
  content: "\e98c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-up-right:after {
  content: "\e98d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-down:before {
  content: "\e98e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-down:after {
  content: "\e98f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-left:before {
  content: "\e990";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-left:after {
  content: "\e991";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-line-down:before {
  content: "\e992";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-line-down:after {
  content: "\e993";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-line-left:before {
  content: "\e994";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-line-left:after {
  content: "\e995";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-line-right:before {
  content: "\e996";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-line-right:after {
  content: "\e997";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-lines-down:before {
  content: "\e998";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-lines-down:after {
  content: "\e999";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-lines-left:before {
  content: "\e99a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-lines-left:after {
  content: "\e99b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-lines-right:before {
  content: "\e99c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-lines-right:after {
  content: "\e99d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-lines-up:before {
  content: "\e99e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-lines-up:after {
  content: "\e99f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-line-up:before {
  content: "\e9a0";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-line-up:after {
  content: "\e9a1";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-right:before {
  content: "\e9a2";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-right:after {
  content: "\e9a3";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-up:before {
  content: "\e9a4";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-up:after {
  content: "\e9a5";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-left:before {
  content: "\e9a6";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-left:after {
  content: "\e9a7";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-down:before {
  content: "\e9a8";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-down:after {
  content: "\e9a9";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-down-left:before {
  content: "\e9aa";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-down-left:after {
  content: "\e9ab";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-down-right:before {
  content: "\e9ac";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-down-right:after {
  content: "\e9ad";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-left:before {
  content: "\e9ae";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-left:after {
  content: "\e9af";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-right:before {
  content: "\e9b0";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-right:after {
  content: "\e9b1";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-up:before {
  content: "\e9b2";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-up:after {
  content: "\e9b3";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-up-left:before {
  content: "\e9b4";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-up-left:after {
  content: "\e9b5";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-up-right:before {
  content: "\e9b6";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-up-right:after {
  content: "\e9b7";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-right:before {
  content: "\e9b8";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-right:after {
  content: "\e9b9";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-clockwise:before {
  content: "\e9ba";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-clockwise:after {
  content: "\e9bb";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-counter-clockwise:before {
  content: "\e9bc";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-counter-clockwise:after {
  content: "\e9bd";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-down-up:before {
  content: "\e9be";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-down-up:after {
  content: "\e9bf";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-horizontal:before {
  content: "\e9c0";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-horizontal:after {
  content: "\e9c1";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in-cardinal:before {
  content: "\e9c2";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in-cardinal:after {
  content: "\e9c3";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in:before {
  content: "\e9c4";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in:after {
  content: "\e9c5";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in-line-horizontal:before {
  content: "\e9c6";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in-line-horizontal:after {
  content: "\e9c7";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in-line-vertical:before {
  content: "\e9c8";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in-line-vertical:after {
  content: "\e9c9";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in-simple:before {
  content: "\e9ca";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in-simple:after {
  content: "\e9cb";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-left-right:before {
  content: "\e9cc";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-left-right:after {
  content: "\e9cd";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-merge:before {
  content: "\e9ce";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-merge:after {
  content: "\e9cf";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out-cardinal:before {
  content: "\e9d0";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out-cardinal:after {
  content: "\e9d1";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out:before {
  content: "\e9d2";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out:after {
  content: "\e9d3";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out-line-horizontal:before {
  content: "\e9d4";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out-line-horizontal:after {
  content: "\e9d5";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out-line-vertical:before {
  content: "\e9d6";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out-line-vertical:after {
  content: "\e9d7";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out-simple:before {
  content: "\e9d8";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out-simple:after {
  content: "\e9d9";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-down:before {
  content: "\e9da";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-down:after {
  content: "\e9db";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-down-left:before {
  content: "\e9dc";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-down-left:after {
  content: "\e9dd";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-down-right:before {
  content: "\e9de";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-down-right:after {
  content: "\e9df";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-in:before {
  content: "\e9e0";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-in:after {
  content: "\e9e1";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-left:before {
  content: "\e9e2";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-left:after {
  content: "\e9e3";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-out:before {
  content: "\e9e4";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-out:after {
  content: "\e9e5";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-right:before {
  content: "\e9e6";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-right:after {
  content: "\e9e7";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-up:before {
  content: "\e9e8";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-up:after {
  content: "\e9e9";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-up-left:before {
  content: "\e9ea";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-up-left:after {
  content: "\e9eb";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-up-right:before {
  content: "\e9ec";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-up-right:after {
  content: "\e9ed";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-split:before {
  content: "\e9ee";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-split:after {
  content: "\e9ef";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-vertical:before {
  content: "\e9f0";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-vertical:after {
  content: "\e9f1";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-down-left:before {
  content: "\e9f2";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-down-left:after {
  content: "\e9f3";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-down-right:before {
  content: "\e9f4";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-down-right:after {
  content: "\e9f5";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-left-down:before {
  content: "\e9f6";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-left-down:after {
  content: "\e9f7";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-left-up:before {
  content: "\e9f8";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-left-up:after {
  content: "\e9f9";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-up:before {
  content: "\e9fa";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-up:after {
  content: "\e9fb";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-up-left:before {
  content: "\e9fc";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-up-left:after {
  content: "\e9fd";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-up-right:before {
  content: "\e9fe";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-up-right:after {
  content: "\e9ff";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-right-down:before {
  content: "\ea00";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-right-down:after {
  content: "\ea01";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-right-up:before {
  content: "\ea02";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-right-up:after {
  content: "\ea03";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-up-left:before {
  content: "\ea04";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-up-left:after {
  content: "\ea05";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-up-right:before {
  content: "\ea06";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-up-right:after {
  content: "\ea07";
  margin-left: -1em;
}
.ph-duotone.ph-article:before {
  content: "\ea08";
  opacity: 0.2;
}
.ph-duotone.ph-article:after {
  content: "\ea09";
  margin-left: -1em;
}
.ph-duotone.ph-article-medium:before {
  content: "\ea0a";
  opacity: 0.2;
}
.ph-duotone.ph-article-medium:after {
  content: "\ea0b";
  margin-left: -1em;
}
.ph-duotone.ph-article-ny-times:before {
  content: "\ea0c";
  opacity: 0.2;
}
.ph-duotone.ph-article-ny-times:after {
  content: "\ea0d";
  margin-left: -1em;
}
.ph-duotone.ph-asterisk:before {
  content: "\ea0e";
  opacity: 0.2;
}
.ph-duotone.ph-asterisk:after {
  content: "\ea0f";
  margin-left: -1em;
}
.ph-duotone.ph-asterisk-simple:before {
  content: "\ea10";
  opacity: 0.2;
}
.ph-duotone.ph-asterisk-simple:after {
  content: "\ea11";
  margin-left: -1em;
}
.ph-duotone.ph-at:before {
  content: "\ea12";
  opacity: 0.2;
}
.ph-duotone.ph-at:after {
  content: "\ea13";
  margin-left: -1em;
}
.ph-duotone.ph-atom:before {
  content: "\ea14";
  opacity: 0.2;
}
.ph-duotone.ph-atom:after {
  content: "\ea15";
  margin-left: -1em;
}
.ph-duotone.ph-baby:before {
  content: "\ea16";
  opacity: 0.2;
}
.ph-duotone.ph-baby:after {
  content: "\ea17";
  margin-left: -1em;
}
.ph-duotone.ph-backpack:before {
  content: "\ea18";
  opacity: 0.2;
}
.ph-duotone.ph-backpack:after {
  content: "\ea19";
  margin-left: -1em;
}
.ph-duotone.ph-backspace:before {
  content: "\ea1a";
  opacity: 0.2;
}
.ph-duotone.ph-backspace:after {
  content: "\ea1b";
  margin-left: -1em;
}
.ph-duotone.ph-bag:before {
  content: "\ea1c";
  opacity: 0.2;
}
.ph-duotone.ph-bag:after {
  content: "\ea1d";
  margin-left: -1em;
}
.ph-duotone.ph-bag-simple:before {
  content: "\ea1e";
  opacity: 0.2;
}
.ph-duotone.ph-bag-simple:after {
  content: "\ea1f";
  margin-left: -1em;
}
.ph-duotone.ph-balloon:before {
  content: "\ea20";
  opacity: 0.2;
}
.ph-duotone.ph-balloon:after {
  content: "\ea21";
  margin-left: -1em;
}
.ph-duotone.ph-bandaids:before {
  content: "\ea22";
  opacity: 0.2;
}
.ph-duotone.ph-bandaids:after {
  content: "\ea23";
  margin-left: -1em;
}
.ph-duotone.ph-bank:before {
  content: "\ea24";
  opacity: 0.2;
}
.ph-duotone.ph-bank:after {
  content: "\ea25";
  margin-left: -1em;
}
.ph-duotone.ph-barbell:before {
  content: "\ea26";
  opacity: 0.2;
}
.ph-duotone.ph-barbell:after {
  content: "\ea27";
  margin-left: -1em;
}
.ph-duotone.ph-barcode:before {
  content: "\ea28";
  opacity: 0.2;
}
.ph-duotone.ph-barcode:after {
  content: "\ea29";
  margin-left: -1em;
}
.ph-duotone.ph-barricade:before {
  content: "\ea2a";
  opacity: 0.2;
}
.ph-duotone.ph-barricade:after {
  content: "\ea2b";
  margin-left: -1em;
}
.ph-duotone.ph-baseball-cap:before {
  content: "\ea2c";
  opacity: 0.2;
}
.ph-duotone.ph-baseball-cap:after {
  content: "\ea2d";
  margin-left: -1em;
}
.ph-duotone.ph-baseball:before {
  content: "\ea2e";
  opacity: 0.2;
}
.ph-duotone.ph-baseball:after {
  content: "\ea2f";
  margin-left: -1em;
}
.ph-duotone.ph-basketball:before {
  content: "\ea30";
  opacity: 0.2;
}
.ph-duotone.ph-basketball:after {
  content: "\ea31";
  margin-left: -1em;
}
.ph-duotone.ph-basket:before {
  content: "\ea32";
  opacity: 0.2;
}
.ph-duotone.ph-basket:after {
  content: "\ea33";
  margin-left: -1em;
}
.ph-duotone.ph-bathtub:before {
  content: "\ea34";
  opacity: 0.2;
}
.ph-duotone.ph-bathtub:after {
  content: "\ea35";
  margin-left: -1em;
}
.ph-duotone.ph-battery-charging:before {
  content: "\ea36";
  opacity: 0.2;
}
.ph-duotone.ph-battery-charging:after {
  content: "\ea37";
  margin-left: -1em;
}
.ph-duotone.ph-battery-charging-vertical:before {
  content: "\ea38";
  opacity: 0.2;
}
.ph-duotone.ph-battery-charging-vertical:after {
  content: "\ea39";
  margin-left: -1em;
}
.ph-duotone.ph-battery-empty:before {
  content: "\ea3a";
  opacity: 0.2;
}
.ph-duotone.ph-battery-empty:after {
  content: "\ea3b";
  margin-left: -1em;
}
.ph-duotone.ph-battery-full:before {
  content: "\ea3c";
  opacity: 0.2;
}
.ph-duotone.ph-battery-full:after {
  content: "\ea3d";
  margin-left: -1em;
}
.ph-duotone.ph-battery-high:before {
  content: "\ea3e";
  opacity: 0.2;
}
.ph-duotone.ph-battery-high:after {
  content: "\ea3f";
  margin-left: -1em;
}
.ph-duotone.ph-battery-low:before {
  content: "\ea40";
  opacity: 0.2;
}
.ph-duotone.ph-battery-low:after {
  content: "\ea41";
  margin-left: -1em;
}
.ph-duotone.ph-battery-medium:before {
  content: "\ea42";
  opacity: 0.2;
}
.ph-duotone.ph-battery-medium:after {
  content: "\ea43";
  margin-left: -1em;
}
.ph-duotone.ph-battery-plus:before {
  content: "\ea44";
  opacity: 0.2;
}
.ph-duotone.ph-battery-plus:after {
  content: "\ea45";
  margin-left: -1em;
}
.ph-duotone.ph-battery-plus-vertical:before {
  content: "\ea46";
  opacity: 0.2;
}
.ph-duotone.ph-battery-plus-vertical:after {
  content: "\ea47";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-empty:before {
  content: "\ea48";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-empty:after {
  content: "\ea49";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-full:before {
  content: "\ea4a";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-full:after {
  content: "\ea4b";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-high:before {
  content: "\ea4c";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-high:after {
  content: "\ea4d";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-low:before {
  content: "\ea4e";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-low:after {
  content: "\ea4f";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-medium:before {
  content: "\ea50";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-medium:after {
  content: "\ea51";
  margin-left: -1em;
}
.ph-duotone.ph-battery-warning:before {
  content: "\ea52";
  opacity: 0.2;
}
.ph-duotone.ph-battery-warning:after {
  content: "\ea53";
  margin-left: -1em;
}
.ph-duotone.ph-battery-warning-vertical:before {
  content: "\ea54";
  opacity: 0.2;
}
.ph-duotone.ph-battery-warning-vertical:after {
  content: "\ea55";
  margin-left: -1em;
}
.ph-duotone.ph-bed:before {
  content: "\ea56";
  opacity: 0.2;
}
.ph-duotone.ph-bed:after {
  content: "\ea57";
  margin-left: -1em;
}
.ph-duotone.ph-beer-bottle:before {
  content: "\ea58";
  opacity: 0.2;
}
.ph-duotone.ph-beer-bottle:after {
  content: "\ea59";
  margin-left: -1em;
}
.ph-duotone.ph-beer-stein:before {
  content: "\ea5a";
  opacity: 0.2;
}
.ph-duotone.ph-beer-stein:after {
  content: "\ea5b";
  margin-left: -1em;
}
.ph-duotone.ph-behance-logo:before {
  content: "\ea5c";
  opacity: 0.2;
}
.ph-duotone.ph-behance-logo:after {
  content: "\ea5d";
  margin-left: -1em;
}
.ph-duotone.ph-bell:before {
  content: "\ea5e";
  opacity: 0.2;
}
.ph-duotone.ph-bell:after {
  content: "\ea5f";
  margin-left: -1em;
}
.ph-duotone.ph-bell-ringing:before {
  content: "\ea60";
  opacity: 0.2;
}
.ph-duotone.ph-bell-ringing:after {
  content: "\ea61";
  margin-left: -1em;
}
.ph-duotone.ph-bell-simple:before {
  content: "\ea62";
  opacity: 0.2;
}
.ph-duotone.ph-bell-simple:after {
  content: "\ea63";
  margin-left: -1em;
}
.ph-duotone.ph-bell-simple-ringing:before {
  content: "\ea64";
  opacity: 0.2;
}
.ph-duotone.ph-bell-simple-ringing:after {
  content: "\ea65";
  margin-left: -1em;
}
.ph-duotone.ph-bell-simple-slash:before {
  content: "\ea66";
  opacity: 0.2;
}
.ph-duotone.ph-bell-simple-slash:after {
  content: "\ea67";
  margin-left: -1em;
}
.ph-duotone.ph-bell-simple-z:before {
  content: "\ea68";
  opacity: 0.2;
}
.ph-duotone.ph-bell-simple-z:after {
  content: "\ea69";
  margin-left: -1em;
}
.ph-duotone.ph-bell-slash:before {
  content: "\ea6a";
  opacity: 0.2;
}
.ph-duotone.ph-bell-slash:after {
  content: "\ea6b";
  margin-left: -1em;
}
.ph-duotone.ph-bell-z:before {
  content: "\ea6c";
  opacity: 0.2;
}
.ph-duotone.ph-bell-z:after {
  content: "\ea6d";
  margin-left: -1em;
}
.ph-duotone.ph-bezier-curve:before {
  content: "\ea6e";
  opacity: 0.2;
}
.ph-duotone.ph-bezier-curve:after {
  content: "\ea6f";
  margin-left: -1em;
}
.ph-duotone.ph-bicycle:before {
  content: "\ea70";
  opacity: 0.2;
}
.ph-duotone.ph-bicycle:after {
  content: "\ea71";
  margin-left: -1em;
}
.ph-duotone.ph-binoculars:before {
  content: "\ea72";
  opacity: 0.2;
}
.ph-duotone.ph-binoculars:after {
  content: "\ea73";
  margin-left: -1em;
}
.ph-duotone.ph-bird:before {
  content: "\ea74";
  opacity: 0.2;
}
.ph-duotone.ph-bird:after {
  content: "\ea75";
  margin-left: -1em;
}
.ph-duotone.ph-bluetooth-connected:before {
  content: "\ea76";
  opacity: 0.2;
}
.ph-duotone.ph-bluetooth-connected:after {
  content: "\ea77";
  margin-left: -1em;
}
.ph-duotone.ph-bluetooth:before {
  content: "\ea78";
  opacity: 0.2;
}
.ph-duotone.ph-bluetooth:after {
  content: "\ea79";
  margin-left: -1em;
}
.ph-duotone.ph-bluetooth-slash:before {
  content: "\ea7a";
  opacity: 0.2;
}
.ph-duotone.ph-bluetooth-slash:after {
  content: "\ea7b";
  margin-left: -1em;
}
.ph-duotone.ph-bluetooth-x:before {
  content: "\ea7c";
  opacity: 0.2;
}
.ph-duotone.ph-bluetooth-x:after {
  content: "\ea7d";
  margin-left: -1em;
}
.ph-duotone.ph-boat:before {
  content: "\ea7e";
  opacity: 0.2;
}
.ph-duotone.ph-boat:after {
  content: "\ea7f";
  margin-left: -1em;
}
.ph-duotone.ph-bone:before {
  content: "\ea80";
  opacity: 0.2;
}
.ph-duotone.ph-bone:after {
  content: "\ea81";
  margin-left: -1em;
}
.ph-duotone.ph-book-bookmark:before {
  content: "\ea82";
  opacity: 0.2;
}
.ph-duotone.ph-book-bookmark:after {
  content: "\ea83";
  margin-left: -1em;
}
.ph-duotone.ph-book:before {
  content: "\ea84";
  opacity: 0.2;
}
.ph-duotone.ph-book:after {
  content: "\ea85";
  margin-left: -1em;
}
.ph-duotone.ph-bookmark:before {
  content: "\ea86";
  opacity: 0.2;
}
.ph-duotone.ph-bookmark:after {
  content: "\ea87";
  margin-left: -1em;
}
.ph-duotone.ph-bookmarks:before {
  content: "\ea88";
  opacity: 0.2;
}
.ph-duotone.ph-bookmarks:after {
  content: "\ea89";
  margin-left: -1em;
}
.ph-duotone.ph-bookmark-simple:before {
  content: "\ea8a";
  opacity: 0.2;
}
.ph-duotone.ph-bookmark-simple:after {
  content: "\ea8b";
  margin-left: -1em;
}
.ph-duotone.ph-bookmarks-simple:before {
  content: "\ea8c";
  opacity: 0.2;
}
.ph-duotone.ph-bookmarks-simple:after {
  content: "\ea8d";
  margin-left: -1em;
}
.ph-duotone.ph-book-open:before {
  content: "\ea8e";
  opacity: 0.2;
}
.ph-duotone.ph-book-open:after {
  content: "\ea8f";
  margin-left: -1em;
}
.ph-duotone.ph-book-open-text:before {
  content: "\ea90";
  opacity: 0.2;
}
.ph-duotone.ph-book-open-text:after {
  content: "\ea91";
  margin-left: -1em;
}
.ph-duotone.ph-books:before {
  content: "\ea92";
  opacity: 0.2;
}
.ph-duotone.ph-books:after {
  content: "\ea93";
  margin-left: -1em;
}
.ph-duotone.ph-boot:before {
  content: "\ea94";
  opacity: 0.2;
}
.ph-duotone.ph-boot:after {
  content: "\ea95";
  margin-left: -1em;
}
.ph-duotone.ph-bounding-box:before {
  content: "\ea96";
  opacity: 0.2;
}
.ph-duotone.ph-bounding-box:after {
  content: "\ea97";
  margin-left: -1em;
}
.ph-duotone.ph-bowl-food:before {
  content: "\ea98";
  opacity: 0.2;
}
.ph-duotone.ph-bowl-food:after {
  content: "\ea99";
  margin-left: -1em;
}
.ph-duotone.ph-brackets-angle:before {
  content: "\ea9a";
  opacity: 0.2;
}
.ph-duotone.ph-brackets-angle:after {
  content: "\ea9b";
  margin-left: -1em;
}
.ph-duotone.ph-brackets-curly:before {
  content: "\ea9c";
  opacity: 0.2;
}
.ph-duotone.ph-brackets-curly:after {
  content: "\ea9d";
  margin-left: -1em;
}
.ph-duotone.ph-brackets-round:before {
  content: "\ea9e";
  opacity: 0.2;
}
.ph-duotone.ph-brackets-round:after {
  content: "\ea9f";
  margin-left: -1em;
}
.ph-duotone.ph-brackets-square:before {
  content: "\eaa0";
  opacity: 0.2;
}
.ph-duotone.ph-brackets-square:after {
  content: "\eaa1";
  margin-left: -1em;
}
.ph-duotone.ph-brain:before {
  content: "\eaa2";
  opacity: 0.2;
}
.ph-duotone.ph-brain:after {
  content: "\eaa3";
  margin-left: -1em;
}
.ph-duotone.ph-brandy:before {
  content: "\eaa4";
  opacity: 0.2;
}
.ph-duotone.ph-brandy:after {
  content: "\eaa5";
  margin-left: -1em;
}
.ph-duotone.ph-bridge:before {
  content: "\eaa6";
  opacity: 0.2;
}
.ph-duotone.ph-bridge:after {
  content: "\eaa7";
  margin-left: -1em;
}
.ph-duotone.ph-briefcase:before {
  content: "\eaa8";
  opacity: 0.2;
}
.ph-duotone.ph-briefcase:after {
  content: "\eaa9";
  margin-left: -1em;
}
.ph-duotone.ph-briefcase-metal:before {
  content: "\eaaa";
  opacity: 0.2;
}
.ph-duotone.ph-briefcase-metal:after {
  content: "\eaab";
  margin-left: -1em;
}
.ph-duotone.ph-broadcast:before {
  content: "\eaac";
  opacity: 0.2;
}
.ph-duotone.ph-broadcast:after {
  content: "\eaad";
  margin-left: -1em;
}
.ph-duotone.ph-broom:before {
  content: "\eaae";
  opacity: 0.2;
}
.ph-duotone.ph-broom:after {
  content: "\eaaf";
  margin-left: -1em;
}
.ph-duotone.ph-browser:before {
  content: "\eab0";
  opacity: 0.2;
}
.ph-duotone.ph-browser:after {
  content: "\eab1";
  margin-left: -1em;
}
.ph-duotone.ph-browsers:before {
  content: "\eab2";
  opacity: 0.2;
}
.ph-duotone.ph-browsers:after {
  content: "\eab3";
  margin-left: -1em;
}
.ph-duotone.ph-bug-beetle:before {
  content: "\eab4";
  opacity: 0.2;
}
.ph-duotone.ph-bug-beetle:after {
  content: "\eab5";
  margin-left: -1em;
}
.ph-duotone.ph-bug-droid:before {
  content: "\eab6";
  opacity: 0.2;
}
.ph-duotone.ph-bug-droid:after {
  content: "\eab7";
  margin-left: -1em;
}
.ph-duotone.ph-bug:before {
  content: "\eab8";
  opacity: 0.2;
}
.ph-duotone.ph-bug:after {
  content: "\eab9";
  margin-left: -1em;
}
.ph-duotone.ph-buildings:before {
  content: "\eaba";
  opacity: 0.2;
}
.ph-duotone.ph-buildings:after {
  content: "\eabb";
  margin-left: -1em;
}
.ph-duotone.ph-bus:before {
  content: "\eabc";
  opacity: 0.2;
}
.ph-duotone.ph-bus:after {
  content: "\eabd";
  margin-left: -1em;
}
.ph-duotone.ph-butterfly:before {
  content: "\eabe";
  opacity: 0.2;
}
.ph-duotone.ph-butterfly:after {
  content: "\eabf";
  margin-left: -1em;
}
.ph-duotone.ph-cactus:before {
  content: "\eac0";
  opacity: 0.2;
}
.ph-duotone.ph-cactus:after {
  content: "\eac1";
  margin-left: -1em;
}
.ph-duotone.ph-cake:before {
  content: "\eac2";
  opacity: 0.2;
}
.ph-duotone.ph-cake:after {
  content: "\eac3";
  margin-left: -1em;
}
.ph-duotone.ph-calculator:before {
  content: "\eac4";
  opacity: 0.2;
}
.ph-duotone.ph-calculator:after {
  content: "\eac5";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-blank:before {
  content: "\eac6";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-blank:after {
  content: "\eac7";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-check:before {
  content: "\eac8";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-check:after {
  content: "\eac9";
  margin-left: -1em;
}
.ph-duotone.ph-calendar:before {
  content: "\eaca";
  opacity: 0.2;
}
.ph-duotone.ph-calendar:after {
  content: "\eacb";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-plus:before {
  content: "\eacc";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-plus:after {
  content: "\eacd";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-x:before {
  content: "\eace";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-x:after {
  content: "\eacf";
  margin-left: -1em;
}
.ph-duotone.ph-call-bell:before {
  content: "\ead0";
  opacity: 0.2;
}
.ph-duotone.ph-call-bell:after {
  content: "\ead1";
  margin-left: -1em;
}
.ph-duotone.ph-camera:before {
  content: "\ead2";
  opacity: 0.2;
}
.ph-duotone.ph-camera:after {
  content: "\ead3";
  margin-left: -1em;
}
.ph-duotone.ph-camera-plus:before {
  content: "\ead4";
  opacity: 0.2;
}
.ph-duotone.ph-camera-plus:after {
  content: "\ead5";
  margin-left: -1em;
}
.ph-duotone.ph-camera-rotate:before {
  content: "\ead6";
  opacity: 0.2;
}
.ph-duotone.ph-camera-rotate:after {
  content: "\ead7";
  margin-left: -1em;
}
.ph-duotone.ph-camera-slash:before {
  content: "\ead8";
  opacity: 0.2;
}
.ph-duotone.ph-camera-slash:after {
  content: "\ead9";
  margin-left: -1em;
}
.ph-duotone.ph-campfire:before {
  content: "\eada";
  opacity: 0.2;
}
.ph-duotone.ph-campfire:after {
  content: "\eadb";
  margin-left: -1em;
}
.ph-duotone.ph-cardholder:before {
  content: "\eadc";
  opacity: 0.2;
}
.ph-duotone.ph-cardholder:after {
  content: "\eadd";
  margin-left: -1em;
}
.ph-duotone.ph-cards:before {
  content: "\eade";
  opacity: 0.2;
}
.ph-duotone.ph-cards:after {
  content: "\eadf";
  margin-left: -1em;
}
.ph-duotone.ph-car:before {
  content: "\eae0";
  opacity: 0.2;
}
.ph-duotone.ph-car:after {
  content: "\eae1";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-double-down:before {
  content: "\eae2";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-double-down:after {
  content: "\eae3";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-double-left:before {
  content: "\eae4";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-double-left:after {
  content: "\eae5";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-double-right:before {
  content: "\eae6";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-double-right:after {
  content: "\eae7";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-double-up:before {
  content: "\eae8";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-double-up:after {
  content: "\eae9";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-down:before {
  content: "\eaea";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-down:after {
  content: "\eaeb";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-left:before {
  content: "\eaec";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-left:after {
  content: "\eaed";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-right:before {
  content: "\eaee";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-right:after {
  content: "\eaef";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-up-down:before {
  content: "\eaf0";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-up-down:after {
  content: "\eaf1";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-up:before {
  content: "\eaf2";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-up:after {
  content: "\eaf3";
  margin-left: -1em;
}
.ph-duotone.ph-caret-double-down:before {
  content: "\eaf4";
  opacity: 0.2;
}
.ph-duotone.ph-caret-double-down:after {
  content: "\eaf5";
  margin-left: -1em;
}
.ph-duotone.ph-caret-double-left:before {
  content: "\eaf6";
  opacity: 0.2;
}
.ph-duotone.ph-caret-double-left:after {
  content: "\eaf7";
  margin-left: -1em;
}
.ph-duotone.ph-caret-double-right:before {
  content: "\eaf8";
  opacity: 0.2;
}
.ph-duotone.ph-caret-double-right:after {
  content: "\eaf9";
  margin-left: -1em;
}
.ph-duotone.ph-caret-double-up:before {
  content: "\eafa";
  opacity: 0.2;
}
.ph-duotone.ph-caret-double-up:after {
  content: "\eafb";
  margin-left: -1em;
}
.ph-duotone.ph-caret-down:before {
  content: "\eafc";
  opacity: 0.2;
}
.ph-duotone.ph-caret-down:after {
  content: "\eafd";
  margin-left: -1em;
}
.ph-duotone.ph-caret-left:before {
  content: "\eafe";
  opacity: 0.2;
}
.ph-duotone.ph-caret-left:after {
  content: "\eaff";
  margin-left: -1em;
}
.ph-duotone.ph-caret-right:before {
  content: "\eb00";
  opacity: 0.2;
}
.ph-duotone.ph-caret-right:after {
  content: "\eb01";
  margin-left: -1em;
}
.ph-duotone.ph-caret-up-down:before {
  content: "\eb02";
  opacity: 0.2;
}
.ph-duotone.ph-caret-up-down:after {
  content: "\eb03";
  margin-left: -1em;
}
.ph-duotone.ph-caret-up:before {
  content: "\eb04";
  opacity: 0.2;
}
.ph-duotone.ph-caret-up:after {
  content: "\eb05";
  margin-left: -1em;
}
.ph-duotone.ph-car-profile:before {
  content: "\eb06";
  opacity: 0.2;
}
.ph-duotone.ph-car-profile:after {
  content: "\eb07";
  margin-left: -1em;
}
.ph-duotone.ph-carrot:before {
  content: "\eb08";
  opacity: 0.2;
}
.ph-duotone.ph-carrot:after {
  content: "\eb09";
  margin-left: -1em;
}
.ph-duotone.ph-car-simple:before {
  content: "\eb0a";
  opacity: 0.2;
}
.ph-duotone.ph-car-simple:after {
  content: "\eb0b";
  margin-left: -1em;
}
.ph-duotone.ph-cassette-tape:before {
  content: "\eb0c";
  opacity: 0.2;
}
.ph-duotone.ph-cassette-tape:after {
  content: "\eb0d";
  margin-left: -1em;
}
.ph-duotone.ph-castle-turret:before {
  content: "\eb0e";
  opacity: 0.2;
}
.ph-duotone.ph-castle-turret:after {
  content: "\eb0f";
  margin-left: -1em;
}
.ph-duotone.ph-cat:before {
  content: "\eb10";
  opacity: 0.2;
}
.ph-duotone.ph-cat:after {
  content: "\eb11";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-full:before {
  content: "\eb12";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-full:after {
  content: "\eb13";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-high:before {
  content: "\eb14";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-high:after {
  content: "\eb15";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-low:before {
  content: "\eb16";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-low:after {
  content: "\eb17";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-medium:before {
  content: "\eb18";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-medium:after {
  content: "\eb19";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-none-duotone:before {
  content: "\eb1a";
}
.ph-duotone.ph-cell-signal-slash:before {
  content: "\eb1b";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-slash:after {
  content: "\eb1c";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-x:before {
  content: "\eb1d";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-x:after {
  content: "\eb1e";
  margin-left: -1em;
}
.ph-duotone.ph-certificate:before {
  content: "\eb1f";
  opacity: 0.2;
}
.ph-duotone.ph-certificate:after {
  content: "\eb20";
  margin-left: -1em;
}
.ph-duotone.ph-chair:before {
  content: "\eb21";
  opacity: 0.2;
}
.ph-duotone.ph-chair:after {
  content: "\eb22";
  margin-left: -1em;
}
.ph-duotone.ph-chalkboard:before {
  content: "\eb23";
  opacity: 0.2;
}
.ph-duotone.ph-chalkboard:after {
  content: "\eb24";
  margin-left: -1em;
}
.ph-duotone.ph-chalkboard-simple:before {
  content: "\eb25";
  opacity: 0.2;
}
.ph-duotone.ph-chalkboard-simple:after {
  content: "\eb26";
  margin-left: -1em;
}
.ph-duotone.ph-chalkboard-teacher:before {
  content: "\eb27";
  opacity: 0.2;
}
.ph-duotone.ph-chalkboard-teacher:after {
  content: "\eb28";
  margin-left: -1em;
}
.ph-duotone.ph-champagne:before {
  content: "\eb29";
  opacity: 0.2;
}
.ph-duotone.ph-champagne:after {
  content: "\eb2a";
  margin-left: -1em;
}
.ph-duotone.ph-charging-station:before {
  content: "\eb2b";
  opacity: 0.2;
}
.ph-duotone.ph-charging-station:after {
  content: "\eb2c";
  margin-left: -1em;
}
.ph-duotone.ph-chart-bar:before {
  content: "\eb2d";
  opacity: 0.2;
}
.ph-duotone.ph-chart-bar:after {
  content: "\eb2e";
  margin-left: -1em;
}
.ph-duotone.ph-chart-bar-horizontal:before {
  content: "\eb2f";
  opacity: 0.2;
}
.ph-duotone.ph-chart-bar-horizontal:after {
  content: "\eb30";
  margin-left: -1em;
}
.ph-duotone.ph-chart-donut:before {
  content: "\eb31";
  opacity: 0.2;
}
.ph-duotone.ph-chart-donut:after {
  content: "\eb32";
  margin-left: -1em;
}
.ph-duotone.ph-chart-line-down:before {
  content: "\eb33";
  opacity: 0.2;
}
.ph-duotone.ph-chart-line-down:after {
  content: "\eb34";
  margin-left: -1em;
}
.ph-duotone.ph-chart-line:before {
  content: "\eb35";
  opacity: 0.2;
}
.ph-duotone.ph-chart-line:after {
  content: "\eb36";
  margin-left: -1em;
}
.ph-duotone.ph-chart-line-up:before {
  content: "\eb37";
  opacity: 0.2;
}
.ph-duotone.ph-chart-line-up:after {
  content: "\eb38";
  margin-left: -1em;
}
.ph-duotone.ph-chart-pie:before {
  content: "\eb39";
  opacity: 0.2;
}
.ph-duotone.ph-chart-pie:after {
  content: "\eb3a";
  margin-left: -1em;
}
.ph-duotone.ph-chart-pie-slice:before {
  content: "\eb3b";
  opacity: 0.2;
}
.ph-duotone.ph-chart-pie-slice:after {
  content: "\eb3c";
  margin-left: -1em;
}
.ph-duotone.ph-chart-polar:before {
  content: "\eb3d";
  opacity: 0.2;
}
.ph-duotone.ph-chart-polar:after {
  content: "\eb3e";
  margin-left: -1em;
}
.ph-duotone.ph-chart-scatter:before {
  content: "\eb3f";
  opacity: 0.2;
}
.ph-duotone.ph-chart-scatter:after {
  content: "\eb40";
  margin-left: -1em;
}
.ph-duotone.ph-chat-centered-dots:before {
  content: "\eb41";
  opacity: 0.2;
}
.ph-duotone.ph-chat-centered-dots:after {
  content: "\eb42";
  margin-left: -1em;
}
.ph-duotone.ph-chat-centered:before {
  content: "\eb43";
  opacity: 0.2;
}
.ph-duotone.ph-chat-centered:after {
  content: "\eb44";
  margin-left: -1em;
}
.ph-duotone.ph-chat-centered-text:before {
  content: "\eb45";
  opacity: 0.2;
}
.ph-duotone.ph-chat-centered-text:after {
  content: "\eb46";
  margin-left: -1em;
}
.ph-duotone.ph-chat-circle-dots:before {
  content: "\eb47";
  opacity: 0.2;
}
.ph-duotone.ph-chat-circle-dots:after {
  content: "\eb48";
  margin-left: -1em;
}
.ph-duotone.ph-chat-circle:before {
  content: "\eb49";
  opacity: 0.2;
}
.ph-duotone.ph-chat-circle:after {
  content: "\eb4a";
  margin-left: -1em;
}
.ph-duotone.ph-chat-circle-text:before {
  content: "\eb4b";
  opacity: 0.2;
}
.ph-duotone.ph-chat-circle-text:after {
  content: "\eb4c";
  margin-left: -1em;
}
.ph-duotone.ph-chat-dots:before {
  content: "\eb4d";
  opacity: 0.2;
}
.ph-duotone.ph-chat-dots:after {
  content: "\eb4e";
  margin-left: -1em;
}
.ph-duotone.ph-chat:before {
  content: "\eb4f";
  opacity: 0.2;
}
.ph-duotone.ph-chat:after {
  content: "\eb50";
  margin-left: -1em;
}
.ph-duotone.ph-chats-circle:before {
  content: "\eb51";
  opacity: 0.2;
}
.ph-duotone.ph-chats-circle:after {
  content: "\eb52";
  margin-left: -1em;
}
.ph-duotone.ph-chats:before {
  content: "\eb53";
  opacity: 0.2;
}
.ph-duotone.ph-chats:after {
  content: "\eb54";
  margin-left: -1em;
}
.ph-duotone.ph-chats-teardrop:before {
  content: "\eb55";
  opacity: 0.2;
}
.ph-duotone.ph-chats-teardrop:after {
  content: "\eb56";
  margin-left: -1em;
}
.ph-duotone.ph-chat-teardrop-dots:before {
  content: "\eb57";
  opacity: 0.2;
}
.ph-duotone.ph-chat-teardrop-dots:after {
  content: "\eb58";
  margin-left: -1em;
}
.ph-duotone.ph-chat-teardrop:before {
  content: "\eb59";
  opacity: 0.2;
}
.ph-duotone.ph-chat-teardrop:after {
  content: "\eb5a";
  margin-left: -1em;
}
.ph-duotone.ph-chat-teardrop-text:before {
  content: "\eb5b";
  opacity: 0.2;
}
.ph-duotone.ph-chat-teardrop-text:after {
  content: "\eb5c";
  margin-left: -1em;
}
.ph-duotone.ph-chat-text:before {
  content: "\eb5d";
  opacity: 0.2;
}
.ph-duotone.ph-chat-text:after {
  content: "\eb5e";
  margin-left: -1em;
}
.ph-duotone.ph-check-circle:before {
  content: "\eb5f";
  opacity: 0.2;
}
.ph-duotone.ph-check-circle:after {
  content: "\eb60";
  margin-left: -1em;
}
.ph-duotone.ph-check:before {
  content: "\eb61";
  opacity: 0.2;
}
.ph-duotone.ph-check:after {
  content: "\eb62";
  margin-left: -1em;
}
.ph-duotone.ph-check-fat:before {
  content: "\eb63";
  opacity: 0.2;
}
.ph-duotone.ph-check-fat:after {
  content: "\eb64";
  margin-left: -1em;
}
.ph-duotone.ph-checks:before {
  content: "\eb65";
  opacity: 0.2;
}
.ph-duotone.ph-checks:after {
  content: "\eb66";
  margin-left: -1em;
}
.ph-duotone.ph-check-square:before {
  content: "\eb67";
  opacity: 0.2;
}
.ph-duotone.ph-check-square:after {
  content: "\eb68";
  margin-left: -1em;
}
.ph-duotone.ph-check-square-offset:before {
  content: "\eb69";
  opacity: 0.2;
}
.ph-duotone.ph-check-square-offset:after {
  content: "\eb6a";
  margin-left: -1em;
}
.ph-duotone.ph-church:before {
  content: "\eb6b";
  opacity: 0.2;
}
.ph-duotone.ph-church:after {
  content: "\eb6c";
  margin-left: -1em;
}
.ph-duotone.ph-circle-dashed:before {
  content: "\eb6d";
  opacity: 0.2;
}
.ph-duotone.ph-circle-dashed:after {
  content: "\eb6e";
  margin-left: -1em;
}
.ph-duotone.ph-circle:before {
  content: "\eb6f";
  opacity: 0.2;
}
.ph-duotone.ph-circle:after {
  content: "\eb70";
  margin-left: -1em;
}
.ph-duotone.ph-circle-half:before {
  content: "\eb71";
  opacity: 0.2;
}
.ph-duotone.ph-circle-half:after {
  content: "\eb72";
  margin-left: -1em;
}
.ph-duotone.ph-circle-half-tilt:before {
  content: "\eb73";
  opacity: 0.2;
}
.ph-duotone.ph-circle-half-tilt:after {
  content: "\eb74";
  margin-left: -1em;
}
.ph-duotone.ph-circle-notch:before {
  content: "\eb75";
  opacity: 0.2;
}
.ph-duotone.ph-circle-notch:after {
  content: "\eb76";
  margin-left: -1em;
}
.ph-duotone.ph-circles-four:before {
  content: "\eb77";
  opacity: 0.2;
}
.ph-duotone.ph-circles-four:after {
  content: "\eb78";
  margin-left: -1em;
}
.ph-duotone.ph-circles-three:before {
  content: "\eb79";
  opacity: 0.2;
}
.ph-duotone.ph-circles-three:after {
  content: "\eb7a";
  margin-left: -1em;
}
.ph-duotone.ph-circles-three-plus:before {
  content: "\eb7b";
  opacity: 0.2;
}
.ph-duotone.ph-circles-three-plus:after {
  content: "\eb7c";
  margin-left: -1em;
}
.ph-duotone.ph-circuitry:before {
  content: "\eb7d";
  opacity: 0.2;
}
.ph-duotone.ph-circuitry:after {
  content: "\eb7e";
  margin-left: -1em;
}
.ph-duotone.ph-clipboard:before {
  content: "\eb7f";
  opacity: 0.2;
}
.ph-duotone.ph-clipboard:after {
  content: "\eb80";
  margin-left: -1em;
}
.ph-duotone.ph-clipboard-text:before {
  content: "\eb81";
  opacity: 0.2;
}
.ph-duotone.ph-clipboard-text:after {
  content: "\eb82";
  margin-left: -1em;
}
.ph-duotone.ph-clock-afternoon:before {
  content: "\eb83";
  opacity: 0.2;
}
.ph-duotone.ph-clock-afternoon:after {
  content: "\eb84";
  margin-left: -1em;
}
.ph-duotone.ph-clock-clockwise:before {
  content: "\eb85";
  opacity: 0.2;
}
.ph-duotone.ph-clock-clockwise:after {
  content: "\eb86";
  margin-left: -1em;
}
.ph-duotone.ph-clock-countdown:before {
  content: "\eb87";
  opacity: 0.2;
}
.ph-duotone.ph-clock-countdown:after {
  content: "\eb88";
  margin-left: -1em;
}
.ph-duotone.ph-clock-counter-clockwise:before {
  content: "\eb89";
  opacity: 0.2;
}
.ph-duotone.ph-clock-counter-clockwise:after {
  content: "\eb8a";
  margin-left: -1em;
}
.ph-duotone.ph-clock:before {
  content: "\eb8b";
  opacity: 0.2;
}
.ph-duotone.ph-clock:after {
  content: "\eb8c";
  margin-left: -1em;
}
.ph-duotone.ph-closed-captioning:before {
  content: "\eb8d";
  opacity: 0.2;
}
.ph-duotone.ph-closed-captioning:after {
  content: "\eb8e";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-arrow-down:before {
  content: "\eb8f";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-arrow-down:after {
  content: "\eb90";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-arrow-up:before {
  content: "\eb91";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-arrow-up:after {
  content: "\eb92";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-check:before {
  content: "\eb93";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-check:after {
  content: "\eb94";
  margin-left: -1em;
}
.ph-duotone.ph-cloud:before {
  content: "\eb95";
  opacity: 0.2;
}
.ph-duotone.ph-cloud:after {
  content: "\eb96";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-fog:before {
  content: "\eb97";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-fog:after {
  content: "\eb98";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-lightning:before {
  content: "\eb99";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-lightning:after {
  content: "\eb9a";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-moon:before {
  content: "\eb9b";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-moon:after {
  content: "\eb9c";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-rain:before {
  content: "\eb9d";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-rain:after {
  content: "\eb9e";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-slash:before {
  content: "\eb9f";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-slash:after {
  content: "\eba0";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-snow:before {
  content: "\eba1";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-snow:after {
  content: "\eba2";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-sun:before {
  content: "\eba3";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-sun:after {
  content: "\eba4";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-warning:before {
  content: "\eba5";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-warning:after {
  content: "\eba6";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-x:before {
  content: "\eba7";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-x:after {
  content: "\eba8";
  margin-left: -1em;
}
.ph-duotone.ph-club:before {
  content: "\eba9";
  opacity: 0.2;
}
.ph-duotone.ph-club:after {
  content: "\ebaa";
  margin-left: -1em;
}
.ph-duotone.ph-coat-hanger:before {
  content: "\ebab";
  opacity: 0.2;
}
.ph-duotone.ph-coat-hanger:after {
  content: "\ebac";
  margin-left: -1em;
}
.ph-duotone.ph-coda-logo:before {
  content: "\ebad";
  opacity: 0.2;
}
.ph-duotone.ph-coda-logo:after {
  content: "\ebae";
  margin-left: -1em;
}
.ph-duotone.ph-code-block:before {
  content: "\ebaf";
  opacity: 0.2;
}
.ph-duotone.ph-code-block:after {
  content: "\ebb0";
  margin-left: -1em;
}
.ph-duotone.ph-code:before {
  content: "\ebb1";
  opacity: 0.2;
}
.ph-duotone.ph-code:after {
  content: "\ebb2";
  margin-left: -1em;
}
.ph-duotone.ph-codepen-logo:before {
  content: "\ebb3";
  opacity: 0.2;
}
.ph-duotone.ph-codepen-logo:after {
  content: "\ebb4";
  margin-left: -1em;
}
.ph-duotone.ph-codesandbox-logo:before {
  content: "\ebb5";
  opacity: 0.2;
}
.ph-duotone.ph-codesandbox-logo:after {
  content: "\ebb6";
  margin-left: -1em;
}
.ph-duotone.ph-code-simple:before {
  content: "\ebb7";
  opacity: 0.2;
}
.ph-duotone.ph-code-simple:after {
  content: "\ebb8";
  margin-left: -1em;
}
.ph-duotone.ph-coffee:before {
  content: "\ebb9";
  opacity: 0.2;
}
.ph-duotone.ph-coffee:after {
  content: "\ebba";
  margin-left: -1em;
}
.ph-duotone.ph-coin:before {
  content: "\ebbb";
  opacity: 0.2;
}
.ph-duotone.ph-coin:after {
  content: "\ebbc";
  margin-left: -1em;
}
.ph-duotone.ph-coins:before {
  content: "\ebbd";
  opacity: 0.2;
}
.ph-duotone.ph-coins:after {
  content: "\ebbe";
  margin-left: -1em;
}
.ph-duotone.ph-coin-vertical:before {
  content: "\ebbf";
  opacity: 0.2;
}
.ph-duotone.ph-coin-vertical:after {
  content: "\ebc0";
  margin-left: -1em;
}
.ph-duotone.ph-columns:before {
  content: "\ebc1";
  opacity: 0.2;
}
.ph-duotone.ph-columns:after {
  content: "\ebc2";
  margin-left: -1em;
}
.ph-duotone.ph-command:before {
  content: "\ebc3";
  opacity: 0.2;
}
.ph-duotone.ph-command:after {
  content: "\ebc4";
  margin-left: -1em;
}
.ph-duotone.ph-compass:before {
  content: "\ebc5";
  opacity: 0.2;
}
.ph-duotone.ph-compass:after {
  content: "\ebc6";
  margin-left: -1em;
}
.ph-duotone.ph-compass-tool:before {
  content: "\ebc7";
  opacity: 0.2;
}
.ph-duotone.ph-compass-tool:after {
  content: "\ebc8";
  margin-left: -1em;
}
.ph-duotone.ph-computer-tower:before {
  content: "\ebc9";
  opacity: 0.2;
}
.ph-duotone.ph-computer-tower:after {
  content: "\ebca";
  margin-left: -1em;
}
.ph-duotone.ph-confetti:before {
  content: "\ebcb";
  opacity: 0.2;
}
.ph-duotone.ph-confetti:after {
  content: "\ebcc";
  margin-left: -1em;
}
.ph-duotone.ph-contactless-payment:before {
  content: "\ebcd";
  opacity: 0.2;
}
.ph-duotone.ph-contactless-payment:after {
  content: "\ebce";
  margin-left: -1em;
}
.ph-duotone.ph-control:before {
  content: "\ebcf";
  opacity: 0.2;
}
.ph-duotone.ph-control:after {
  content: "\ebd0";
  margin-left: -1em;
}
.ph-duotone.ph-cookie:before {
  content: "\ebd1";
  opacity: 0.2;
}
.ph-duotone.ph-cookie:after {
  content: "\ebd2";
  margin-left: -1em;
}
.ph-duotone.ph-cooking-pot:before {
  content: "\ebd3";
  opacity: 0.2;
}
.ph-duotone.ph-cooking-pot:after {
  content: "\ebd4";
  margin-left: -1em;
}
.ph-duotone.ph-copy:before {
  content: "\ebd5";
  opacity: 0.2;
}
.ph-duotone.ph-copy:after {
  content: "\ebd6";
  margin-left: -1em;
}
.ph-duotone.ph-copyleft:before {
  content: "\ebd7";
  opacity: 0.2;
}
.ph-duotone.ph-copyleft:after {
  content: "\ebd8";
  margin-left: -1em;
}
.ph-duotone.ph-copyright:before {
  content: "\ebd9";
  opacity: 0.2;
}
.ph-duotone.ph-copyright:after {
  content: "\ebda";
  margin-left: -1em;
}
.ph-duotone.ph-copy-simple:before {
  content: "\ebdb";
  opacity: 0.2;
}
.ph-duotone.ph-copy-simple:after {
  content: "\ebdc";
  margin-left: -1em;
}
.ph-duotone.ph-corners-in:before {
  content: "\ebdd";
  opacity: 0.2;
}
.ph-duotone.ph-corners-in:after {
  content: "\ebde";
  margin-left: -1em;
}
.ph-duotone.ph-corners-out:before {
  content: "\ebdf";
  opacity: 0.2;
}
.ph-duotone.ph-corners-out:after {
  content: "\ebe0";
  margin-left: -1em;
}
.ph-duotone.ph-couch:before {
  content: "\ebe1";
  opacity: 0.2;
}
.ph-duotone.ph-couch:after {
  content: "\ebe2";
  margin-left: -1em;
}
.ph-duotone.ph-cpu:before {
  content: "\ebe3";
  opacity: 0.2;
}
.ph-duotone.ph-cpu:after {
  content: "\ebe4";
  margin-left: -1em;
}
.ph-duotone.ph-credit-card:before {
  content: "\ebe5";
  opacity: 0.2;
}
.ph-duotone.ph-credit-card:after {
  content: "\ebe6";
  margin-left: -1em;
}
.ph-duotone.ph-crop:before {
  content: "\ebe7";
  opacity: 0.2;
}
.ph-duotone.ph-crop:after {
  content: "\ebe8";
  margin-left: -1em;
}
.ph-duotone.ph-cross:before {
  content: "\ebe9";
  opacity: 0.2;
}
.ph-duotone.ph-cross:after {
  content: "\ebea";
  margin-left: -1em;
}
.ph-duotone.ph-crosshair:before {
  content: "\ebeb";
  opacity: 0.2;
}
.ph-duotone.ph-crosshair:after {
  content: "\ebec";
  margin-left: -1em;
}
.ph-duotone.ph-crosshair-simple:before {
  content: "\ebed";
  opacity: 0.2;
}
.ph-duotone.ph-crosshair-simple:after {
  content: "\ebee";
  margin-left: -1em;
}
.ph-duotone.ph-crown:before {
  content: "\ebef";
  opacity: 0.2;
}
.ph-duotone.ph-crown:after {
  content: "\ebf0";
  margin-left: -1em;
}
.ph-duotone.ph-crown-simple:before {
  content: "\ebf1";
  opacity: 0.2;
}
.ph-duotone.ph-crown-simple:after {
  content: "\ebf2";
  margin-left: -1em;
}
.ph-duotone.ph-cube:before {
  content: "\ebf3";
  opacity: 0.2;
}
.ph-duotone.ph-cube:after {
  content: "\ebf4";
  margin-left: -1em;
}
.ph-duotone.ph-cube-focus:before {
  content: "\ebf5";
  opacity: 0.2;
}
.ph-duotone.ph-cube-focus:after {
  content: "\ebf6";
  margin-left: -1em;
}
.ph-duotone.ph-cube-transparent:before {
  content: "\ebf7";
  opacity: 0.2;
}
.ph-duotone.ph-cube-transparent:after {
  content: "\ebf8";
  margin-left: -1em;
}
.ph-duotone.ph-currency-btc:before {
  content: "\ebf9";
  opacity: 0.2;
}
.ph-duotone.ph-currency-btc:after {
  content: "\ebfa";
  margin-left: -1em;
}
.ph-duotone.ph-currency-circle-dollar:before {
  content: "\ebfb";
  opacity: 0.2;
}
.ph-duotone.ph-currency-circle-dollar:after {
  content: "\ebfc";
  margin-left: -1em;
}
.ph-duotone.ph-currency-cny:before {
  content: "\ebfd";
  opacity: 0.2;
}
.ph-duotone.ph-currency-cny:after {
  content: "\ebfe";
  margin-left: -1em;
}
.ph-duotone.ph-currency-dollar:before {
  content: "\ebff";
  opacity: 0.2;
}
.ph-duotone.ph-currency-dollar:after {
  content: "\ec00";
  margin-left: -1em;
}
.ph-duotone.ph-currency-dollar-simple:before {
  content: "\ec01";
  opacity: 0.2;
}
.ph-duotone.ph-currency-dollar-simple:after {
  content: "\ec02";
  margin-left: -1em;
}
.ph-duotone.ph-currency-eth:before {
  content: "\ec03";
  opacity: 0.2;
}
.ph-duotone.ph-currency-eth:after {
  content: "\ec04";
  margin-left: -1em;
}
.ph-duotone.ph-currency-eur:before {
  content: "\ec05";
  opacity: 0.2;
}
.ph-duotone.ph-currency-eur:after {
  content: "\ec06";
  margin-left: -1em;
}
.ph-duotone.ph-currency-gbp:before {
  content: "\ec07";
  opacity: 0.2;
}
.ph-duotone.ph-currency-gbp:after {
  content: "\ec08";
  margin-left: -1em;
}
.ph-duotone.ph-currency-inr:before {
  content: "\ec09";
  opacity: 0.2;
}
.ph-duotone.ph-currency-inr:after {
  content: "\ec0a";
  margin-left: -1em;
}
.ph-duotone.ph-currency-jpy:before {
  content: "\ec0b";
  opacity: 0.2;
}
.ph-duotone.ph-currency-jpy:after {
  content: "\ec0c";
  margin-left: -1em;
}
.ph-duotone.ph-currency-krw:before {
  content: "\ec0d";
  opacity: 0.2;
}
.ph-duotone.ph-currency-krw:after {
  content: "\ec0e";
  margin-left: -1em;
}
.ph-duotone.ph-currency-kzt:before {
  content: "\ec0f";
  opacity: 0.2;
}
.ph-duotone.ph-currency-kzt:after {
  content: "\ec10";
  margin-left: -1em;
}
.ph-duotone.ph-currency-ngn:before {
  content: "\ec11";
  opacity: 0.2;
}
.ph-duotone.ph-currency-ngn:after {
  content: "\ec12";
  margin-left: -1em;
}
.ph-duotone.ph-currency-rub:before {
  content: "\ec13";
  opacity: 0.2;
}
.ph-duotone.ph-currency-rub:after {
  content: "\ec14";
  margin-left: -1em;
}
.ph-duotone.ph-cursor-click:before {
  content: "\ec15";
  opacity: 0.2;
}
.ph-duotone.ph-cursor-click:after {
  content: "\ec16";
  margin-left: -1em;
}
.ph-duotone.ph-cursor:before {
  content: "\ec17";
  opacity: 0.2;
}
.ph-duotone.ph-cursor:after {
  content: "\ec18";
  margin-left: -1em;
}
.ph-duotone.ph-cursor-text:before {
  content: "\ec19";
  opacity: 0.2;
}
.ph-duotone.ph-cursor-text:after {
  content: "\ec1a";
  margin-left: -1em;
}
.ph-duotone.ph-cylinder:before {
  content: "\ec1b";
  opacity: 0.2;
}
.ph-duotone.ph-cylinder:after {
  content: "\ec1c";
  margin-left: -1em;
}
.ph-duotone.ph-database:before {
  content: "\ec1d";
  opacity: 0.2;
}
.ph-duotone.ph-database:after {
  content: "\ec1e";
  margin-left: -1em;
}
.ph-duotone.ph-desktop:before {
  content: "\ec1f";
  opacity: 0.2;
}
.ph-duotone.ph-desktop:after {
  content: "\ec20";
  margin-left: -1em;
}
.ph-duotone.ph-desktop-tower:before {
  content: "\ec21";
  opacity: 0.2;
}
.ph-duotone.ph-desktop-tower:after {
  content: "\ec22";
  margin-left: -1em;
}
.ph-duotone.ph-detective:before {
  content: "\ec23";
  opacity: 0.2;
}
.ph-duotone.ph-detective:after {
  content: "\ec24";
  margin-left: -1em;
}
.ph-duotone.ph-device-mobile-camera:before {
  content: "\ec25";
  opacity: 0.2;
}
.ph-duotone.ph-device-mobile-camera:after {
  content: "\ec26";
  margin-left: -1em;
}
.ph-duotone.ph-device-mobile:before {
  content: "\ec27";
  opacity: 0.2;
}
.ph-duotone.ph-device-mobile:after {
  content: "\ec28";
  margin-left: -1em;
}
.ph-duotone.ph-device-mobile-speaker:before {
  content: "\ec29";
  opacity: 0.2;
}
.ph-duotone.ph-device-mobile-speaker:after {
  content: "\ec2a";
  margin-left: -1em;
}
.ph-duotone.ph-devices:before {
  content: "\ec2b";
  opacity: 0.2;
}
.ph-duotone.ph-devices:after {
  content: "\ec2c";
  margin-left: -1em;
}
.ph-duotone.ph-device-tablet-camera:before {
  content: "\ec2d";
  opacity: 0.2;
}
.ph-duotone.ph-device-tablet-camera:after {
  content: "\ec2e";
  margin-left: -1em;
}
.ph-duotone.ph-device-tablet:before {
  content: "\ec2f";
  opacity: 0.2;
}
.ph-duotone.ph-device-tablet:after {
  content: "\ec30";
  margin-left: -1em;
}
.ph-duotone.ph-device-tablet-speaker:before {
  content: "\ec31";
  opacity: 0.2;
}
.ph-duotone.ph-device-tablet-speaker:after {
  content: "\ec32";
  margin-left: -1em;
}
.ph-duotone.ph-dev-to-logo:before {
  content: "\ec33";
  opacity: 0.2;
}
.ph-duotone.ph-dev-to-logo:after {
  content: "\ec34";
  margin-left: -1em;
}
.ph-duotone.ph-diamond:before {
  content: "\ec35";
  opacity: 0.2;
}
.ph-duotone.ph-diamond:after {
  content: "\ec36";
  margin-left: -1em;
}
.ph-duotone.ph-diamonds-four:before {
  content: "\ec37";
  opacity: 0.2;
}
.ph-duotone.ph-diamonds-four:after {
  content: "\ec38";
  margin-left: -1em;
}
.ph-duotone.ph-dice-five:before {
  content: "\ec39";
  opacity: 0.2;
}
.ph-duotone.ph-dice-five:after {
  content: "\ec3a";
  margin-left: -1em;
}
.ph-duotone.ph-dice-four:before {
  content: "\ec3b";
  opacity: 0.2;
}
.ph-duotone.ph-dice-four:after {
  content: "\ec3c";
  margin-left: -1em;
}
.ph-duotone.ph-dice-one:before {
  content: "\ec3d";
  opacity: 0.2;
}
.ph-duotone.ph-dice-one:after {
  content: "\ec3e";
  margin-left: -1em;
}
.ph-duotone.ph-dice-six:before {
  content: "\ec3f";
  opacity: 0.2;
}
.ph-duotone.ph-dice-six:after {
  content: "\ec40";
  margin-left: -1em;
}
.ph-duotone.ph-dice-three:before {
  content: "\ec41";
  opacity: 0.2;
}
.ph-duotone.ph-dice-three:after {
  content: "\ec42";
  margin-left: -1em;
}
.ph-duotone.ph-dice-two:before {
  content: "\ec43";
  opacity: 0.2;
}
.ph-duotone.ph-dice-two:after {
  content: "\ec44";
  margin-left: -1em;
}
.ph-duotone.ph-disc:before {
  content: "\ec45";
  opacity: 0.2;
}
.ph-duotone.ph-disc:after {
  content: "\ec46";
  margin-left: -1em;
}
.ph-duotone.ph-discord-logo:before {
  content: "\ec47";
  opacity: 0.2;
}
.ph-duotone.ph-discord-logo:after {
  content: "\ec48";
  margin-left: -1em;
}
.ph-duotone.ph-divide:before {
  content: "\ec49";
  opacity: 0.2;
}
.ph-duotone.ph-divide:after {
  content: "\ec4a";
  margin-left: -1em;
}
.ph-duotone.ph-dna:before {
  content: "\ec4b";
  opacity: 0.2;
}
.ph-duotone.ph-dna:after {
  content: "\ec4c";
  margin-left: -1em;
}
.ph-duotone.ph-dog:before {
  content: "\ec4d";
  opacity: 0.2;
}
.ph-duotone.ph-dog:after {
  content: "\ec4e";
  margin-left: -1em;
}
.ph-duotone.ph-door:before {
  content: "\ec4f";
  opacity: 0.2;
}
.ph-duotone.ph-door:after {
  content: "\ec50";
  margin-left: -1em;
}
.ph-duotone.ph-door-open:before {
  content: "\ec51";
  opacity: 0.2;
}
.ph-duotone.ph-door-open:after {
  content: "\ec52";
  margin-left: -1em;
}
.ph-duotone.ph-dot:before {
  content: "\ec53";
  opacity: 0.2;
}
.ph-duotone.ph-dot:after {
  content: "\ec54";
  margin-left: -1em;
}
.ph-duotone.ph-dot-outline:before {
  content: "\ec55";
  opacity: 0.2;
}
.ph-duotone.ph-dot-outline:after {
  content: "\ec56";
  margin-left: -1em;
}
.ph-duotone.ph-dots-nine:before {
  content: "\ec57";
  opacity: 0.2;
}
.ph-duotone.ph-dots-nine:after {
  content: "\ec58";
  margin-left: -1em;
}
.ph-duotone.ph-dots-six:before {
  content: "\ec59";
  opacity: 0.2;
}
.ph-duotone.ph-dots-six:after {
  content: "\ec5a";
  margin-left: -1em;
}
.ph-duotone.ph-dots-six-vertical:before {
  content: "\ec5b";
  opacity: 0.2;
}
.ph-duotone.ph-dots-six-vertical:after {
  content: "\ec5c";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-circle:before {
  content: "\ec5d";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-circle:after {
  content: "\ec5e";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-circle-vertical:before {
  content: "\ec5f";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-circle-vertical:after {
  content: "\ec60";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three:before {
  content: "\ec61";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three:after {
  content: "\ec62";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-outline:before {
  content: "\ec63";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-outline:after {
  content: "\ec64";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-outline-vertical:before {
  content: "\ec65";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-outline-vertical:after {
  content: "\ec66";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-vertical:before {
  content: "\ec67";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-vertical:after {
  content: "\ec68";
  margin-left: -1em;
}
.ph-duotone.ph-download:before {
  content: "\ec69";
  opacity: 0.2;
}
.ph-duotone.ph-download:after {
  content: "\ec6a";
  margin-left: -1em;
}
.ph-duotone.ph-download-simple:before {
  content: "\ec6b";
  opacity: 0.2;
}
.ph-duotone.ph-download-simple:after {
  content: "\ec6c";
  margin-left: -1em;
}
.ph-duotone.ph-dress:before {
  content: "\ec6d";
  opacity: 0.2;
}
.ph-duotone.ph-dress:after {
  content: "\ec6e";
  margin-left: -1em;
}
.ph-duotone.ph-dribbble-logo:before {
  content: "\ec6f";
  opacity: 0.2;
}
.ph-duotone.ph-dribbble-logo:after {
  content: "\ec70";
  margin-left: -1em;
}
.ph-duotone.ph-dropbox-logo:before {
  content: "\ec71";
  opacity: 0.2;
}
.ph-duotone.ph-dropbox-logo:after {
  content: "\ec72";
  margin-left: -1em;
}
.ph-duotone.ph-drop:before {
  content: "\ec73";
  opacity: 0.2;
}
.ph-duotone.ph-drop:after {
  content: "\ec74";
  margin-left: -1em;
}
.ph-duotone.ph-drop-half-bottom:before {
  content: "\ec75";
  opacity: 0.2;
}
.ph-duotone.ph-drop-half-bottom:after {
  content: "\ec76";
  margin-left: -1em;
}
.ph-duotone.ph-drop-half:before {
  content: "\ec77";
  opacity: 0.2;
}
.ph-duotone.ph-drop-half:after {
  content: "\ec78";
  margin-left: -1em;
}
.ph-duotone.ph-ear:before {
  content: "\ec79";
  opacity: 0.2;
}
.ph-duotone.ph-ear:after {
  content: "\ec7a";
  margin-left: -1em;
}
.ph-duotone.ph-ear-slash:before {
  content: "\ec7b";
  opacity: 0.2;
}
.ph-duotone.ph-ear-slash:after {
  content: "\ec7c";
  margin-left: -1em;
}
.ph-duotone.ph-egg-crack:before {
  content: "\ec7d";
  opacity: 0.2;
}
.ph-duotone.ph-egg-crack:after {
  content: "\ec7e";
  margin-left: -1em;
}
.ph-duotone.ph-egg:before {
  content: "\ec7f";
  opacity: 0.2;
}
.ph-duotone.ph-egg:after {
  content: "\ec80";
  margin-left: -1em;
}
.ph-duotone.ph-eject:before {
  content: "\ec81";
  opacity: 0.2;
}
.ph-duotone.ph-eject:after {
  content: "\ec82";
  margin-left: -1em;
}
.ph-duotone.ph-eject-simple:before {
  content: "\ec83";
  opacity: 0.2;
}
.ph-duotone.ph-eject-simple:after {
  content: "\ec84";
  margin-left: -1em;
}
.ph-duotone.ph-elevator:before {
  content: "\ec85";
  opacity: 0.2;
}
.ph-duotone.ph-elevator:after {
  content: "\ec86";
  margin-left: -1em;
}
.ph-duotone.ph-engine:before {
  content: "\ec87";
  opacity: 0.2;
}
.ph-duotone.ph-engine:after {
  content: "\ec88";
  margin-left: -1em;
}
.ph-duotone.ph-envelope:before {
  content: "\ec89";
  opacity: 0.2;
}
.ph-duotone.ph-envelope:after {
  content: "\ec8a";
  margin-left: -1em;
}
.ph-duotone.ph-envelope-open:before {
  content: "\ec8b";
  opacity: 0.2;
}
.ph-duotone.ph-envelope-open:after {
  content: "\ec8c";
  margin-left: -1em;
}
.ph-duotone.ph-envelope-simple:before {
  content: "\ec8d";
  opacity: 0.2;
}
.ph-duotone.ph-envelope-simple:after {
  content: "\ec8e";
  margin-left: -1em;
}
.ph-duotone.ph-envelope-simple-open:before {
  content: "\ec8f";
  opacity: 0.2;
}
.ph-duotone.ph-envelope-simple-open:after {
  content: "\ec90";
  margin-left: -1em;
}
.ph-duotone.ph-equalizer:before {
  content: "\ec91";
  opacity: 0.2;
}
.ph-duotone.ph-equalizer:after {
  content: "\ec92";
  margin-left: -1em;
}
.ph-duotone.ph-equals:before {
  content: "\ec93";
  opacity: 0.2;
}
.ph-duotone.ph-equals:after {
  content: "\ec94";
  margin-left: -1em;
}
.ph-duotone.ph-eraser:before {
  content: "\ec95";
  opacity: 0.2;
}
.ph-duotone.ph-eraser:after {
  content: "\ec96";
  margin-left: -1em;
}
.ph-duotone.ph-escalator-down:before {
  content: "\ec97";
  opacity: 0.2;
}
.ph-duotone.ph-escalator-down:after {
  content: "\ec98";
  margin-left: -1em;
}
.ph-duotone.ph-escalator-up:before {
  content: "\ec99";
  opacity: 0.2;
}
.ph-duotone.ph-escalator-up:after {
  content: "\ec9a";
  margin-left: -1em;
}
.ph-duotone.ph-exam:before {
  content: "\ec9b";
  opacity: 0.2;
}
.ph-duotone.ph-exam:after {
  content: "\ec9c";
  margin-left: -1em;
}
.ph-duotone.ph-exclude:before {
  content: "\ec9d";
  opacity: 0.2;
}
.ph-duotone.ph-exclude:after {
  content: "\ec9e";
  margin-left: -1em;
}
.ph-duotone.ph-exclude-square:before {
  content: "\ec9f";
  opacity: 0.2;
}
.ph-duotone.ph-exclude-square:after {
  content: "\eca0";
  margin-left: -1em;
}
.ph-duotone.ph-export:before {
  content: "\eca1";
  opacity: 0.2;
}
.ph-duotone.ph-export:after {
  content: "\eca2";
  margin-left: -1em;
}
.ph-duotone.ph-eye-closed:before {
  content: "\eca3";
  opacity: 0.2;
}
.ph-duotone.ph-eye-closed:after {
  content: "\eca4";
  margin-left: -1em;
}
.ph-duotone.ph-eyedropper:before {
  content: "\eca5";
  opacity: 0.2;
}
.ph-duotone.ph-eyedropper:after {
  content: "\eca6";
  margin-left: -1em;
}
.ph-duotone.ph-eyedropper-sample:before {
  content: "\eca7";
  opacity: 0.2;
}
.ph-duotone.ph-eyedropper-sample:after {
  content: "\eca8";
  margin-left: -1em;
}
.ph-duotone.ph-eye:before {
  content: "\eca9";
  opacity: 0.2;
}
.ph-duotone.ph-eye:after {
  content: "\ecaa";
  margin-left: -1em;
}
.ph-duotone.ph-eyeglasses:before {
  content: "\ecab";
  opacity: 0.2;
}
.ph-duotone.ph-eyeglasses:after {
  content: "\ecac";
  margin-left: -1em;
}
.ph-duotone.ph-eye-slash:before {
  content: "\ecad";
  opacity: 0.2;
}
.ph-duotone.ph-eye-slash:after {
  content: "\ecae";
  margin-left: -1em;
}
.ph-duotone.ph-facebook-logo:before {
  content: "\ecaf";
  opacity: 0.2;
}
.ph-duotone.ph-facebook-logo:after {
  content: "\ecb0";
  margin-left: -1em;
}
.ph-duotone.ph-face-mask:before {
  content: "\ecb1";
  opacity: 0.2;
}
.ph-duotone.ph-face-mask:after {
  content: "\ecb2";
  margin-left: -1em;
}
.ph-duotone.ph-factory:before {
  content: "\ecb3";
  opacity: 0.2;
}
.ph-duotone.ph-factory:after {
  content: "\ecb4";
  margin-left: -1em;
}
.ph-duotone.ph-faders:before {
  content: "\ecb5";
  opacity: 0.2;
}
.ph-duotone.ph-faders:after {
  content: "\ecb6";
  margin-left: -1em;
}
.ph-duotone.ph-faders-horizontal:before {
  content: "\ecb7";
  opacity: 0.2;
}
.ph-duotone.ph-faders-horizontal:after {
  content: "\ecb8";
  margin-left: -1em;
}
.ph-duotone.ph-fan:before {
  content: "\ecb9";
  opacity: 0.2;
}
.ph-duotone.ph-fan:after {
  content: "\ecba";
  margin-left: -1em;
}
.ph-duotone.ph-fast-forward-circle:before {
  content: "\ecbb";
  opacity: 0.2;
}
.ph-duotone.ph-fast-forward-circle:after {
  content: "\ecbc";
  margin-left: -1em;
}
.ph-duotone.ph-fast-forward:before {
  content: "\ecbd";
  opacity: 0.2;
}
.ph-duotone.ph-fast-forward:after {
  content: "\ecbe";
  margin-left: -1em;
}
.ph-duotone.ph-feather:before {
  content: "\ecbf";
  opacity: 0.2;
}
.ph-duotone.ph-feather:after {
  content: "\ecc0";
  margin-left: -1em;
}
.ph-duotone.ph-figma-logo:before {
  content: "\ecc1";
  opacity: 0.2;
}
.ph-duotone.ph-figma-logo:after {
  content: "\ecc2";
  margin-left: -1em;
}
.ph-duotone.ph-file-archive:before {
  content: "\ecc3";
  opacity: 0.2;
}
.ph-duotone.ph-file-archive:after {
  content: "\ecc4";
  margin-left: -1em;
}
.ph-duotone.ph-file-arrow-down:before {
  content: "\ecc5";
  opacity: 0.2;
}
.ph-duotone.ph-file-arrow-down:after {
  content: "\ecc6";
  margin-left: -1em;
}
.ph-duotone.ph-file-arrow-up:before {
  content: "\ecc7";
  opacity: 0.2;
}
.ph-duotone.ph-file-arrow-up:after {
  content: "\ecc8";
  margin-left: -1em;
}
.ph-duotone.ph-file-audio:before {
  content: "\ecc9";
  opacity: 0.2;
}
.ph-duotone.ph-file-audio:after {
  content: "\ecca";
  margin-left: -1em;
}
.ph-duotone.ph-file-cloud:before {
  content: "\eccb";
  opacity: 0.2;
}
.ph-duotone.ph-file-cloud:after {
  content: "\eccc";
  margin-left: -1em;
}
.ph-duotone.ph-file-code:before {
  content: "\eccd";
  opacity: 0.2;
}
.ph-duotone.ph-file-code:after {
  content: "\ecce";
  margin-left: -1em;
}
.ph-duotone.ph-file-css:before {
  content: "\eccf";
  opacity: 0.2;
}
.ph-duotone.ph-file-css:after {
  content: "\ecd0";
  margin-left: -1em;
}
.ph-duotone.ph-file-csv:before {
  content: "\ecd1";
  opacity: 0.2;
}
.ph-duotone.ph-file-csv:after {
  content: "\ecd2";
  margin-left: -1em;
}
.ph-duotone.ph-file-dashed:before, .ph-duotone.ph-file-dotted:before {
  content: "\ecd3";
  opacity: 0.2;
}
.ph-duotone.ph-file-dashed:after, .ph-duotone.ph-file-dotted:after {
  content: "\ecd4";
  margin-left: -1em;
}
.ph-duotone.ph-file-doc:before {
  content: "\ecd5";
  opacity: 0.2;
}
.ph-duotone.ph-file-doc:after {
  content: "\ecd6";
  margin-left: -1em;
}
.ph-duotone.ph-file:before {
  content: "\ecd7";
  opacity: 0.2;
}
.ph-duotone.ph-file:after {
  content: "\ecd8";
  margin-left: -1em;
}
.ph-duotone.ph-file-html:before {
  content: "\ecd9";
  opacity: 0.2;
}
.ph-duotone.ph-file-html:after {
  content: "\ecda";
  margin-left: -1em;
}
.ph-duotone.ph-file-image:before {
  content: "\ecdb";
  opacity: 0.2;
}
.ph-duotone.ph-file-image:after {
  content: "\ecdc";
  margin-left: -1em;
}
.ph-duotone.ph-file-jpg:before {
  content: "\ecdd";
  opacity: 0.2;
}
.ph-duotone.ph-file-jpg:after {
  content: "\ecde";
  margin-left: -1em;
}
.ph-duotone.ph-file-js:before {
  content: "\ecdf";
  opacity: 0.2;
}
.ph-duotone.ph-file-js:after {
  content: "\ece0";
  margin-left: -1em;
}
.ph-duotone.ph-file-jsx:before {
  content: "\ece1";
  opacity: 0.2;
}
.ph-duotone.ph-file-jsx:after {
  content: "\ece2";
  margin-left: -1em;
}
.ph-duotone.ph-file-lock:before {
  content: "\ece3";
  opacity: 0.2;
}
.ph-duotone.ph-file-lock:after {
  content: "\ece4";
  margin-left: -1em;
}
.ph-duotone.ph-file-magnifying-glass:before, .ph-duotone.ph-file-search:before {
  content: "\ece5";
  opacity: 0.2;
}
.ph-duotone.ph-file-magnifying-glass:after, .ph-duotone.ph-file-search:after {
  content: "\ece6";
  margin-left: -1em;
}
.ph-duotone.ph-file-minus:before {
  content: "\ece7";
  opacity: 0.2;
}
.ph-duotone.ph-file-minus:after {
  content: "\ece8";
  margin-left: -1em;
}
.ph-duotone.ph-file-pdf:before {
  content: "\ece9";
  opacity: 0.2;
}
.ph-duotone.ph-file-pdf:after {
  content: "\ecea";
  margin-left: -1em;
}
.ph-duotone.ph-file-plus:before {
  content: "\eceb";
  opacity: 0.2;
}
.ph-duotone.ph-file-plus:after {
  content: "\ecec";
  margin-left: -1em;
}
.ph-duotone.ph-file-png:before {
  content: "\eced";
  opacity: 0.2;
}
.ph-duotone.ph-file-png:after {
  content: "\ecee";
  margin-left: -1em;
}
.ph-duotone.ph-file-ppt:before {
  content: "\ecef";
  opacity: 0.2;
}
.ph-duotone.ph-file-ppt:after {
  content: "\ecf0";
  margin-left: -1em;
}
.ph-duotone.ph-file-rs:before {
  content: "\ecf1";
  opacity: 0.2;
}
.ph-duotone.ph-file-rs:after {
  content: "\ecf2";
  margin-left: -1em;
}
.ph-duotone.ph-files:before {
  content: "\ecf3";
  opacity: 0.2;
}
.ph-duotone.ph-files:after {
  content: "\ecf4";
  margin-left: -1em;
}
.ph-duotone.ph-file-sql:before {
  content: "\ecf5";
  opacity: 0.2;
}
.ph-duotone.ph-file-sql:after {
  content: "\ecf6";
  margin-left: -1em;
}
.ph-duotone.ph-file-svg:before {
  content: "\ecf7";
  opacity: 0.2;
}
.ph-duotone.ph-file-svg:after {
  content: "\ecf8";
  margin-left: -1em;
}
.ph-duotone.ph-file-text:before {
  content: "\ecf9";
  opacity: 0.2;
}
.ph-duotone.ph-file-text:after {
  content: "\ecfa";
  margin-left: -1em;
}
.ph-duotone.ph-file-ts:before {
  content: "\ecfb";
  opacity: 0.2;
}
.ph-duotone.ph-file-ts:after {
  content: "\ecfc";
  margin-left: -1em;
}
.ph-duotone.ph-file-tsx:before {
  content: "\ecfd";
  opacity: 0.2;
}
.ph-duotone.ph-file-tsx:after {
  content: "\ecfe";
  margin-left: -1em;
}
.ph-duotone.ph-file-video:before {
  content: "\ecff";
  opacity: 0.2;
}
.ph-duotone.ph-file-video:after {
  content: "\ed00";
  margin-left: -1em;
}
.ph-duotone.ph-file-vue:before {
  content: "\ed01";
  opacity: 0.2;
}
.ph-duotone.ph-file-vue:after {
  content: "\ed02";
  margin-left: -1em;
}
.ph-duotone.ph-file-x:before {
  content: "\ed03";
  opacity: 0.2;
}
.ph-duotone.ph-file-x:after {
  content: "\ed04";
  margin-left: -1em;
}
.ph-duotone.ph-file-xls:before {
  content: "\ed05";
  opacity: 0.2;
}
.ph-duotone.ph-file-xls:after {
  content: "\ed06";
  margin-left: -1em;
}
.ph-duotone.ph-file-zip:before {
  content: "\ed07";
  opacity: 0.2;
}
.ph-duotone.ph-file-zip:after {
  content: "\ed08";
  margin-left: -1em;
}
.ph-duotone.ph-film-reel:before {
  content: "\ed09";
  opacity: 0.2;
}
.ph-duotone.ph-film-reel:after {
  content: "\ed0a";
  margin-left: -1em;
}
.ph-duotone.ph-film-script:before {
  content: "\ed0b";
  opacity: 0.2;
}
.ph-duotone.ph-film-script:after {
  content: "\ed0c";
  margin-left: -1em;
}
.ph-duotone.ph-film-slate:before {
  content: "\ed0d";
  opacity: 0.2;
}
.ph-duotone.ph-film-slate:after {
  content: "\ed0e";
  margin-left: -1em;
}
.ph-duotone.ph-film-strip:before {
  content: "\ed0f";
  opacity: 0.2;
}
.ph-duotone.ph-film-strip:after {
  content: "\ed10";
  margin-left: -1em;
}
.ph-duotone.ph-fingerprint:before {
  content: "\ed11";
  opacity: 0.2;
}
.ph-duotone.ph-fingerprint:after {
  content: "\ed12";
  margin-left: -1em;
}
.ph-duotone.ph-fingerprint-simple:before {
  content: "\ed13";
  opacity: 0.2;
}
.ph-duotone.ph-fingerprint-simple:after {
  content: "\ed14";
  margin-left: -1em;
}
.ph-duotone.ph-finn-the-human:before {
  content: "\ed15";
  opacity: 0.2;
}
.ph-duotone.ph-finn-the-human:after {
  content: "\ed16";
  margin-left: -1em;
}
.ph-duotone.ph-fire:before {
  content: "\ed17";
  opacity: 0.2;
}
.ph-duotone.ph-fire:after {
  content: "\ed18";
  margin-left: -1em;
}
.ph-duotone.ph-fire-extinguisher:before {
  content: "\ed19";
  opacity: 0.2;
}
.ph-duotone.ph-fire-extinguisher:after {
  content: "\ed1a";
  margin-left: -1em;
}
.ph-duotone.ph-fire-simple:before {
  content: "\ed1b";
  opacity: 0.2;
}
.ph-duotone.ph-fire-simple:after {
  content: "\ed1c";
  margin-left: -1em;
}
.ph-duotone.ph-first-aid:before {
  content: "\ed1d";
  opacity: 0.2;
}
.ph-duotone.ph-first-aid:after {
  content: "\ed1e";
  margin-left: -1em;
}
.ph-duotone.ph-first-aid-kit:before {
  content: "\ed1f";
  opacity: 0.2;
}
.ph-duotone.ph-first-aid-kit:after {
  content: "\ed20";
  margin-left: -1em;
}
.ph-duotone.ph-fish:before {
  content: "\ed21";
  opacity: 0.2;
}
.ph-duotone.ph-fish:after {
  content: "\ed22";
  margin-left: -1em;
}
.ph-duotone.ph-fish-simple:before {
  content: "\ed23";
  opacity: 0.2;
}
.ph-duotone.ph-fish-simple:after {
  content: "\ed24";
  margin-left: -1em;
}
.ph-duotone.ph-flag-banner:before {
  content: "\ed25";
  opacity: 0.2;
}
.ph-duotone.ph-flag-banner:after {
  content: "\ed26";
  margin-left: -1em;
}
.ph-duotone.ph-flag-checkered:before {
  content: "\ed27";
  opacity: 0.2;
}
.ph-duotone.ph-flag-checkered:after {
  content: "\ed28";
  margin-left: -1em;
}
.ph-duotone.ph-flag:before {
  content: "\ed29";
  opacity: 0.2;
}
.ph-duotone.ph-flag:after {
  content: "\ed2a";
  margin-left: -1em;
}
.ph-duotone.ph-flag-pennant:before {
  content: "\ed2b";
  opacity: 0.2;
}
.ph-duotone.ph-flag-pennant:after {
  content: "\ed2c";
  margin-left: -1em;
}
.ph-duotone.ph-flame:before {
  content: "\ed2d";
  opacity: 0.2;
}
.ph-duotone.ph-flame:after {
  content: "\ed2e";
  margin-left: -1em;
}
.ph-duotone.ph-flashlight:before {
  content: "\ed2f";
  opacity: 0.2;
}
.ph-duotone.ph-flashlight:after {
  content: "\ed30";
  margin-left: -1em;
}
.ph-duotone.ph-flask:before {
  content: "\ed31";
  opacity: 0.2;
}
.ph-duotone.ph-flask:after {
  content: "\ed32";
  margin-left: -1em;
}
.ph-duotone.ph-floppy-disk-back:before {
  content: "\ed33";
  opacity: 0.2;
}
.ph-duotone.ph-floppy-disk-back:after {
  content: "\ed34";
  margin-left: -1em;
}
.ph-duotone.ph-floppy-disk:before {
  content: "\ed35";
  opacity: 0.2;
}
.ph-duotone.ph-floppy-disk:after {
  content: "\ed36";
  margin-left: -1em;
}
.ph-duotone.ph-flow-arrow:before {
  content: "\ed37";
  opacity: 0.2;
}
.ph-duotone.ph-flow-arrow:after {
  content: "\ed38";
  margin-left: -1em;
}
.ph-duotone.ph-flower:before {
  content: "\ed39";
  opacity: 0.2;
}
.ph-duotone.ph-flower:after {
  content: "\ed3a";
  margin-left: -1em;
}
.ph-duotone.ph-flower-lotus:before {
  content: "\ed3b";
  opacity: 0.2;
}
.ph-duotone.ph-flower-lotus:after {
  content: "\ed3c";
  margin-left: -1em;
}
.ph-duotone.ph-flower-tulip:before {
  content: "\ed3d";
  opacity: 0.2;
}
.ph-duotone.ph-flower-tulip:after {
  content: "\ed3e";
  margin-left: -1em;
}
.ph-duotone.ph-flying-saucer:before {
  content: "\ed3f";
  opacity: 0.2;
}
.ph-duotone.ph-flying-saucer:after {
  content: "\ed40";
  margin-left: -1em;
}
.ph-duotone.ph-folder-dashed:before, .ph-duotone.ph-folder-dotted:before {
  content: "\ed41";
  opacity: 0.2;
}
.ph-duotone.ph-folder-dashed:after, .ph-duotone.ph-folder-dotted:after {
  content: "\ed42";
  margin-left: -1em;
}
.ph-duotone.ph-folder:before {
  content: "\ed43";
  opacity: 0.2;
}
.ph-duotone.ph-folder:after {
  content: "\ed44";
  margin-left: -1em;
}
.ph-duotone.ph-folder-lock:before {
  content: "\ed45";
  opacity: 0.2;
}
.ph-duotone.ph-folder-lock:after {
  content: "\ed46";
  margin-left: -1em;
}
.ph-duotone.ph-folder-minus:before {
  content: "\ed47";
  opacity: 0.2;
}
.ph-duotone.ph-folder-minus:after {
  content: "\ed48";
  margin-left: -1em;
}
.ph-duotone.ph-folder-notch:before {
  content: "\ed49";
  opacity: 0.2;
}
.ph-duotone.ph-folder-notch:after {
  content: "\ed4a";
  margin-left: -1em;
}
.ph-duotone.ph-folder-notch-minus:before {
  content: "\ed4b";
  opacity: 0.2;
}
.ph-duotone.ph-folder-notch-minus:after {
  content: "\ed4c";
  margin-left: -1em;
}
.ph-duotone.ph-folder-notch-open:before {
  content: "\ed4d";
  opacity: 0.2;
}
.ph-duotone.ph-folder-notch-open:after {
  content: "\ed4e";
  margin-left: -1em;
}
.ph-duotone.ph-folder-notch-plus:before {
  content: "\ed4f";
  opacity: 0.2;
}
.ph-duotone.ph-folder-notch-plus:after {
  content: "\ed50";
  margin-left: -1em;
}
.ph-duotone.ph-folder-open:before {
  content: "\ed51";
  opacity: 0.2;
}
.ph-duotone.ph-folder-open:after {
  content: "\ed52";
  margin-left: -1em;
}
.ph-duotone.ph-folder-plus:before {
  content: "\ed53";
  opacity: 0.2;
}
.ph-duotone.ph-folder-plus:after {
  content: "\ed54";
  margin-left: -1em;
}
.ph-duotone.ph-folders:before {
  content: "\ed55";
  opacity: 0.2;
}
.ph-duotone.ph-folders:after {
  content: "\ed56";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-dashed:before, .ph-duotone.ph-folder-simple-dotted:before {
  content: "\ed57";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-dashed:after, .ph-duotone.ph-folder-simple-dotted:after {
  content: "\ed58";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple:before {
  content: "\ed59";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple:after {
  content: "\ed5a";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-lock:before {
  content: "\ed5b";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-lock:after {
  content: "\ed5c";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-minus:before {
  content: "\ed5d";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-minus:after {
  content: "\ed5e";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-plus:before {
  content: "\ed5f";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-plus:after {
  content: "\ed60";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-star:before {
  content: "\ed61";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-star:after {
  content: "\ed62";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-user:before {
  content: "\ed63";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-user:after {
  content: "\ed64";
  margin-left: -1em;
}
.ph-duotone.ph-folder-star:before {
  content: "\ed65";
  opacity: 0.2;
}
.ph-duotone.ph-folder-star:after {
  content: "\ed66";
  margin-left: -1em;
}
.ph-duotone.ph-folder-user:before {
  content: "\ed67";
  opacity: 0.2;
}
.ph-duotone.ph-folder-user:after {
  content: "\ed68";
  margin-left: -1em;
}
.ph-duotone.ph-football:before {
  content: "\ed69";
  opacity: 0.2;
}
.ph-duotone.ph-football:after {
  content: "\ed6a";
  margin-left: -1em;
}
.ph-duotone.ph-footprints:before {
  content: "\ed6b";
  opacity: 0.2;
}
.ph-duotone.ph-footprints:after {
  content: "\ed6c";
  margin-left: -1em;
}
.ph-duotone.ph-fork-knife:before {
  content: "\ed6d";
  opacity: 0.2;
}
.ph-duotone.ph-fork-knife:after {
  content: "\ed6e";
  margin-left: -1em;
}
.ph-duotone.ph-frame-corners:before {
  content: "\ed6f";
  opacity: 0.2;
}
.ph-duotone.ph-frame-corners:after {
  content: "\ed70";
  margin-left: -1em;
}
.ph-duotone.ph-framer-logo:before {
  content: "\ed71";
  opacity: 0.2;
}
.ph-duotone.ph-framer-logo:after {
  content: "\ed72";
  margin-left: -1em;
}
.ph-duotone.ph-function:before {
  content: "\ed73";
  opacity: 0.2;
}
.ph-duotone.ph-function:after {
  content: "\ed74";
  margin-left: -1em;
}
.ph-duotone.ph-funnel:before {
  content: "\ed75";
  opacity: 0.2;
}
.ph-duotone.ph-funnel:after {
  content: "\ed76";
  margin-left: -1em;
}
.ph-duotone.ph-funnel-simple:before {
  content: "\ed77";
  opacity: 0.2;
}
.ph-duotone.ph-funnel-simple:after {
  content: "\ed78";
  margin-left: -1em;
}
.ph-duotone.ph-game-controller:before {
  content: "\ed79";
  opacity: 0.2;
}
.ph-duotone.ph-game-controller:after {
  content: "\ed7a";
  margin-left: -1em;
}
.ph-duotone.ph-garage:before {
  content: "\ed7b";
  opacity: 0.2;
}
.ph-duotone.ph-garage:after {
  content: "\ed7c";
  margin-left: -1em;
}
.ph-duotone.ph-gas-can:before {
  content: "\ed7d";
  opacity: 0.2;
}
.ph-duotone.ph-gas-can:after {
  content: "\ed7e";
  margin-left: -1em;
}
.ph-duotone.ph-gas-pump:before {
  content: "\ed7f";
  opacity: 0.2;
}
.ph-duotone.ph-gas-pump:after {
  content: "\ed80";
  margin-left: -1em;
}
.ph-duotone.ph-gauge:before {
  content: "\ed81";
  opacity: 0.2;
}
.ph-duotone.ph-gauge:after {
  content: "\ed82";
  margin-left: -1em;
}
.ph-duotone.ph-gavel:before {
  content: "\ed83";
  opacity: 0.2;
}
.ph-duotone.ph-gavel:after {
  content: "\ed84";
  margin-left: -1em;
}
.ph-duotone.ph-gear:before {
  content: "\ed85";
  opacity: 0.2;
}
.ph-duotone.ph-gear:after {
  content: "\ed86";
  margin-left: -1em;
}
.ph-duotone.ph-gear-fine:before {
  content: "\ed87";
  opacity: 0.2;
}
.ph-duotone.ph-gear-fine:after {
  content: "\ed88";
  margin-left: -1em;
}
.ph-duotone.ph-gear-six:before {
  content: "\ed89";
  opacity: 0.2;
}
.ph-duotone.ph-gear-six:after {
  content: "\ed8a";
  margin-left: -1em;
}
.ph-duotone.ph-gender-female:before {
  content: "\ed8b";
  opacity: 0.2;
}
.ph-duotone.ph-gender-female:after {
  content: "\ed8c";
  margin-left: -1em;
}
.ph-duotone.ph-gender-intersex:before {
  content: "\ed8d";
  opacity: 0.2;
}
.ph-duotone.ph-gender-intersex:after {
  content: "\ed8e";
  margin-left: -1em;
}
.ph-duotone.ph-gender-male:before {
  content: "\ed8f";
  opacity: 0.2;
}
.ph-duotone.ph-gender-male:after {
  content: "\ed90";
  margin-left: -1em;
}
.ph-duotone.ph-gender-neuter:before {
  content: "\ed91";
  opacity: 0.2;
}
.ph-duotone.ph-gender-neuter:after {
  content: "\ed92";
  margin-left: -1em;
}
.ph-duotone.ph-gender-nonbinary:before {
  content: "\ed93";
  opacity: 0.2;
}
.ph-duotone.ph-gender-nonbinary:after {
  content: "\ed94";
  margin-left: -1em;
}
.ph-duotone.ph-gender-transgender:before {
  content: "\ed95";
  opacity: 0.2;
}
.ph-duotone.ph-gender-transgender:after {
  content: "\ed96";
  margin-left: -1em;
}
.ph-duotone.ph-ghost:before {
  content: "\ed97";
  opacity: 0.2;
}
.ph-duotone.ph-ghost:after {
  content: "\ed98";
  margin-left: -1em;
}
.ph-duotone.ph-gif:before {
  content: "\ed99";
  opacity: 0.2;
}
.ph-duotone.ph-gif:after {
  content: "\ed9a";
  margin-left: -1em;
}
.ph-duotone.ph-gift:before {
  content: "\ed9b";
  opacity: 0.2;
}
.ph-duotone.ph-gift:after {
  content: "\ed9c";
  margin-left: -1em;
}
.ph-duotone.ph-git-branch:before {
  content: "\ed9d";
  opacity: 0.2;
}
.ph-duotone.ph-git-branch:after {
  content: "\ed9e";
  margin-left: -1em;
}
.ph-duotone.ph-git-commit:before {
  content: "\ed9f";
  opacity: 0.2;
}
.ph-duotone.ph-git-commit:after {
  content: "\eda0";
  margin-left: -1em;
}
.ph-duotone.ph-git-diff:before {
  content: "\eda1";
  opacity: 0.2;
}
.ph-duotone.ph-git-diff:after {
  content: "\eda2";
  margin-left: -1em;
}
.ph-duotone.ph-git-fork:before {
  content: "\eda3";
  opacity: 0.2;
}
.ph-duotone.ph-git-fork:after {
  content: "\eda4";
  margin-left: -1em;
}
.ph-duotone.ph-github-logo:before {
  content: "\eda5";
  opacity: 0.2;
}
.ph-duotone.ph-github-logo:after {
  content: "\eda6";
  margin-left: -1em;
}
.ph-duotone.ph-gitlab-logo:before {
  content: "\eda7";
  opacity: 0.2;
}
.ph-duotone.ph-gitlab-logo:after {
  content: "\eda8";
  margin-left: -1em;
}
.ph-duotone.ph-gitlab-logo-simple:before {
  content: "\eda9";
  opacity: 0.2;
}
.ph-duotone.ph-gitlab-logo-simple:after {
  content: "\edaa";
  margin-left: -1em;
}
.ph-duotone.ph-git-merge:before {
  content: "\edab";
  opacity: 0.2;
}
.ph-duotone.ph-git-merge:after {
  content: "\edac";
  margin-left: -1em;
}
.ph-duotone.ph-git-pull-request:before {
  content: "\edad";
  opacity: 0.2;
}
.ph-duotone.ph-git-pull-request:after {
  content: "\edae";
  margin-left: -1em;
}
.ph-duotone.ph-globe:before {
  content: "\edaf";
  opacity: 0.2;
}
.ph-duotone.ph-globe:after {
  content: "\edb0";
  margin-left: -1em;
}
.ph-duotone.ph-globe-hemisphere-east:before {
  content: "\edb1";
  opacity: 0.2;
}
.ph-duotone.ph-globe-hemisphere-east:after {
  content: "\edb2";
  margin-left: -1em;
}
.ph-duotone.ph-globe-hemisphere-west:before {
  content: "\edb3";
  opacity: 0.2;
}
.ph-duotone.ph-globe-hemisphere-west:after {
  content: "\edb4";
  margin-left: -1em;
}
.ph-duotone.ph-globe-simple:before {
  content: "\edb5";
  opacity: 0.2;
}
.ph-duotone.ph-globe-simple:after {
  content: "\edb6";
  margin-left: -1em;
}
.ph-duotone.ph-globe-stand:before {
  content: "\edb7";
  opacity: 0.2;
}
.ph-duotone.ph-globe-stand:after {
  content: "\edb8";
  margin-left: -1em;
}
.ph-duotone.ph-goggles:before {
  content: "\edb9";
  opacity: 0.2;
}
.ph-duotone.ph-goggles:after {
  content: "\edba";
  margin-left: -1em;
}
.ph-duotone.ph-goodreads-logo:before {
  content: "\edbb";
  opacity: 0.2;
}
.ph-duotone.ph-goodreads-logo:after {
  content: "\edbc";
  margin-left: -1em;
}
.ph-duotone.ph-google-cardboard-logo:before {
  content: "\edbd";
  opacity: 0.2;
}
.ph-duotone.ph-google-cardboard-logo:after {
  content: "\edbe";
  margin-left: -1em;
}
.ph-duotone.ph-google-chrome-logo:before {
  content: "\edbf";
  opacity: 0.2;
}
.ph-duotone.ph-google-chrome-logo:after {
  content: "\edc0";
  margin-left: -1em;
}
.ph-duotone.ph-google-drive-logo:before {
  content: "\edc1";
  opacity: 0.2;
}
.ph-duotone.ph-google-drive-logo:after {
  content: "\edc2";
  margin-left: -1em;
}
.ph-duotone.ph-google-logo:before {
  content: "\edc3";
  opacity: 0.2;
}
.ph-duotone.ph-google-logo:after {
  content: "\edc4";
  margin-left: -1em;
}
.ph-duotone.ph-google-photos-logo:before {
  content: "\edc5";
  opacity: 0.2;
}
.ph-duotone.ph-google-photos-logo:after {
  content: "\edc6";
  margin-left: -1em;
}
.ph-duotone.ph-google-play-logo:before {
  content: "\edc7";
  opacity: 0.2;
}
.ph-duotone.ph-google-play-logo:after {
  content: "\edc8";
  margin-left: -1em;
}
.ph-duotone.ph-google-podcasts-logo:before {
  content: "\edc9";
  opacity: 0.2;
}
.ph-duotone.ph-google-podcasts-logo:after {
  content: "\edca";
  margin-left: -1em;
}
.ph-duotone.ph-gradient:before {
  content: "\edcb";
  opacity: 0.2;
}
.ph-duotone.ph-gradient:after {
  content: "\edcc";
  margin-left: -1em;
}
.ph-duotone.ph-graduation-cap:before {
  content: "\edcd";
  opacity: 0.2;
}
.ph-duotone.ph-graduation-cap:after {
  content: "\edce";
  margin-left: -1em;
}
.ph-duotone.ph-grains:before {
  content: "\edcf";
  opacity: 0.2;
}
.ph-duotone.ph-grains:after {
  content: "\edd0";
  margin-left: -1em;
}
.ph-duotone.ph-grains-slash:before {
  content: "\edd1";
  opacity: 0.2;
}
.ph-duotone.ph-grains-slash:after {
  content: "\edd2";
  margin-left: -1em;
}
.ph-duotone.ph-graph:before {
  content: "\edd3";
  opacity: 0.2;
}
.ph-duotone.ph-graph:after {
  content: "\edd4";
  margin-left: -1em;
}
.ph-duotone.ph-grid-four:before {
  content: "\edd5";
  opacity: 0.2;
}
.ph-duotone.ph-grid-four:after {
  content: "\edd6";
  margin-left: -1em;
}
.ph-duotone.ph-grid-nine:before {
  content: "\edd7";
  opacity: 0.2;
}
.ph-duotone.ph-grid-nine:after {
  content: "\edd8";
  margin-left: -1em;
}
.ph-duotone.ph-guitar:before {
  content: "\edd9";
  opacity: 0.2;
}
.ph-duotone.ph-guitar:after {
  content: "\edda";
  margin-left: -1em;
}
.ph-duotone.ph-hamburger:before {
  content: "\eddb";
  opacity: 0.2;
}
.ph-duotone.ph-hamburger:after {
  content: "\eddc";
  margin-left: -1em;
}
.ph-duotone.ph-hammer:before {
  content: "\eddd";
  opacity: 0.2;
}
.ph-duotone.ph-hammer:after {
  content: "\edde";
  margin-left: -1em;
}
.ph-duotone.ph-handbag:before {
  content: "\eddf";
  opacity: 0.2;
}
.ph-duotone.ph-handbag:after {
  content: "\ede0";
  margin-left: -1em;
}
.ph-duotone.ph-handbag-simple:before {
  content: "\ede1";
  opacity: 0.2;
}
.ph-duotone.ph-handbag-simple:after {
  content: "\ede2";
  margin-left: -1em;
}
.ph-duotone.ph-hand-coins:before {
  content: "\ede3";
  opacity: 0.2;
}
.ph-duotone.ph-hand-coins:after {
  content: "\ede4";
  margin-left: -1em;
}
.ph-duotone.ph-hand:before {
  content: "\ede5";
  opacity: 0.2;
}
.ph-duotone.ph-hand:after {
  content: "\ede6";
  margin-left: -1em;
}
.ph-duotone.ph-hand-eye:before {
  content: "\ede7";
  opacity: 0.2;
}
.ph-duotone.ph-hand-eye:after {
  content: "\ede8";
  margin-left: -1em;
}
.ph-duotone.ph-hand-fist:before {
  content: "\ede9";
  opacity: 0.2;
}
.ph-duotone.ph-hand-fist:after {
  content: "\edea";
  margin-left: -1em;
}
.ph-duotone.ph-hand-grabbing:before {
  content: "\edeb";
  opacity: 0.2;
}
.ph-duotone.ph-hand-grabbing:after {
  content: "\edec";
  margin-left: -1em;
}
.ph-duotone.ph-hand-heart:before {
  content: "\eded";
  opacity: 0.2;
}
.ph-duotone.ph-hand-heart:after {
  content: "\edee";
  margin-left: -1em;
}
.ph-duotone.ph-hand-palm:before {
  content: "\edef";
  opacity: 0.2;
}
.ph-duotone.ph-hand-palm:after {
  content: "\edf0";
  margin-left: -1em;
}
.ph-duotone.ph-hand-pointing:before {
  content: "\edf1";
  opacity: 0.2;
}
.ph-duotone.ph-hand-pointing:after {
  content: "\edf2";
  margin-left: -1em;
}
.ph-duotone.ph-hands-clapping:before {
  content: "\edf3";
  opacity: 0.2;
}
.ph-duotone.ph-hands-clapping:after {
  content: "\edf4";
  margin-left: -1em;
}
.ph-duotone.ph-handshake:before {
  content: "\edf5";
  opacity: 0.2;
}
.ph-duotone.ph-handshake:after {
  content: "\edf6";
  margin-left: -1em;
}
.ph-duotone.ph-hand-soap:before {
  content: "\edf7";
  opacity: 0.2;
}
.ph-duotone.ph-hand-soap:after {
  content: "\edf8";
  margin-left: -1em;
}
.ph-duotone.ph-hands-praying:before {
  content: "\edf9";
  opacity: 0.2;
}
.ph-duotone.ph-hands-praying:after {
  content: "\edfa";
  margin-left: -1em;
}
.ph-duotone.ph-hand-swipe-left:before {
  content: "\edfb";
  opacity: 0.2;
}
.ph-duotone.ph-hand-swipe-left:after {
  content: "\edfc";
  margin-left: -1em;
}
.ph-duotone.ph-hand-swipe-right:before {
  content: "\edfd";
  opacity: 0.2;
}
.ph-duotone.ph-hand-swipe-right:after {
  content: "\edfe";
  margin-left: -1em;
}
.ph-duotone.ph-hand-tap:before {
  content: "\edff";
  opacity: 0.2;
}
.ph-duotone.ph-hand-tap:after {
  content: "\ee00";
  margin-left: -1em;
}
.ph-duotone.ph-hand-waving:before {
  content: "\ee01";
  opacity: 0.2;
}
.ph-duotone.ph-hand-waving:after {
  content: "\ee02";
  margin-left: -1em;
}
.ph-duotone.ph-hard-drive:before {
  content: "\ee03";
  opacity: 0.2;
}
.ph-duotone.ph-hard-drive:after {
  content: "\ee04";
  margin-left: -1em;
}
.ph-duotone.ph-hard-drives:before {
  content: "\ee05";
  opacity: 0.2;
}
.ph-duotone.ph-hard-drives:after {
  content: "\ee06";
  margin-left: -1em;
}
.ph-duotone.ph-hash:before {
  content: "\ee07";
  opacity: 0.2;
}
.ph-duotone.ph-hash:after {
  content: "\ee08";
  margin-left: -1em;
}
.ph-duotone.ph-hash-straight:before {
  content: "\ee09";
  opacity: 0.2;
}
.ph-duotone.ph-hash-straight:after {
  content: "\ee0a";
  margin-left: -1em;
}
.ph-duotone.ph-headlights:before {
  content: "\ee0b";
  opacity: 0.2;
}
.ph-duotone.ph-headlights:after {
  content: "\ee0c";
  margin-left: -1em;
}
.ph-duotone.ph-headphones:before {
  content: "\ee0d";
  opacity: 0.2;
}
.ph-duotone.ph-headphones:after {
  content: "\ee0e";
  margin-left: -1em;
}
.ph-duotone.ph-headset:before {
  content: "\ee0f";
  opacity: 0.2;
}
.ph-duotone.ph-headset:after {
  content: "\ee10";
  margin-left: -1em;
}
.ph-duotone.ph-heartbeat:before {
  content: "\ee11";
  opacity: 0.2;
}
.ph-duotone.ph-heartbeat:after {
  content: "\ee12";
  margin-left: -1em;
}
.ph-duotone.ph-heart-break:before {
  content: "\ee13";
  opacity: 0.2;
}
.ph-duotone.ph-heart-break:after {
  content: "\ee14";
  margin-left: -1em;
}
.ph-duotone.ph-heart:before {
  content: "\ee15";
  opacity: 0.2;
}
.ph-duotone.ph-heart:after {
  content: "\ee16";
  margin-left: -1em;
}
.ph-duotone.ph-heart-half:before {
  content: "\ee17";
  opacity: 0.2;
}
.ph-duotone.ph-heart-half:after {
  content: "\ee18";
  margin-left: -1em;
}
.ph-duotone.ph-heart-straight-break:before {
  content: "\ee19";
  opacity: 0.2;
}
.ph-duotone.ph-heart-straight-break:after {
  content: "\ee1a";
  margin-left: -1em;
}
.ph-duotone.ph-heart-straight:before {
  content: "\ee1b";
  opacity: 0.2;
}
.ph-duotone.ph-heart-straight:after {
  content: "\ee1c";
  margin-left: -1em;
}
.ph-duotone.ph-hexagon:before {
  content: "\ee1d";
  opacity: 0.2;
}
.ph-duotone.ph-hexagon:after {
  content: "\ee1e";
  margin-left: -1em;
}
.ph-duotone.ph-high-heel:before {
  content: "\ee1f";
  opacity: 0.2;
}
.ph-duotone.ph-high-heel:after {
  content: "\ee20";
  margin-left: -1em;
}
.ph-duotone.ph-highlighter-circle:before {
  content: "\ee21";
  opacity: 0.2;
}
.ph-duotone.ph-highlighter-circle:after {
  content: "\ee22";
  margin-left: -1em;
}
.ph-duotone.ph-hoodie:before {
  content: "\ee23";
  opacity: 0.2;
}
.ph-duotone.ph-hoodie:after {
  content: "\ee24";
  margin-left: -1em;
}
.ph-duotone.ph-horse:before {
  content: "\ee25";
  opacity: 0.2;
}
.ph-duotone.ph-horse:after {
  content: "\ee26";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass:before {
  content: "\ee27";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass:after {
  content: "\ee28";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-high:before {
  content: "\ee29";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-high:after {
  content: "\ee2a";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-low:before {
  content: "\ee2b";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-low:after {
  content: "\ee2c";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-medium:before {
  content: "\ee2d";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-medium:after {
  content: "\ee2e";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-simple:before {
  content: "\ee2f";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-simple:after {
  content: "\ee30";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-simple-high:before {
  content: "\ee31";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-simple-high:after {
  content: "\ee32";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-simple-low:before {
  content: "\ee33";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-simple-low:after {
  content: "\ee34";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-simple-medium:before {
  content: "\ee35";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-simple-medium:after {
  content: "\ee36";
  margin-left: -1em;
}
.ph-duotone.ph-house:before {
  content: "\ee37";
  opacity: 0.2;
}
.ph-duotone.ph-house:after {
  content: "\ee38";
  margin-left: -1em;
}
.ph-duotone.ph-house-line:before {
  content: "\ee39";
  opacity: 0.2;
}
.ph-duotone.ph-house-line:after {
  content: "\ee3a";
  margin-left: -1em;
}
.ph-duotone.ph-house-simple:before {
  content: "\ee3b";
  opacity: 0.2;
}
.ph-duotone.ph-house-simple:after {
  content: "\ee3c";
  margin-left: -1em;
}
.ph-duotone.ph-ice-cream:before {
  content: "\ee3d";
  opacity: 0.2;
}
.ph-duotone.ph-ice-cream:after {
  content: "\ee3e";
  margin-left: -1em;
}
.ph-duotone.ph-identification-badge:before {
  content: "\ee3f";
  opacity: 0.2;
}
.ph-duotone.ph-identification-badge:after {
  content: "\ee40";
  margin-left: -1em;
}
.ph-duotone.ph-identification-card:before {
  content: "\ee41";
  opacity: 0.2;
}
.ph-duotone.ph-identification-card:after {
  content: "\ee42";
  margin-left: -1em;
}
.ph-duotone.ph-image:before {
  content: "\ee43";
  opacity: 0.2;
}
.ph-duotone.ph-image:after {
  content: "\ee44";
  margin-left: -1em;
}
.ph-duotone.ph-images:before {
  content: "\ee45";
  opacity: 0.2;
}
.ph-duotone.ph-images:after {
  content: "\ee46";
  margin-left: -1em;
}
.ph-duotone.ph-image-square:before {
  content: "\ee47";
  opacity: 0.2;
}
.ph-duotone.ph-image-square:after {
  content: "\ee48";
  margin-left: -1em;
}
.ph-duotone.ph-images-square:before {
  content: "\ee49";
  opacity: 0.2;
}
.ph-duotone.ph-images-square:after {
  content: "\ee4a";
  margin-left: -1em;
}
.ph-duotone.ph-infinity:before {
  content: "\ee4b";
  opacity: 0.2;
}
.ph-duotone.ph-infinity:after {
  content: "\ee4c";
  margin-left: -1em;
}
.ph-duotone.ph-info:before {
  content: "\ee4d";
  opacity: 0.2;
}
.ph-duotone.ph-info:after {
  content: "\ee4e";
  margin-left: -1em;
}
.ph-duotone.ph-instagram-logo:before {
  content: "\ee4f";
  opacity: 0.2;
}
.ph-duotone.ph-instagram-logo:after {
  content: "\ee50";
  margin-left: -1em;
}
.ph-duotone.ph-intersect:before {
  content: "\ee51";
  opacity: 0.2;
}
.ph-duotone.ph-intersect:after {
  content: "\ee52";
  margin-left: -1em;
}
.ph-duotone.ph-intersect-square:before {
  content: "\ee53";
  opacity: 0.2;
}
.ph-duotone.ph-intersect-square:after {
  content: "\ee54";
  margin-left: -1em;
}
.ph-duotone.ph-intersect-three:before {
  content: "\ee55";
  opacity: 0.2;
}
.ph-duotone.ph-intersect-three:after {
  content: "\ee56";
  margin-left: -1em;
}
.ph-duotone.ph-jeep:before {
  content: "\ee57";
  opacity: 0.2;
}
.ph-duotone.ph-jeep:after {
  content: "\ee58";
  margin-left: -1em;
}
.ph-duotone.ph-kanban:before {
  content: "\ee59";
  opacity: 0.2;
}
.ph-duotone.ph-kanban:after {
  content: "\ee5a";
  margin-left: -1em;
}
.ph-duotone.ph-keyboard:before {
  content: "\ee5b";
  opacity: 0.2;
}
.ph-duotone.ph-keyboard:after {
  content: "\ee5c";
  margin-left: -1em;
}
.ph-duotone.ph-key:before {
  content: "\ee5d";
  opacity: 0.2;
}
.ph-duotone.ph-key:after {
  content: "\ee5e";
  margin-left: -1em;
}
.ph-duotone.ph-keyhole:before {
  content: "\ee5f";
  opacity: 0.2;
}
.ph-duotone.ph-keyhole:after {
  content: "\ee60";
  margin-left: -1em;
}
.ph-duotone.ph-key-return:before {
  content: "\ee61";
  opacity: 0.2;
}
.ph-duotone.ph-key-return:after {
  content: "\ee62";
  margin-left: -1em;
}
.ph-duotone.ph-knife:before {
  content: "\ee63";
  opacity: 0.2;
}
.ph-duotone.ph-knife:after {
  content: "\ee64";
  margin-left: -1em;
}
.ph-duotone.ph-ladder:before {
  content: "\ee65";
  opacity: 0.2;
}
.ph-duotone.ph-ladder:after {
  content: "\ee66";
  margin-left: -1em;
}
.ph-duotone.ph-ladder-simple:before {
  content: "\ee67";
  opacity: 0.2;
}
.ph-duotone.ph-ladder-simple:after {
  content: "\ee68";
  margin-left: -1em;
}
.ph-duotone.ph-lamp:before {
  content: "\ee69";
  opacity: 0.2;
}
.ph-duotone.ph-lamp:after {
  content: "\ee6a";
  margin-left: -1em;
}
.ph-duotone.ph-laptop:before {
  content: "\ee6b";
  opacity: 0.2;
}
.ph-duotone.ph-laptop:after {
  content: "\ee6c";
  margin-left: -1em;
}
.ph-duotone.ph-layout:before {
  content: "\ee6d";
  opacity: 0.2;
}
.ph-duotone.ph-layout:after {
  content: "\ee6e";
  margin-left: -1em;
}
.ph-duotone.ph-leaf:before {
  content: "\ee6f";
  opacity: 0.2;
}
.ph-duotone.ph-leaf:after {
  content: "\ee70";
  margin-left: -1em;
}
.ph-duotone.ph-lifebuoy:before {
  content: "\ee71";
  opacity: 0.2;
}
.ph-duotone.ph-lifebuoy:after {
  content: "\ee72";
  margin-left: -1em;
}
.ph-duotone.ph-lightbulb:before {
  content: "\ee73";
  opacity: 0.2;
}
.ph-duotone.ph-lightbulb:after {
  content: "\ee74";
  margin-left: -1em;
}
.ph-duotone.ph-lightbulb-filament:before {
  content: "\ee75";
  opacity: 0.2;
}
.ph-duotone.ph-lightbulb-filament:after {
  content: "\ee76";
  margin-left: -1em;
}
.ph-duotone.ph-lighthouse:before {
  content: "\ee77";
  opacity: 0.2;
}
.ph-duotone.ph-lighthouse:after {
  content: "\ee78";
  margin-left: -1em;
}
.ph-duotone.ph-lightning-a:before {
  content: "\ee79";
  opacity: 0.2;
}
.ph-duotone.ph-lightning-a:after {
  content: "\ee7a";
  margin-left: -1em;
}
.ph-duotone.ph-lightning:before {
  content: "\ee7b";
  opacity: 0.2;
}
.ph-duotone.ph-lightning:after {
  content: "\ee7c";
  margin-left: -1em;
}
.ph-duotone.ph-lightning-slash:before {
  content: "\ee7d";
  opacity: 0.2;
}
.ph-duotone.ph-lightning-slash:after {
  content: "\ee7e";
  margin-left: -1em;
}
.ph-duotone.ph-line-segment:before {
  content: "\ee7f";
  opacity: 0.2;
}
.ph-duotone.ph-line-segment:after {
  content: "\ee80";
  margin-left: -1em;
}
.ph-duotone.ph-line-segments:before {
  content: "\ee81";
  opacity: 0.2;
}
.ph-duotone.ph-line-segments:after {
  content: "\ee82";
  margin-left: -1em;
}
.ph-duotone.ph-link-break:before {
  content: "\ee83";
  opacity: 0.2;
}
.ph-duotone.ph-link-break:after {
  content: "\ee84";
  margin-left: -1em;
}
.ph-duotone.ph-link:before {
  content: "\ee85";
  opacity: 0.2;
}
.ph-duotone.ph-link:after {
  content: "\ee86";
  margin-left: -1em;
}
.ph-duotone.ph-linkedin-logo:before {
  content: "\ee87";
  opacity: 0.2;
}
.ph-duotone.ph-linkedin-logo:after {
  content: "\ee88";
  margin-left: -1em;
}
.ph-duotone.ph-link-simple-break:before {
  content: "\ee89";
  opacity: 0.2;
}
.ph-duotone.ph-link-simple-break:after {
  content: "\ee8a";
  margin-left: -1em;
}
.ph-duotone.ph-link-simple:before {
  content: "\ee8b";
  opacity: 0.2;
}
.ph-duotone.ph-link-simple:after {
  content: "\ee8c";
  margin-left: -1em;
}
.ph-duotone.ph-link-simple-horizontal-break:before {
  content: "\ee8d";
  opacity: 0.2;
}
.ph-duotone.ph-link-simple-horizontal-break:after {
  content: "\ee8e";
  margin-left: -1em;
}
.ph-duotone.ph-link-simple-horizontal:before {
  content: "\ee8f";
  opacity: 0.2;
}
.ph-duotone.ph-link-simple-horizontal:after {
  content: "\ee90";
  margin-left: -1em;
}
.ph-duotone.ph-linux-logo:before {
  content: "\ee91";
  opacity: 0.2;
}
.ph-duotone.ph-linux-logo:after {
  content: "\ee92";
  margin-left: -1em;
}
.ph-duotone.ph-list-bullets:before {
  content: "\ee93";
  opacity: 0.2;
}
.ph-duotone.ph-list-bullets:after {
  content: "\ee94";
  margin-left: -1em;
}
.ph-duotone.ph-list-checks:before {
  content: "\ee95";
  opacity: 0.2;
}
.ph-duotone.ph-list-checks:after {
  content: "\ee96";
  margin-left: -1em;
}
.ph-duotone.ph-list-dashes:before {
  content: "\ee97";
  opacity: 0.2;
}
.ph-duotone.ph-list-dashes:after {
  content: "\ee98";
  margin-left: -1em;
}
.ph-duotone.ph-list:before {
  content: "\ee99";
  opacity: 0.2;
}
.ph-duotone.ph-list:after {
  content: "\ee9a";
  margin-left: -1em;
}
.ph-duotone.ph-list-magnifying-glass:before {
  content: "\ee9b";
  opacity: 0.2;
}
.ph-duotone.ph-list-magnifying-glass:after {
  content: "\ee9c";
  margin-left: -1em;
}
.ph-duotone.ph-list-numbers:before {
  content: "\ee9d";
  opacity: 0.2;
}
.ph-duotone.ph-list-numbers:after {
  content: "\ee9e";
  margin-left: -1em;
}
.ph-duotone.ph-list-plus:before {
  content: "\ee9f";
  opacity: 0.2;
}
.ph-duotone.ph-list-plus:after {
  content: "\eea0";
  margin-left: -1em;
}
.ph-duotone.ph-lock:before {
  content: "\eea1";
  opacity: 0.2;
}
.ph-duotone.ph-lock:after {
  content: "\eea2";
  margin-left: -1em;
}
.ph-duotone.ph-lockers:before {
  content: "\eea3";
  opacity: 0.2;
}
.ph-duotone.ph-lockers:after {
  content: "\eea4";
  margin-left: -1em;
}
.ph-duotone.ph-lock-key:before {
  content: "\eea5";
  opacity: 0.2;
}
.ph-duotone.ph-lock-key:after {
  content: "\eea6";
  margin-left: -1em;
}
.ph-duotone.ph-lock-key-open:before {
  content: "\eea7";
  opacity: 0.2;
}
.ph-duotone.ph-lock-key-open:after {
  content: "\eea8";
  margin-left: -1em;
}
.ph-duotone.ph-lock-laminated:before {
  content: "\eea9";
  opacity: 0.2;
}
.ph-duotone.ph-lock-laminated:after {
  content: "\eeaa";
  margin-left: -1em;
}
.ph-duotone.ph-lock-laminated-open:before {
  content: "\eeab";
  opacity: 0.2;
}
.ph-duotone.ph-lock-laminated-open:after {
  content: "\eeac";
  margin-left: -1em;
}
.ph-duotone.ph-lock-open:before {
  content: "\eead";
  opacity: 0.2;
}
.ph-duotone.ph-lock-open:after {
  content: "\eeae";
  margin-left: -1em;
}
.ph-duotone.ph-lock-simple:before {
  content: "\eeaf";
  opacity: 0.2;
}
.ph-duotone.ph-lock-simple:after {
  content: "\eeb0";
  margin-left: -1em;
}
.ph-duotone.ph-lock-simple-open:before {
  content: "\eeb1";
  opacity: 0.2;
}
.ph-duotone.ph-lock-simple-open:after {
  content: "\eeb2";
  margin-left: -1em;
}
.ph-duotone.ph-magic-wand:before {
  content: "\eeb3";
  opacity: 0.2;
}
.ph-duotone.ph-magic-wand:after {
  content: "\eeb4";
  margin-left: -1em;
}
.ph-duotone.ph-magnet:before {
  content: "\eeb5";
  opacity: 0.2;
}
.ph-duotone.ph-magnet:after {
  content: "\eeb6";
  margin-left: -1em;
}
.ph-duotone.ph-magnet-straight:before {
  content: "\eeb7";
  opacity: 0.2;
}
.ph-duotone.ph-magnet-straight:after {
  content: "\eeb8";
  margin-left: -1em;
}
.ph-duotone.ph-magnifying-glass:before {
  content: "\eeb9";
  opacity: 0.2;
}
.ph-duotone.ph-magnifying-glass:after {
  content: "\eeba";
  margin-left: -1em;
}
.ph-duotone.ph-magnifying-glass-minus:before {
  content: "\eebb";
  opacity: 0.2;
}
.ph-duotone.ph-magnifying-glass-minus:after {
  content: "\eebc";
  margin-left: -1em;
}
.ph-duotone.ph-magnifying-glass-plus:before {
  content: "\eebd";
  opacity: 0.2;
}
.ph-duotone.ph-magnifying-glass-plus:after {
  content: "\eebe";
  margin-left: -1em;
}
.ph-duotone.ph-map-pin:before {
  content: "\eebf";
  opacity: 0.2;
}
.ph-duotone.ph-map-pin:after {
  content: "\eec0";
  margin-left: -1em;
}
.ph-duotone.ph-map-pin-line:before {
  content: "\eec1";
  opacity: 0.2;
}
.ph-duotone.ph-map-pin-line:after {
  content: "\eec2";
  margin-left: -1em;
}
.ph-duotone.ph-map-trifold:before {
  content: "\eec3";
  opacity: 0.2;
}
.ph-duotone.ph-map-trifold:after {
  content: "\eec4";
  margin-left: -1em;
}
.ph-duotone.ph-marker-circle:before {
  content: "\eec5";
  opacity: 0.2;
}
.ph-duotone.ph-marker-circle:after {
  content: "\eec6";
  margin-left: -1em;
}
.ph-duotone.ph-martini:before {
  content: "\eec7";
  opacity: 0.2;
}
.ph-duotone.ph-martini:after {
  content: "\eec8";
  margin-left: -1em;
}
.ph-duotone.ph-mask-happy:before {
  content: "\eec9";
  opacity: 0.2;
}
.ph-duotone.ph-mask-happy:after {
  content: "\eeca";
  margin-left: -1em;
}
.ph-duotone.ph-mask-sad:before {
  content: "\eecb";
  opacity: 0.2;
}
.ph-duotone.ph-mask-sad:after {
  content: "\eecc";
  margin-left: -1em;
}
.ph-duotone.ph-math-operations:before {
  content: "\eecd";
  opacity: 0.2;
}
.ph-duotone.ph-math-operations:after {
  content: "\eece";
  margin-left: -1em;
}
.ph-duotone.ph-medal:before {
  content: "\eecf";
  opacity: 0.2;
}
.ph-duotone.ph-medal:after {
  content: "\eed0";
  margin-left: -1em;
}
.ph-duotone.ph-medal-military:before {
  content: "\eed1";
  opacity: 0.2;
}
.ph-duotone.ph-medal-military:after {
  content: "\eed2";
  margin-left: -1em;
}
.ph-duotone.ph-medium-logo:before {
  content: "\eed3";
  opacity: 0.2;
}
.ph-duotone.ph-medium-logo:after {
  content: "\eed4";
  margin-left: -1em;
}
.ph-duotone.ph-megaphone:before {
  content: "\eed5";
  opacity: 0.2;
}
.ph-duotone.ph-megaphone:after {
  content: "\eed6";
  margin-left: -1em;
}
.ph-duotone.ph-megaphone-simple:before {
  content: "\eed7";
  opacity: 0.2;
}
.ph-duotone.ph-megaphone-simple:after {
  content: "\eed8";
  margin-left: -1em;
}
.ph-duotone.ph-messenger-logo:before {
  content: "\eed9";
  opacity: 0.2;
}
.ph-duotone.ph-messenger-logo:after {
  content: "\eeda";
  margin-left: -1em;
}
.ph-duotone.ph-meta-logo:before {
  content: "\eedb";
  opacity: 0.2;
}
.ph-duotone.ph-meta-logo:after {
  content: "\eedc";
  margin-left: -1em;
}
.ph-duotone.ph-metronome:before {
  content: "\eedd";
  opacity: 0.2;
}
.ph-duotone.ph-metronome:after {
  content: "\eede";
  margin-left: -1em;
}
.ph-duotone.ph-microphone:before {
  content: "\eedf";
  opacity: 0.2;
}
.ph-duotone.ph-microphone:after {
  content: "\eee0";
  margin-left: -1em;
}
.ph-duotone.ph-microphone-slash:before {
  content: "\eee1";
  opacity: 0.2;
}
.ph-duotone.ph-microphone-slash:after {
  content: "\eee2";
  margin-left: -1em;
}
.ph-duotone.ph-microphone-stage:before {
  content: "\eee3";
  opacity: 0.2;
}
.ph-duotone.ph-microphone-stage:after {
  content: "\eee4";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-excel-logo:before {
  content: "\eee5";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-excel-logo:after {
  content: "\eee6";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-outlook-logo:before {
  content: "\eee7";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-outlook-logo:after {
  content: "\eee8";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-powerpoint-logo:before {
  content: "\eee9";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-powerpoint-logo:after {
  content: "\eeea";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-teams-logo:before {
  content: "\eeeb";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-teams-logo:after {
  content: "\eeec";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-word-logo:before {
  content: "\eeed";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-word-logo:after {
  content: "\eeee";
  margin-left: -1em;
}
.ph-duotone.ph-minus-circle:before {
  content: "\eeef";
  opacity: 0.2;
}
.ph-duotone.ph-minus-circle:after {
  content: "\eef0";
  margin-left: -1em;
}
.ph-duotone.ph-minus:before {
  content: "\eef1";
  opacity: 0.2;
}
.ph-duotone.ph-minus:after {
  content: "\eef2";
  margin-left: -1em;
}
.ph-duotone.ph-minus-square:before {
  content: "\eef3";
  opacity: 0.2;
}
.ph-duotone.ph-minus-square:after {
  content: "\eef4";
  margin-left: -1em;
}
.ph-duotone.ph-money:before {
  content: "\eef5";
  opacity: 0.2;
}
.ph-duotone.ph-money:after {
  content: "\eef6";
  margin-left: -1em;
}
.ph-duotone.ph-monitor:before {
  content: "\eef7";
  opacity: 0.2;
}
.ph-duotone.ph-monitor:after {
  content: "\eef8";
  margin-left: -1em;
}
.ph-duotone.ph-monitor-play:before {
  content: "\eef9";
  opacity: 0.2;
}
.ph-duotone.ph-monitor-play:after {
  content: "\eefa";
  margin-left: -1em;
}
.ph-duotone.ph-moon:before {
  content: "\eefb";
  opacity: 0.2;
}
.ph-duotone.ph-moon:after {
  content: "\eefc";
  margin-left: -1em;
}
.ph-duotone.ph-moon-stars:before {
  content: "\eefd";
  opacity: 0.2;
}
.ph-duotone.ph-moon-stars:after {
  content: "\eefe";
  margin-left: -1em;
}
.ph-duotone.ph-moped:before {
  content: "\eeff";
  opacity: 0.2;
}
.ph-duotone.ph-moped:after {
  content: "\ef00";
  margin-left: -1em;
}
.ph-duotone.ph-moped-front:before {
  content: "\ef01";
  opacity: 0.2;
}
.ph-duotone.ph-moped-front:after {
  content: "\ef02";
  margin-left: -1em;
}
.ph-duotone.ph-mosque:before {
  content: "\ef03";
  opacity: 0.2;
}
.ph-duotone.ph-mosque:after {
  content: "\ef04";
  margin-left: -1em;
}
.ph-duotone.ph-motorcycle:before {
  content: "\ef05";
  opacity: 0.2;
}
.ph-duotone.ph-motorcycle:after {
  content: "\ef06";
  margin-left: -1em;
}
.ph-duotone.ph-mountains:before {
  content: "\ef07";
  opacity: 0.2;
}
.ph-duotone.ph-mountains:after {
  content: "\ef08";
  margin-left: -1em;
}
.ph-duotone.ph-mouse:before {
  content: "\ef09";
  opacity: 0.2;
}
.ph-duotone.ph-mouse:after {
  content: "\ef0a";
  margin-left: -1em;
}
.ph-duotone.ph-mouse-simple:before {
  content: "\ef0b";
  opacity: 0.2;
}
.ph-duotone.ph-mouse-simple:after {
  content: "\ef0c";
  margin-left: -1em;
}
.ph-duotone.ph-music-note:before {
  content: "\ef0d";
  opacity: 0.2;
}
.ph-duotone.ph-music-note:after {
  content: "\ef0e";
  margin-left: -1em;
}
.ph-duotone.ph-music-notes:before {
  content: "\ef0f";
  opacity: 0.2;
}
.ph-duotone.ph-music-notes:after {
  content: "\ef10";
  margin-left: -1em;
}
.ph-duotone.ph-music-note-simple:before {
  content: "\ef11";
  opacity: 0.2;
}
.ph-duotone.ph-music-note-simple:after {
  content: "\ef12";
  margin-left: -1em;
}
.ph-duotone.ph-music-notes-plus:before {
  content: "\ef13";
  opacity: 0.2;
}
.ph-duotone.ph-music-notes-plus:after {
  content: "\ef14";
  margin-left: -1em;
}
.ph-duotone.ph-music-notes-simple:before {
  content: "\ef15";
  opacity: 0.2;
}
.ph-duotone.ph-music-notes-simple:after {
  content: "\ef16";
  margin-left: -1em;
}
.ph-duotone.ph-navigation-arrow:before {
  content: "\ef17";
  opacity: 0.2;
}
.ph-duotone.ph-navigation-arrow:after {
  content: "\ef18";
  margin-left: -1em;
}
.ph-duotone.ph-needle:before {
  content: "\ef19";
  opacity: 0.2;
}
.ph-duotone.ph-needle:after {
  content: "\ef1a";
  margin-left: -1em;
}
.ph-duotone.ph-newspaper-clipping:before {
  content: "\ef1b";
  opacity: 0.2;
}
.ph-duotone.ph-newspaper-clipping:after {
  content: "\ef1c";
  margin-left: -1em;
}
.ph-duotone.ph-newspaper:before {
  content: "\ef1d";
  opacity: 0.2;
}
.ph-duotone.ph-newspaper:after {
  content: "\ef1e";
  margin-left: -1em;
}
.ph-duotone.ph-notches:before {
  content: "\ef1f";
  opacity: 0.2;
}
.ph-duotone.ph-notches:after {
  content: "\ef20";
  margin-left: -1em;
}
.ph-duotone.ph-note-blank:before {
  content: "\ef21";
  opacity: 0.2;
}
.ph-duotone.ph-note-blank:after {
  content: "\ef22";
  margin-left: -1em;
}
.ph-duotone.ph-notebook:before {
  content: "\ef23";
  opacity: 0.2;
}
.ph-duotone.ph-notebook:after {
  content: "\ef24";
  margin-left: -1em;
}
.ph-duotone.ph-note:before {
  content: "\ef25";
  opacity: 0.2;
}
.ph-duotone.ph-note:after {
  content: "\ef26";
  margin-left: -1em;
}
.ph-duotone.ph-notepad:before {
  content: "\ef27";
  opacity: 0.2;
}
.ph-duotone.ph-notepad:after {
  content: "\ef28";
  margin-left: -1em;
}
.ph-duotone.ph-note-pencil:before {
  content: "\ef29";
  opacity: 0.2;
}
.ph-duotone.ph-note-pencil:after {
  content: "\ef2a";
  margin-left: -1em;
}
.ph-duotone.ph-notification:before {
  content: "\ef2b";
  opacity: 0.2;
}
.ph-duotone.ph-notification:after {
  content: "\ef2c";
  margin-left: -1em;
}
.ph-duotone.ph-notion-logo:before {
  content: "\ef2d";
  opacity: 0.2;
}
.ph-duotone.ph-notion-logo:after {
  content: "\ef2e";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-eight:before {
  content: "\ef2f";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-eight:after {
  content: "\ef30";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-five:before {
  content: "\ef31";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-five:after {
  content: "\ef32";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-four:before {
  content: "\ef33";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-four:after {
  content: "\ef34";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-nine:before {
  content: "\ef35";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-nine:after {
  content: "\ef36";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-one:before {
  content: "\ef37";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-one:after {
  content: "\ef38";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-seven:before {
  content: "\ef39";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-seven:after {
  content: "\ef3a";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-six:before {
  content: "\ef3b";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-six:after {
  content: "\ef3c";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-three:before {
  content: "\ef3d";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-three:after {
  content: "\ef3e";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-two:before {
  content: "\ef3f";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-two:after {
  content: "\ef40";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-zero:before {
  content: "\ef41";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-zero:after {
  content: "\ef42";
  margin-left: -1em;
}
.ph-duotone.ph-number-eight:before {
  content: "\ef43";
  opacity: 0.2;
}
.ph-duotone.ph-number-eight:after {
  content: "\ef44";
  margin-left: -1em;
}
.ph-duotone.ph-number-five:before {
  content: "\ef45";
  opacity: 0.2;
}
.ph-duotone.ph-number-five:after {
  content: "\ef46";
  margin-left: -1em;
}
.ph-duotone.ph-number-four:before {
  content: "\ef47";
  opacity: 0.2;
}
.ph-duotone.ph-number-four:after {
  content: "\ef48";
  margin-left: -1em;
}
.ph-duotone.ph-number-nine:before {
  content: "\ef49";
  opacity: 0.2;
}
.ph-duotone.ph-number-nine:after {
  content: "\ef4a";
  margin-left: -1em;
}
.ph-duotone.ph-number-one:before {
  content: "\ef4b";
  opacity: 0.2;
}
.ph-duotone.ph-number-one:after {
  content: "\ef4c";
  margin-left: -1em;
}
.ph-duotone.ph-number-seven:before {
  content: "\ef4d";
  opacity: 0.2;
}
.ph-duotone.ph-number-seven:after {
  content: "\ef4e";
  margin-left: -1em;
}
.ph-duotone.ph-number-six:before {
  content: "\ef4f";
  opacity: 0.2;
}
.ph-duotone.ph-number-six:after {
  content: "\ef50";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-eight:before {
  content: "\ef51";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-eight:after {
  content: "\ef52";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-five:before {
  content: "\ef53";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-five:after {
  content: "\ef54";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-four:before {
  content: "\ef55";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-four:after {
  content: "\ef56";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-nine:before {
  content: "\ef57";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-nine:after {
  content: "\ef58";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-one:before {
  content: "\ef59";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-one:after {
  content: "\ef5a";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-seven:before {
  content: "\ef5b";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-seven:after {
  content: "\ef5c";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-six:before {
  content: "\ef5d";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-six:after {
  content: "\ef5e";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-three:before {
  content: "\ef5f";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-three:after {
  content: "\ef60";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-two:before {
  content: "\ef61";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-two:after {
  content: "\ef62";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-zero:before {
  content: "\ef63";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-zero:after {
  content: "\ef64";
  margin-left: -1em;
}
.ph-duotone.ph-number-three:before {
  content: "\ef65";
  opacity: 0.2;
}
.ph-duotone.ph-number-three:after {
  content: "\ef66";
  margin-left: -1em;
}
.ph-duotone.ph-number-two:before {
  content: "\ef67";
  opacity: 0.2;
}
.ph-duotone.ph-number-two:after {
  content: "\ef68";
  margin-left: -1em;
}
.ph-duotone.ph-number-zero:before {
  content: "\ef69";
  opacity: 0.2;
}
.ph-duotone.ph-number-zero:after {
  content: "\ef6a";
  margin-left: -1em;
}
.ph-duotone.ph-nut:before {
  content: "\ef6b";
  opacity: 0.2;
}
.ph-duotone.ph-nut:after {
  content: "\ef6c";
  margin-left: -1em;
}
.ph-duotone.ph-ny-times-logo:before {
  content: "\ef6d";
  opacity: 0.2;
}
.ph-duotone.ph-ny-times-logo:after {
  content: "\ef6e";
  margin-left: -1em;
}
.ph-duotone.ph-octagon:before {
  content: "\ef6f";
  opacity: 0.2;
}
.ph-duotone.ph-octagon:after {
  content: "\ef70";
  margin-left: -1em;
}
.ph-duotone.ph-office-chair:before {
  content: "\ef71";
  opacity: 0.2;
}
.ph-duotone.ph-office-chair:after {
  content: "\ef72";
  margin-left: -1em;
}
.ph-duotone.ph-option:before {
  content: "\ef73";
  opacity: 0.2;
}
.ph-duotone.ph-option:after {
  content: "\ef74";
  margin-left: -1em;
}
.ph-duotone.ph-orange-slice:before {
  content: "\ef75";
  opacity: 0.2;
}
.ph-duotone.ph-orange-slice:after {
  content: "\ef76";
  margin-left: -1em;
}
.ph-duotone.ph-package:before {
  content: "\ef77";
  opacity: 0.2;
}
.ph-duotone.ph-package:after {
  content: "\ef78";
  margin-left: -1em;
}
.ph-duotone.ph-paint-brush-broad:before {
  content: "\ef79";
  opacity: 0.2;
}
.ph-duotone.ph-paint-brush-broad:after {
  content: "\ef7a";
  margin-left: -1em;
}
.ph-duotone.ph-paint-brush:before {
  content: "\ef7b";
  opacity: 0.2;
}
.ph-duotone.ph-paint-brush:after {
  content: "\ef7c";
  margin-left: -1em;
}
.ph-duotone.ph-paint-brush-household:before {
  content: "\ef7d";
  opacity: 0.2;
}
.ph-duotone.ph-paint-brush-household:after {
  content: "\ef7e";
  margin-left: -1em;
}
.ph-duotone.ph-paint-bucket:before {
  content: "\ef7f";
  opacity: 0.2;
}
.ph-duotone.ph-paint-bucket:after {
  content: "\ef80";
  margin-left: -1em;
}
.ph-duotone.ph-paint-roller:before {
  content: "\ef81";
  opacity: 0.2;
}
.ph-duotone.ph-paint-roller:after {
  content: "\ef82";
  margin-left: -1em;
}
.ph-duotone.ph-palette:before {
  content: "\ef83";
  opacity: 0.2;
}
.ph-duotone.ph-palette:after {
  content: "\ef84";
  margin-left: -1em;
}
.ph-duotone.ph-pants:before {
  content: "\ef85";
  opacity: 0.2;
}
.ph-duotone.ph-pants:after {
  content: "\ef86";
  margin-left: -1em;
}
.ph-duotone.ph-paperclip:before {
  content: "\ef87";
  opacity: 0.2;
}
.ph-duotone.ph-paperclip:after {
  content: "\ef88";
  margin-left: -1em;
}
.ph-duotone.ph-paperclip-horizontal:before {
  content: "\ef89";
  opacity: 0.2;
}
.ph-duotone.ph-paperclip-horizontal:after {
  content: "\ef8a";
  margin-left: -1em;
}
.ph-duotone.ph-paper-plane:before {
  content: "\ef8b";
  opacity: 0.2;
}
.ph-duotone.ph-paper-plane:after {
  content: "\ef8c";
  margin-left: -1em;
}
.ph-duotone.ph-paper-plane-right:before {
  content: "\ef8d";
  opacity: 0.2;
}
.ph-duotone.ph-paper-plane-right:after {
  content: "\ef8e";
  margin-left: -1em;
}
.ph-duotone.ph-paper-plane-tilt:before {
  content: "\ef8f";
  opacity: 0.2;
}
.ph-duotone.ph-paper-plane-tilt:after {
  content: "\ef90";
  margin-left: -1em;
}
.ph-duotone.ph-parachute:before {
  content: "\ef91";
  opacity: 0.2;
}
.ph-duotone.ph-parachute:after {
  content: "\ef92";
  margin-left: -1em;
}
.ph-duotone.ph-paragraph:before {
  content: "\ef93";
  opacity: 0.2;
}
.ph-duotone.ph-paragraph:after {
  content: "\ef94";
  margin-left: -1em;
}
.ph-duotone.ph-parallelogram:before {
  content: "\ef95";
  opacity: 0.2;
}
.ph-duotone.ph-parallelogram:after {
  content: "\ef96";
  margin-left: -1em;
}
.ph-duotone.ph-park:before {
  content: "\ef97";
  opacity: 0.2;
}
.ph-duotone.ph-park:after {
  content: "\ef98";
  margin-left: -1em;
}
.ph-duotone.ph-password:before {
  content: "\ef99";
  opacity: 0.2;
}
.ph-duotone.ph-password:after {
  content: "\ef9a";
  margin-left: -1em;
}
.ph-duotone.ph-path:before {
  content: "\ef9b";
  opacity: 0.2;
}
.ph-duotone.ph-path:after {
  content: "\ef9c";
  margin-left: -1em;
}
.ph-duotone.ph-patreon-logo:before {
  content: "\ef9d";
  opacity: 0.2;
}
.ph-duotone.ph-patreon-logo:after {
  content: "\ef9e";
  margin-left: -1em;
}
.ph-duotone.ph-pause-circle:before {
  content: "\ef9f";
  opacity: 0.2;
}
.ph-duotone.ph-pause-circle:after {
  content: "\efa0";
  margin-left: -1em;
}
.ph-duotone.ph-pause:before {
  content: "\efa1";
  opacity: 0.2;
}
.ph-duotone.ph-pause:after {
  content: "\efa2";
  margin-left: -1em;
}
.ph-duotone.ph-paw-print:before {
  content: "\efa3";
  opacity: 0.2;
}
.ph-duotone.ph-paw-print:after {
  content: "\efa4";
  margin-left: -1em;
}
.ph-duotone.ph-paypal-logo:before {
  content: "\efa5";
  opacity: 0.2;
}
.ph-duotone.ph-paypal-logo:after {
  content: "\efa6";
  margin-left: -1em;
}
.ph-duotone.ph-peace:before {
  content: "\efa7";
  opacity: 0.2;
}
.ph-duotone.ph-peace:after {
  content: "\efa8";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-circle:before {
  content: "\efa9";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-circle:after {
  content: "\efaa";
  margin-left: -1em;
}
.ph-duotone.ph-pencil:before {
  content: "\efab";
  opacity: 0.2;
}
.ph-duotone.ph-pencil:after {
  content: "\efac";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-line:before {
  content: "\efad";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-line:after {
  content: "\efae";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-simple:before {
  content: "\efaf";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-simple:after {
  content: "\efb0";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-simple-line:before {
  content: "\efb1";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-simple-line:after {
  content: "\efb2";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-simple-slash:before {
  content: "\efb3";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-simple-slash:after {
  content: "\efb4";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-slash:before {
  content: "\efb5";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-slash:after {
  content: "\efb6";
  margin-left: -1em;
}
.ph-duotone.ph-pen:before {
  content: "\efb7";
  opacity: 0.2;
}
.ph-duotone.ph-pen:after {
  content: "\efb8";
  margin-left: -1em;
}
.ph-duotone.ph-pen-nib:before {
  content: "\efb9";
  opacity: 0.2;
}
.ph-duotone.ph-pen-nib:after {
  content: "\efba";
  margin-left: -1em;
}
.ph-duotone.ph-pen-nib-straight:before {
  content: "\efbb";
  opacity: 0.2;
}
.ph-duotone.ph-pen-nib-straight:after {
  content: "\efbc";
  margin-left: -1em;
}
.ph-duotone.ph-pentagram:before {
  content: "\efbd";
  opacity: 0.2;
}
.ph-duotone.ph-pentagram:after {
  content: "\efbe";
  margin-left: -1em;
}
.ph-duotone.ph-pepper:before {
  content: "\efbf";
  opacity: 0.2;
}
.ph-duotone.ph-pepper:after {
  content: "\efc0";
  margin-left: -1em;
}
.ph-duotone.ph-percent:before {
  content: "\efc1";
  opacity: 0.2;
}
.ph-duotone.ph-percent:after {
  content: "\efc2";
  margin-left: -1em;
}
.ph-duotone.ph-person-arms-spread:before {
  content: "\efc3";
  opacity: 0.2;
}
.ph-duotone.ph-person-arms-spread:after {
  content: "\efc4";
  margin-left: -1em;
}
.ph-duotone.ph-person:before {
  content: "\efc5";
  opacity: 0.2;
}
.ph-duotone.ph-person:after {
  content: "\efc6";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-bike:before {
  content: "\efc7";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-bike:after {
  content: "\efc8";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple:before {
  content: "\efc9";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple:after {
  content: "\efca";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-run:before {
  content: "\efcb";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-run:after {
  content: "\efcc";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-throw:before {
  content: "\efcd";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-throw:after {
  content: "\efce";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-walk:before {
  content: "\efcf";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-walk:after {
  content: "\efd0";
  margin-left: -1em;
}
.ph-duotone.ph-perspective:before {
  content: "\efd1";
  opacity: 0.2;
}
.ph-duotone.ph-perspective:after {
  content: "\efd2";
  margin-left: -1em;
}
.ph-duotone.ph-phone-call:before {
  content: "\efd3";
  opacity: 0.2;
}
.ph-duotone.ph-phone-call:after {
  content: "\efd4";
  margin-left: -1em;
}
.ph-duotone.ph-phone-disconnect:before {
  content: "\efd5";
  opacity: 0.2;
}
.ph-duotone.ph-phone-disconnect:after {
  content: "\efd6";
  margin-left: -1em;
}
.ph-duotone.ph-phone:before {
  content: "\efd7";
  opacity: 0.2;
}
.ph-duotone.ph-phone:after {
  content: "\efd8";
  margin-left: -1em;
}
.ph-duotone.ph-phone-incoming:before {
  content: "\efd9";
  opacity: 0.2;
}
.ph-duotone.ph-phone-incoming:after {
  content: "\efda";
  margin-left: -1em;
}
.ph-duotone.ph-phone-outgoing:before {
  content: "\efdb";
  opacity: 0.2;
}
.ph-duotone.ph-phone-outgoing:after {
  content: "\efdc";
  margin-left: -1em;
}
.ph-duotone.ph-phone-plus:before {
  content: "\efdd";
  opacity: 0.2;
}
.ph-duotone.ph-phone-plus:after {
  content: "\efde";
  margin-left: -1em;
}
.ph-duotone.ph-phone-slash:before {
  content: "\efdf";
  opacity: 0.2;
}
.ph-duotone.ph-phone-slash:after {
  content: "\efe0";
  margin-left: -1em;
}
.ph-duotone.ph-phone-x:before {
  content: "\efe1";
  opacity: 0.2;
}
.ph-duotone.ph-phone-x:after {
  content: "\efe2";
  margin-left: -1em;
}
.ph-duotone.ph-phosphor-logo:before {
  content: "\efe3";
  opacity: 0.2;
}
.ph-duotone.ph-phosphor-logo:after {
  content: "\efe4";
  margin-left: -1em;
}
.ph-duotone.ph-piano-keys:before {
  content: "\efe5";
  opacity: 0.2;
}
.ph-duotone.ph-piano-keys:after {
  content: "\efe6";
  margin-left: -1em;
}
.ph-duotone.ph-picture-in-picture:before {
  content: "\efe7";
  opacity: 0.2;
}
.ph-duotone.ph-picture-in-picture:after {
  content: "\efe8";
  margin-left: -1em;
}
.ph-duotone.ph-pi:before {
  content: "\efe9";
  opacity: 0.2;
}
.ph-duotone.ph-pi:after {
  content: "\efea";
  margin-left: -1em;
}
.ph-duotone.ph-piggy-bank:before {
  content: "\efeb";
  opacity: 0.2;
}
.ph-duotone.ph-piggy-bank:after {
  content: "\efec";
  margin-left: -1em;
}
.ph-duotone.ph-pill:before {
  content: "\efed";
  opacity: 0.2;
}
.ph-duotone.ph-pill:after {
  content: "\efee";
  margin-left: -1em;
}
.ph-duotone.ph-pinterest-logo:before {
  content: "\efef";
  opacity: 0.2;
}
.ph-duotone.ph-pinterest-logo:after {
  content: "\eff0";
  margin-left: -1em;
}
.ph-duotone.ph-pinwheel:before {
  content: "\eff1";
  opacity: 0.2;
}
.ph-duotone.ph-pinwheel:after {
  content: "\eff2";
  margin-left: -1em;
}
.ph-duotone.ph-pizza:before {
  content: "\eff3";
  opacity: 0.2;
}
.ph-duotone.ph-pizza:after {
  content: "\eff4";
  margin-left: -1em;
}
.ph-duotone.ph-placeholder:before {
  content: "\eff5";
  opacity: 0.2;
}
.ph-duotone.ph-placeholder:after {
  content: "\eff6";
  margin-left: -1em;
}
.ph-duotone.ph-planet:before {
  content: "\eff7";
  opacity: 0.2;
}
.ph-duotone.ph-planet:after {
  content: "\eff8";
  margin-left: -1em;
}
.ph-duotone.ph-plant:before {
  content: "\eff9";
  opacity: 0.2;
}
.ph-duotone.ph-plant:after {
  content: "\effa";
  margin-left: -1em;
}
.ph-duotone.ph-play-circle:before {
  content: "\effb";
  opacity: 0.2;
}
.ph-duotone.ph-play-circle:after {
  content: "\effc";
  margin-left: -1em;
}
.ph-duotone.ph-play:before {
  content: "\effd";
  opacity: 0.2;
}
.ph-duotone.ph-play:after {
  content: "\effe";
  margin-left: -1em;
}
.ph-duotone.ph-playlist:before {
  content: "\efff";
  opacity: 0.2;
}
.ph-duotone.ph-playlist:after {
  content: "\f000";
  margin-left: -1em;
}
.ph-duotone.ph-play-pause:before {
  content: "\f001";
  opacity: 0.2;
}
.ph-duotone.ph-play-pause:after {
  content: "\f002";
  margin-left: -1em;
}
.ph-duotone.ph-plug-charging:before {
  content: "\f003";
  opacity: 0.2;
}
.ph-duotone.ph-plug-charging:after {
  content: "\f004";
  margin-left: -1em;
}
.ph-duotone.ph-plug:before {
  content: "\f005";
  opacity: 0.2;
}
.ph-duotone.ph-plug:after {
  content: "\f006";
  margin-left: -1em;
}
.ph-duotone.ph-plugs-connected:before {
  content: "\f007";
  opacity: 0.2;
}
.ph-duotone.ph-plugs-connected:after {
  content: "\f008";
  margin-left: -1em;
}
.ph-duotone.ph-plugs:before {
  content: "\f009";
  opacity: 0.2;
}
.ph-duotone.ph-plugs:after {
  content: "\f00a";
  margin-left: -1em;
}
.ph-duotone.ph-plus-circle:before {
  content: "\f00b";
  opacity: 0.2;
}
.ph-duotone.ph-plus-circle:after {
  content: "\f00c";
  margin-left: -1em;
}
.ph-duotone.ph-plus:before {
  content: "\f00d";
  opacity: 0.2;
}
.ph-duotone.ph-plus:after {
  content: "\f00e";
  margin-left: -1em;
}
.ph-duotone.ph-plus-minus:before {
  content: "\f00f";
  opacity: 0.2;
}
.ph-duotone.ph-plus-minus:after {
  content: "\f010";
  margin-left: -1em;
}
.ph-duotone.ph-plus-square:before {
  content: "\f011";
  opacity: 0.2;
}
.ph-duotone.ph-plus-square:after {
  content: "\f012";
  margin-left: -1em;
}
.ph-duotone.ph-poker-chip:before {
  content: "\f013";
  opacity: 0.2;
}
.ph-duotone.ph-poker-chip:after {
  content: "\f014";
  margin-left: -1em;
}
.ph-duotone.ph-police-car:before {
  content: "\f015";
  opacity: 0.2;
}
.ph-duotone.ph-police-car:after {
  content: "\f016";
  margin-left: -1em;
}
.ph-duotone.ph-polygon:before {
  content: "\f017";
  opacity: 0.2;
}
.ph-duotone.ph-polygon:after {
  content: "\f018";
  margin-left: -1em;
}
.ph-duotone.ph-popcorn:before {
  content: "\f019";
  opacity: 0.2;
}
.ph-duotone.ph-popcorn:after {
  content: "\f01a";
  margin-left: -1em;
}
.ph-duotone.ph-potted-plant:before {
  content: "\f01b";
  opacity: 0.2;
}
.ph-duotone.ph-potted-plant:after {
  content: "\f01c";
  margin-left: -1em;
}
.ph-duotone.ph-power:before {
  content: "\f01d";
  opacity: 0.2;
}
.ph-duotone.ph-power:after {
  content: "\f01e";
  margin-left: -1em;
}
.ph-duotone.ph-prescription:before {
  content: "\f01f";
  opacity: 0.2;
}
.ph-duotone.ph-prescription:after {
  content: "\f020";
  margin-left: -1em;
}
.ph-duotone.ph-presentation-chart:before {
  content: "\f021";
  opacity: 0.2;
}
.ph-duotone.ph-presentation-chart:after {
  content: "\f022";
  margin-left: -1em;
}
.ph-duotone.ph-presentation:before {
  content: "\f023";
  opacity: 0.2;
}
.ph-duotone.ph-presentation:after {
  content: "\f024";
  margin-left: -1em;
}
.ph-duotone.ph-printer:before {
  content: "\f025";
  opacity: 0.2;
}
.ph-duotone.ph-printer:after {
  content: "\f026";
  margin-left: -1em;
}
.ph-duotone.ph-prohibit:before {
  content: "\f027";
  opacity: 0.2;
}
.ph-duotone.ph-prohibit:after {
  content: "\f028";
  margin-left: -1em;
}
.ph-duotone.ph-prohibit-inset:before {
  content: "\f029";
  opacity: 0.2;
}
.ph-duotone.ph-prohibit-inset:after {
  content: "\f02a";
  margin-left: -1em;
}
.ph-duotone.ph-projector-screen-chart:before {
  content: "\f02b";
  opacity: 0.2;
}
.ph-duotone.ph-projector-screen-chart:after {
  content: "\f02c";
  margin-left: -1em;
}
.ph-duotone.ph-projector-screen:before {
  content: "\f02d";
  opacity: 0.2;
}
.ph-duotone.ph-projector-screen:after {
  content: "\f02e";
  margin-left: -1em;
}
.ph-duotone.ph-pulse:before, .ph-duotone.ph-activity:before {
  content: "\f02f";
  opacity: 0.2;
}
.ph-duotone.ph-pulse:after, .ph-duotone.ph-activity:after {
  content: "\f030";
  margin-left: -1em;
}
.ph-duotone.ph-push-pin:before {
  content: "\f031";
  opacity: 0.2;
}
.ph-duotone.ph-push-pin:after {
  content: "\f032";
  margin-left: -1em;
}
.ph-duotone.ph-push-pin-simple:before {
  content: "\f033";
  opacity: 0.2;
}
.ph-duotone.ph-push-pin-simple:after {
  content: "\f034";
  margin-left: -1em;
}
.ph-duotone.ph-push-pin-simple-slash:before {
  content: "\f035";
  opacity: 0.2;
}
.ph-duotone.ph-push-pin-simple-slash:after {
  content: "\f036";
  margin-left: -1em;
}
.ph-duotone.ph-push-pin-slash:before {
  content: "\f037";
  opacity: 0.2;
}
.ph-duotone.ph-push-pin-slash:after {
  content: "\f038";
  margin-left: -1em;
}
.ph-duotone.ph-puzzle-piece:before {
  content: "\f039";
  opacity: 0.2;
}
.ph-duotone.ph-puzzle-piece:after {
  content: "\f03a";
  margin-left: -1em;
}
.ph-duotone.ph-qr-code:before {
  content: "\f03b";
  opacity: 0.2;
}
.ph-duotone.ph-qr-code:after {
  content: "\f03c";
  margin-left: -1em;
}
.ph-duotone.ph-question:before {
  content: "\f03d";
  opacity: 0.2;
}
.ph-duotone.ph-question:after {
  content: "\f03e";
  margin-left: -1em;
}
.ph-duotone.ph-queue:before {
  content: "\f03f";
  opacity: 0.2;
}
.ph-duotone.ph-queue:after {
  content: "\f040";
  margin-left: -1em;
}
.ph-duotone.ph-quotes:before {
  content: "\f041";
  opacity: 0.2;
}
.ph-duotone.ph-quotes:after {
  content: "\f042";
  margin-left: -1em;
}
.ph-duotone.ph-radical:before {
  content: "\f043";
  opacity: 0.2;
}
.ph-duotone.ph-radical:after {
  content: "\f044";
  margin-left: -1em;
}
.ph-duotone.ph-radioactive:before {
  content: "\f045";
  opacity: 0.2;
}
.ph-duotone.ph-radioactive:after {
  content: "\f046";
  margin-left: -1em;
}
.ph-duotone.ph-radio-button:before {
  content: "\f047";
  opacity: 0.2;
}
.ph-duotone.ph-radio-button:after {
  content: "\f048";
  margin-left: -1em;
}
.ph-duotone.ph-radio:before {
  content: "\f049";
  opacity: 0.2;
}
.ph-duotone.ph-radio:after {
  content: "\f04a";
  margin-left: -1em;
}
.ph-duotone.ph-rainbow-cloud:before {
  content: "\f04b";
  opacity: 0.2;
}
.ph-duotone.ph-rainbow-cloud:after {
  content: "\f04c";
  margin-left: -1em;
}
.ph-duotone.ph-rainbow:before {
  content: "\f04d";
  opacity: 0.2;
}
.ph-duotone.ph-rainbow:after {
  content: "\f04e";
  margin-left: -1em;
}
.ph-duotone.ph-read-cv-logo:before {
  content: "\f04f";
  opacity: 0.2;
}
.ph-duotone.ph-read-cv-logo:after {
  content: "\f050";
  margin-left: -1em;
}
.ph-duotone.ph-receipt:before {
  content: "\f051";
  opacity: 0.2;
}
.ph-duotone.ph-receipt:after {
  content: "\f052";
  margin-left: -1em;
}
.ph-duotone.ph-receipt-x:before {
  content: "\f053";
  opacity: 0.2;
}
.ph-duotone.ph-receipt-x:after {
  content: "\f054";
  margin-left: -1em;
}
.ph-duotone.ph-record:before {
  content: "\f055";
  opacity: 0.2;
}
.ph-duotone.ph-record:after {
  content: "\f056";
  margin-left: -1em;
}
.ph-duotone.ph-rectangle:before {
  content: "\f057";
  opacity: 0.2;
}
.ph-duotone.ph-rectangle:after {
  content: "\f058";
  margin-left: -1em;
}
.ph-duotone.ph-recycle:before {
  content: "\f059";
  opacity: 0.2;
}
.ph-duotone.ph-recycle:after {
  content: "\f05a";
  margin-left: -1em;
}
.ph-duotone.ph-reddit-logo:before {
  content: "\f05b";
  opacity: 0.2;
}
.ph-duotone.ph-reddit-logo:after {
  content: "\f05c";
  margin-left: -1em;
}
.ph-duotone.ph-repeat:before {
  content: "\f05d";
  opacity: 0.2;
}
.ph-duotone.ph-repeat:after {
  content: "\f05e";
  margin-left: -1em;
}
.ph-duotone.ph-repeat-once:before {
  content: "\f05f";
  opacity: 0.2;
}
.ph-duotone.ph-repeat-once:after {
  content: "\f060";
  margin-left: -1em;
}
.ph-duotone.ph-rewind-circle:before {
  content: "\f061";
  opacity: 0.2;
}
.ph-duotone.ph-rewind-circle:after {
  content: "\f062";
  margin-left: -1em;
}
.ph-duotone.ph-rewind:before {
  content: "\f063";
  opacity: 0.2;
}
.ph-duotone.ph-rewind:after {
  content: "\f064";
  margin-left: -1em;
}
.ph-duotone.ph-road-horizon:before {
  content: "\f065";
  opacity: 0.2;
}
.ph-duotone.ph-road-horizon:after {
  content: "\f066";
  margin-left: -1em;
}
.ph-duotone.ph-robot:before {
  content: "\f067";
  opacity: 0.2;
}
.ph-duotone.ph-robot:after {
  content: "\f068";
  margin-left: -1em;
}
.ph-duotone.ph-rocket:before {
  content: "\f069";
  opacity: 0.2;
}
.ph-duotone.ph-rocket:after {
  content: "\f06a";
  margin-left: -1em;
}
.ph-duotone.ph-rocket-launch:before {
  content: "\f06b";
  opacity: 0.2;
}
.ph-duotone.ph-rocket-launch:after {
  content: "\f06c";
  margin-left: -1em;
}
.ph-duotone.ph-rows:before {
  content: "\f06d";
  opacity: 0.2;
}
.ph-duotone.ph-rows:after {
  content: "\f06e";
  margin-left: -1em;
}
.ph-duotone.ph-rss:before {
  content: "\f06f";
  opacity: 0.2;
}
.ph-duotone.ph-rss:after {
  content: "\f070";
  margin-left: -1em;
}
.ph-duotone.ph-rss-simple:before {
  content: "\f071";
  opacity: 0.2;
}
.ph-duotone.ph-rss-simple:after {
  content: "\f072";
  margin-left: -1em;
}
.ph-duotone.ph-rug:before {
  content: "\f073";
  opacity: 0.2;
}
.ph-duotone.ph-rug:after {
  content: "\f074";
  margin-left: -1em;
}
.ph-duotone.ph-ruler:before {
  content: "\f075";
  opacity: 0.2;
}
.ph-duotone.ph-ruler:after {
  content: "\f076";
  margin-left: -1em;
}
.ph-duotone.ph-scales:before {
  content: "\f077";
  opacity: 0.2;
}
.ph-duotone.ph-scales:after {
  content: "\f078";
  margin-left: -1em;
}
.ph-duotone.ph-scan:before {
  content: "\f079";
  opacity: 0.2;
}
.ph-duotone.ph-scan:after {
  content: "\f07a";
  margin-left: -1em;
}
.ph-duotone.ph-scissors:before {
  content: "\f07b";
  opacity: 0.2;
}
.ph-duotone.ph-scissors:after {
  content: "\f07c";
  margin-left: -1em;
}
.ph-duotone.ph-scooter:before {
  content: "\f07d";
  opacity: 0.2;
}
.ph-duotone.ph-scooter:after {
  content: "\f07e";
  margin-left: -1em;
}
.ph-duotone.ph-screencast:before {
  content: "\f07f";
  opacity: 0.2;
}
.ph-duotone.ph-screencast:after {
  content: "\f080";
  margin-left: -1em;
}
.ph-duotone.ph-scribble-loop:before {
  content: "\f081";
  opacity: 0.2;
}
.ph-duotone.ph-scribble-loop:after {
  content: "\f082";
  margin-left: -1em;
}
.ph-duotone.ph-scroll:before {
  content: "\f083";
  opacity: 0.2;
}
.ph-duotone.ph-scroll:after {
  content: "\f084";
  margin-left: -1em;
}
.ph-duotone.ph-seal-check:before, .ph-duotone.ph-circle-wavy-check:before {
  content: "\f085";
  opacity: 0.2;
}
.ph-duotone.ph-seal-check:after, .ph-duotone.ph-circle-wavy-check:after {
  content: "\f086";
  margin-left: -1em;
}
.ph-duotone.ph-seal:before, .ph-duotone.ph-circle-wavy:before {
  content: "\f087";
  opacity: 0.2;
}
.ph-duotone.ph-seal:after, .ph-duotone.ph-circle-wavy:after {
  content: "\f088";
  margin-left: -1em;
}
.ph-duotone.ph-seal-question:before, .ph-duotone.ph-circle-wavy-question:before {
  content: "\f089";
  opacity: 0.2;
}
.ph-duotone.ph-seal-question:after, .ph-duotone.ph-circle-wavy-question:after {
  content: "\f08a";
  margin-left: -1em;
}
.ph-duotone.ph-seal-warning:before, .ph-duotone.ph-circle-wavy-warning:before {
  content: "\f08b";
  opacity: 0.2;
}
.ph-duotone.ph-seal-warning:after, .ph-duotone.ph-circle-wavy-warning:after {
  content: "\f08c";
  margin-left: -1em;
}
.ph-duotone.ph-selection-all:before {
  content: "\f08d";
  opacity: 0.2;
}
.ph-duotone.ph-selection-all:after {
  content: "\f08e";
  margin-left: -1em;
}
.ph-duotone.ph-selection-background:before {
  content: "\f08f";
  opacity: 0.2;
}
.ph-duotone.ph-selection-background:after {
  content: "\f090";
  margin-left: -1em;
}
.ph-duotone.ph-selection:before {
  content: "\f091";
  opacity: 0.2;
}
.ph-duotone.ph-selection:after {
  content: "\f092";
  margin-left: -1em;
}
.ph-duotone.ph-selection-foreground:before {
  content: "\f093";
  opacity: 0.2;
}
.ph-duotone.ph-selection-foreground:after {
  content: "\f094";
  margin-left: -1em;
}
.ph-duotone.ph-selection-inverse:before {
  content: "\f095";
  opacity: 0.2;
}
.ph-duotone.ph-selection-inverse:after {
  content: "\f096";
  margin-left: -1em;
}
.ph-duotone.ph-selection-plus:before {
  content: "\f097";
  opacity: 0.2;
}
.ph-duotone.ph-selection-plus:after {
  content: "\f098";
  margin-left: -1em;
}
.ph-duotone.ph-selection-slash:before {
  content: "\f099";
  opacity: 0.2;
}
.ph-duotone.ph-selection-slash:after {
  content: "\f09a";
  margin-left: -1em;
}
.ph-duotone.ph-shapes:before {
  content: "\f09b";
  opacity: 0.2;
}
.ph-duotone.ph-shapes:after {
  content: "\f09c";
  margin-left: -1em;
}
.ph-duotone.ph-share:before {
  content: "\f09d";
  opacity: 0.2;
}
.ph-duotone.ph-share:after {
  content: "\f09e";
  margin-left: -1em;
}
.ph-duotone.ph-share-fat:before {
  content: "\f09f";
  opacity: 0.2;
}
.ph-duotone.ph-share-fat:after {
  content: "\f0a0";
  margin-left: -1em;
}
.ph-duotone.ph-share-network:before {
  content: "\f0a1";
  opacity: 0.2;
}
.ph-duotone.ph-share-network:after {
  content: "\f0a2";
  margin-left: -1em;
}
.ph-duotone.ph-shield-check:before {
  content: "\f0a3";
  opacity: 0.2;
}
.ph-duotone.ph-shield-check:after {
  content: "\f0a4";
  margin-left: -1em;
}
.ph-duotone.ph-shield-checkered:before {
  content: "\f0a5";
  opacity: 0.2;
}
.ph-duotone.ph-shield-checkered:after {
  content: "\f0a6";
  margin-left: -1em;
}
.ph-duotone.ph-shield-chevron:before {
  content: "\f0a7";
  opacity: 0.2;
}
.ph-duotone.ph-shield-chevron:after {
  content: "\f0a8";
  margin-left: -1em;
}
.ph-duotone.ph-shield:before {
  content: "\f0a9";
  opacity: 0.2;
}
.ph-duotone.ph-shield:after {
  content: "\f0aa";
  margin-left: -1em;
}
.ph-duotone.ph-shield-plus:before {
  content: "\f0ab";
  opacity: 0.2;
}
.ph-duotone.ph-shield-plus:after {
  content: "\f0ac";
  margin-left: -1em;
}
.ph-duotone.ph-shield-slash:before {
  content: "\f0ad";
  opacity: 0.2;
}
.ph-duotone.ph-shield-slash:after {
  content: "\f0ae";
  margin-left: -1em;
}
.ph-duotone.ph-shield-star:before {
  content: "\f0af";
  opacity: 0.2;
}
.ph-duotone.ph-shield-star:after {
  content: "\f0b0";
  margin-left: -1em;
}
.ph-duotone.ph-shield-warning:before {
  content: "\f0b1";
  opacity: 0.2;
}
.ph-duotone.ph-shield-warning:after {
  content: "\f0b2";
  margin-left: -1em;
}
.ph-duotone.ph-shirt-folded:before {
  content: "\f0b3";
  opacity: 0.2;
}
.ph-duotone.ph-shirt-folded:after {
  content: "\f0b4";
  margin-left: -1em;
}
.ph-duotone.ph-shooting-star:before {
  content: "\f0b5";
  opacity: 0.2;
}
.ph-duotone.ph-shooting-star:after {
  content: "\f0b6";
  margin-left: -1em;
}
.ph-duotone.ph-shopping-bag:before {
  content: "\f0b7";
  opacity: 0.2;
}
.ph-duotone.ph-shopping-bag:after {
  content: "\f0b8";
  margin-left: -1em;
}
.ph-duotone.ph-shopping-bag-open:before {
  content: "\f0b9";
  opacity: 0.2;
}
.ph-duotone.ph-shopping-bag-open:after {
  content: "\f0ba";
  margin-left: -1em;
}
.ph-duotone.ph-shopping-cart:before {
  content: "\f0bb";
  opacity: 0.2;
}
.ph-duotone.ph-shopping-cart:after {
  content: "\f0bc";
  margin-left: -1em;
}
.ph-duotone.ph-shopping-cart-simple:before {
  content: "\f0bd";
  opacity: 0.2;
}
.ph-duotone.ph-shopping-cart-simple:after {
  content: "\f0be";
  margin-left: -1em;
}
.ph-duotone.ph-shower:before {
  content: "\f0bf";
  opacity: 0.2;
}
.ph-duotone.ph-shower:after {
  content: "\f0c0";
  margin-left: -1em;
}
.ph-duotone.ph-shrimp:before {
  content: "\f0c1";
  opacity: 0.2;
}
.ph-duotone.ph-shrimp:after {
  content: "\f0c2";
  margin-left: -1em;
}
.ph-duotone.ph-shuffle-angular:before {
  content: "\f0c3";
  opacity: 0.2;
}
.ph-duotone.ph-shuffle-angular:after {
  content: "\f0c4";
  margin-left: -1em;
}
.ph-duotone.ph-shuffle:before {
  content: "\f0c5";
  opacity: 0.2;
}
.ph-duotone.ph-shuffle:after {
  content: "\f0c6";
  margin-left: -1em;
}
.ph-duotone.ph-shuffle-simple:before {
  content: "\f0c7";
  opacity: 0.2;
}
.ph-duotone.ph-shuffle-simple:after {
  content: "\f0c8";
  margin-left: -1em;
}
.ph-duotone.ph-sidebar:before {
  content: "\f0c9";
  opacity: 0.2;
}
.ph-duotone.ph-sidebar:after {
  content: "\f0ca";
  margin-left: -1em;
}
.ph-duotone.ph-sidebar-simple:before {
  content: "\f0cb";
  opacity: 0.2;
}
.ph-duotone.ph-sidebar-simple:after {
  content: "\f0cc";
  margin-left: -1em;
}
.ph-duotone.ph-sigma:before {
  content: "\f0cd";
  opacity: 0.2;
}
.ph-duotone.ph-sigma:after {
  content: "\f0ce";
  margin-left: -1em;
}
.ph-duotone.ph-signature:before {
  content: "\f0cf";
  opacity: 0.2;
}
.ph-duotone.ph-signature:after {
  content: "\f0d0";
  margin-left: -1em;
}
.ph-duotone.ph-sign-in:before {
  content: "\f0d1";
  opacity: 0.2;
}
.ph-duotone.ph-sign-in:after {
  content: "\f0d2";
  margin-left: -1em;
}
.ph-duotone.ph-sign-out:before {
  content: "\f0d3";
  opacity: 0.2;
}
.ph-duotone.ph-sign-out:after {
  content: "\f0d4";
  margin-left: -1em;
}
.ph-duotone.ph-signpost:before {
  content: "\f0d5";
  opacity: 0.2;
}
.ph-duotone.ph-signpost:after {
  content: "\f0d6";
  margin-left: -1em;
}
.ph-duotone.ph-sim-card:before {
  content: "\f0d7";
  opacity: 0.2;
}
.ph-duotone.ph-sim-card:after {
  content: "\f0d8";
  margin-left: -1em;
}
.ph-duotone.ph-siren:before {
  content: "\f0d9";
  opacity: 0.2;
}
.ph-duotone.ph-siren:after {
  content: "\f0da";
  margin-left: -1em;
}
.ph-duotone.ph-sketch-logo:before {
  content: "\f0db";
  opacity: 0.2;
}
.ph-duotone.ph-sketch-logo:after {
  content: "\f0dc";
  margin-left: -1em;
}
.ph-duotone.ph-skip-back-circle:before {
  content: "\f0dd";
  opacity: 0.2;
}
.ph-duotone.ph-skip-back-circle:after {
  content: "\f0de";
  margin-left: -1em;
}
.ph-duotone.ph-skip-back:before {
  content: "\f0df";
  opacity: 0.2;
}
.ph-duotone.ph-skip-back:after {
  content: "\f0e0";
  margin-left: -1em;
}
.ph-duotone.ph-skip-forward-circle:before {
  content: "\f0e1";
  opacity: 0.2;
}
.ph-duotone.ph-skip-forward-circle:after {
  content: "\f0e2";
  margin-left: -1em;
}
.ph-duotone.ph-skip-forward:before {
  content: "\f0e3";
  opacity: 0.2;
}
.ph-duotone.ph-skip-forward:after {
  content: "\f0e4";
  margin-left: -1em;
}
.ph-duotone.ph-skull:before {
  content: "\f0e5";
  opacity: 0.2;
}
.ph-duotone.ph-skull:after {
  content: "\f0e6";
  margin-left: -1em;
}
.ph-duotone.ph-slack-logo:before {
  content: "\f0e7";
  opacity: 0.2;
}
.ph-duotone.ph-slack-logo:after {
  content: "\f0e8";
  margin-left: -1em;
}
.ph-duotone.ph-sliders:before {
  content: "\f0e9";
  opacity: 0.2;
}
.ph-duotone.ph-sliders:after {
  content: "\f0ea";
  margin-left: -1em;
}
.ph-duotone.ph-sliders-horizontal:before {
  content: "\f0eb";
  opacity: 0.2;
}
.ph-duotone.ph-sliders-horizontal:after {
  content: "\f0ec";
  margin-left: -1em;
}
.ph-duotone.ph-slideshow:before {
  content: "\f0ed";
  opacity: 0.2;
}
.ph-duotone.ph-slideshow:after {
  content: "\f0ee";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-angry:before {
  content: "\f0ef";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-angry:after {
  content: "\f0f0";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-blank:before {
  content: "\f0f1";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-blank:after {
  content: "\f0f2";
  margin-left: -1em;
}
.ph-duotone.ph-smiley:before {
  content: "\f0f3";
  opacity: 0.2;
}
.ph-duotone.ph-smiley:after {
  content: "\f0f4";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-meh:before {
  content: "\f0f5";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-meh:after {
  content: "\f0f6";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-nervous:before {
  content: "\f0f7";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-nervous:after {
  content: "\f0f8";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-sad:before {
  content: "\f0f9";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-sad:after {
  content: "\f0fa";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-sticker:before {
  content: "\f0fb";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-sticker:after {
  content: "\f0fc";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-wink:before {
  content: "\f0fd";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-wink:after {
  content: "\f0fe";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-x-eyes:before {
  content: "\f0ff";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-x-eyes:after {
  content: "\f100";
  margin-left: -1em;
}
.ph-duotone.ph-snapchat-logo:before {
  content: "\f101";
  opacity: 0.2;
}
.ph-duotone.ph-snapchat-logo:after {
  content: "\f102";
  margin-left: -1em;
}
.ph-duotone.ph-sneaker:before {
  content: "\f103";
  opacity: 0.2;
}
.ph-duotone.ph-sneaker:after {
  content: "\f104";
  margin-left: -1em;
}
.ph-duotone.ph-sneaker-move:before {
  content: "\f105";
  opacity: 0.2;
}
.ph-duotone.ph-sneaker-move:after {
  content: "\f106";
  margin-left: -1em;
}
.ph-duotone.ph-snowflake:before {
  content: "\f107";
  opacity: 0.2;
}
.ph-duotone.ph-snowflake:after {
  content: "\f108";
  margin-left: -1em;
}
.ph-duotone.ph-soccer-ball:before {
  content: "\f109";
  opacity: 0.2;
}
.ph-duotone.ph-soccer-ball:after {
  content: "\f10a";
  margin-left: -1em;
}
.ph-duotone.ph-sort-ascending:before {
  content: "\f10b";
  opacity: 0.2;
}
.ph-duotone.ph-sort-ascending:after {
  content: "\f10c";
  margin-left: -1em;
}
.ph-duotone.ph-sort-descending:before {
  content: "\f10d";
  opacity: 0.2;
}
.ph-duotone.ph-sort-descending:after {
  content: "\f10e";
  margin-left: -1em;
}
.ph-duotone.ph-soundcloud-logo:before {
  content: "\f10f";
  opacity: 0.2;
}
.ph-duotone.ph-soundcloud-logo:after {
  content: "\f110";
  margin-left: -1em;
}
.ph-duotone.ph-spade:before {
  content: "\f111";
  opacity: 0.2;
}
.ph-duotone.ph-spade:after {
  content: "\f112";
  margin-left: -1em;
}
.ph-duotone.ph-sparkle:before {
  content: "\f113";
  opacity: 0.2;
}
.ph-duotone.ph-sparkle:after {
  content: "\f114";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-hifi:before {
  content: "\f115";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-hifi:after {
  content: "\f116";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-high:before {
  content: "\f117";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-high:after {
  content: "\f118";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-low:before {
  content: "\f119";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-low:after {
  content: "\f11a";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-none:before {
  content: "\f11b";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-none:after {
  content: "\f11c";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-high:before {
  content: "\f11d";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-high:after {
  content: "\f11e";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-low:before {
  content: "\f11f";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-low:after {
  content: "\f120";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-none:before {
  content: "\f121";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-none:after {
  content: "\f122";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-slash:before {
  content: "\f123";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-slash:after {
  content: "\f124";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-x:before {
  content: "\f125";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-x:after {
  content: "\f126";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-slash:before {
  content: "\f127";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-slash:after {
  content: "\f128";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-x:before {
  content: "\f129";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-x:after {
  content: "\f12a";
  margin-left: -1em;
}
.ph-duotone.ph-spinner:before {
  content: "\f12b";
  opacity: 0.2;
}
.ph-duotone.ph-spinner:after {
  content: "\f12c";
  margin-left: -1em;
}
.ph-duotone.ph-spinner-gap:before {
  content: "\f12d";
  opacity: 0.2;
}
.ph-duotone.ph-spinner-gap:after {
  content: "\f12e";
  margin-left: -1em;
}
.ph-duotone.ph-spiral:before {
  content: "\f12f";
  opacity: 0.2;
}
.ph-duotone.ph-spiral:after {
  content: "\f130";
  margin-left: -1em;
}
.ph-duotone.ph-split-horizontal:before {
  content: "\f131";
  opacity: 0.2;
}
.ph-duotone.ph-split-horizontal:after {
  content: "\f132";
  margin-left: -1em;
}
.ph-duotone.ph-split-vertical:before {
  content: "\f133";
  opacity: 0.2;
}
.ph-duotone.ph-split-vertical:after {
  content: "\f134";
  margin-left: -1em;
}
.ph-duotone.ph-spotify-logo:before {
  content: "\f135";
  opacity: 0.2;
}
.ph-duotone.ph-spotify-logo:after {
  content: "\f136";
  margin-left: -1em;
}
.ph-duotone.ph-square:before {
  content: "\f137";
  opacity: 0.2;
}
.ph-duotone.ph-square:after {
  content: "\f138";
  margin-left: -1em;
}
.ph-duotone.ph-square-half-bottom:before {
  content: "\f139";
  opacity: 0.2;
}
.ph-duotone.ph-square-half-bottom:after {
  content: "\f13a";
  margin-left: -1em;
}
.ph-duotone.ph-square-half:before {
  content: "\f13b";
  opacity: 0.2;
}
.ph-duotone.ph-square-half:after {
  content: "\f13c";
  margin-left: -1em;
}
.ph-duotone.ph-square-logo:before {
  content: "\f13d";
  opacity: 0.2;
}
.ph-duotone.ph-square-logo:after {
  content: "\f13e";
  margin-left: -1em;
}
.ph-duotone.ph-squares-four:before {
  content: "\f13f";
  opacity: 0.2;
}
.ph-duotone.ph-squares-four:after {
  content: "\f140";
  margin-left: -1em;
}
.ph-duotone.ph-square-split-horizontal:before {
  content: "\f141";
  opacity: 0.2;
}
.ph-duotone.ph-square-split-horizontal:after {
  content: "\f142";
  margin-left: -1em;
}
.ph-duotone.ph-square-split-vertical:before {
  content: "\f143";
  opacity: 0.2;
}
.ph-duotone.ph-square-split-vertical:after {
  content: "\f144";
  margin-left: -1em;
}
.ph-duotone.ph-stack:before {
  content: "\f145";
  opacity: 0.2;
}
.ph-duotone.ph-stack:after {
  content: "\f146";
  margin-left: -1em;
}
.ph-duotone.ph-stack-overflow-logo:before {
  content: "\f147";
  opacity: 0.2;
}
.ph-duotone.ph-stack-overflow-logo:after {
  content: "\f148";
  margin-left: -1em;
}
.ph-duotone.ph-stack-simple:before {
  content: "\f149";
  opacity: 0.2;
}
.ph-duotone.ph-stack-simple:after {
  content: "\f14a";
  margin-left: -1em;
}
.ph-duotone.ph-stairs:before {
  content: "\f14b";
  opacity: 0.2;
}
.ph-duotone.ph-stairs:after {
  content: "\f14c";
  margin-left: -1em;
}
.ph-duotone.ph-stamp:before {
  content: "\f14d";
  opacity: 0.2;
}
.ph-duotone.ph-stamp:after {
  content: "\f14e";
  margin-left: -1em;
}
.ph-duotone.ph-star-and-crescent:before {
  content: "\f14f";
  opacity: 0.2;
}
.ph-duotone.ph-star-and-crescent:after {
  content: "\f150";
  margin-left: -1em;
}
.ph-duotone.ph-star:before {
  content: "\f151";
  opacity: 0.2;
}
.ph-duotone.ph-star:after {
  content: "\f152";
  margin-left: -1em;
}
.ph-duotone.ph-star-four:before {
  content: "\f153";
  opacity: 0.2;
}
.ph-duotone.ph-star-four:after {
  content: "\f154";
  margin-left: -1em;
}
.ph-duotone.ph-star-half:before {
  content: "\f155";
  opacity: 0.2;
}
.ph-duotone.ph-star-half:after {
  content: "\f156";
  margin-left: -1em;
}
.ph-duotone.ph-star-of-david:before {
  content: "\f157";
  opacity: 0.2;
}
.ph-duotone.ph-star-of-david:after {
  content: "\f158";
  margin-left: -1em;
}
.ph-duotone.ph-steering-wheel:before {
  content: "\f159";
  opacity: 0.2;
}
.ph-duotone.ph-steering-wheel:after {
  content: "\f15a";
  margin-left: -1em;
}
.ph-duotone.ph-steps:before {
  content: "\f15b";
  opacity: 0.2;
}
.ph-duotone.ph-steps:after {
  content: "\f15c";
  margin-left: -1em;
}
.ph-duotone.ph-stethoscope:before {
  content: "\f15d";
  opacity: 0.2;
}
.ph-duotone.ph-stethoscope:after {
  content: "\f15e";
  margin-left: -1em;
}
.ph-duotone.ph-sticker:before {
  content: "\f15f";
  opacity: 0.2;
}
.ph-duotone.ph-sticker:after {
  content: "\f160";
  margin-left: -1em;
}
.ph-duotone.ph-stool:before {
  content: "\f161";
  opacity: 0.2;
}
.ph-duotone.ph-stool:after {
  content: "\f162";
  margin-left: -1em;
}
.ph-duotone.ph-stop-circle:before {
  content: "\f163";
  opacity: 0.2;
}
.ph-duotone.ph-stop-circle:after {
  content: "\f164";
  margin-left: -1em;
}
.ph-duotone.ph-stop:before {
  content: "\f165";
  opacity: 0.2;
}
.ph-duotone.ph-stop:after {
  content: "\f166";
  margin-left: -1em;
}
.ph-duotone.ph-storefront:before {
  content: "\f167";
  opacity: 0.2;
}
.ph-duotone.ph-storefront:after {
  content: "\f168";
  margin-left: -1em;
}
.ph-duotone.ph-strategy:before {
  content: "\f169";
  opacity: 0.2;
}
.ph-duotone.ph-strategy:after {
  content: "\f16a";
  margin-left: -1em;
}
.ph-duotone.ph-stripe-logo:before {
  content: "\f16b";
  opacity: 0.2;
}
.ph-duotone.ph-stripe-logo:after {
  content: "\f16c";
  margin-left: -1em;
}
.ph-duotone.ph-student:before {
  content: "\f16d";
  opacity: 0.2;
}
.ph-duotone.ph-student:after {
  content: "\f16e";
  margin-left: -1em;
}
.ph-duotone.ph-subtitles:before {
  content: "\f16f";
  opacity: 0.2;
}
.ph-duotone.ph-subtitles:after {
  content: "\f170";
  margin-left: -1em;
}
.ph-duotone.ph-subtract:before {
  content: "\f171";
  opacity: 0.2;
}
.ph-duotone.ph-subtract:after {
  content: "\f172";
  margin-left: -1em;
}
.ph-duotone.ph-subtract-square:before {
  content: "\f173";
  opacity: 0.2;
}
.ph-duotone.ph-subtract-square:after {
  content: "\f174";
  margin-left: -1em;
}
.ph-duotone.ph-suitcase:before {
  content: "\f175";
  opacity: 0.2;
}
.ph-duotone.ph-suitcase:after {
  content: "\f176";
  margin-left: -1em;
}
.ph-duotone.ph-suitcase-rolling:before {
  content: "\f177";
  opacity: 0.2;
}
.ph-duotone.ph-suitcase-rolling:after {
  content: "\f178";
  margin-left: -1em;
}
.ph-duotone.ph-suitcase-simple:before {
  content: "\f179";
  opacity: 0.2;
}
.ph-duotone.ph-suitcase-simple:after {
  content: "\f17a";
  margin-left: -1em;
}
.ph-duotone.ph-sun-dim:before {
  content: "\f17b";
  opacity: 0.2;
}
.ph-duotone.ph-sun-dim:after {
  content: "\f17c";
  margin-left: -1em;
}
.ph-duotone.ph-sun:before {
  content: "\f17d";
  opacity: 0.2;
}
.ph-duotone.ph-sun:after {
  content: "\f17e";
  margin-left: -1em;
}
.ph-duotone.ph-sunglasses:before {
  content: "\f17f";
  opacity: 0.2;
}
.ph-duotone.ph-sunglasses:after {
  content: "\f180";
  margin-left: -1em;
}
.ph-duotone.ph-sun-horizon:before {
  content: "\f181";
  opacity: 0.2;
}
.ph-duotone.ph-sun-horizon:after {
  content: "\f182";
  margin-left: -1em;
}
.ph-duotone.ph-swap:before {
  content: "\f183";
  opacity: 0.2;
}
.ph-duotone.ph-swap:after {
  content: "\f184";
  margin-left: -1em;
}
.ph-duotone.ph-swatches:before {
  content: "\f185";
  opacity: 0.2;
}
.ph-duotone.ph-swatches:after {
  content: "\f186";
  margin-left: -1em;
}
.ph-duotone.ph-swimming-pool:before {
  content: "\f187";
  opacity: 0.2;
}
.ph-duotone.ph-swimming-pool:after {
  content: "\f188";
  margin-left: -1em;
}
.ph-duotone.ph-sword:before {
  content: "\f189";
  opacity: 0.2;
}
.ph-duotone.ph-sword:after {
  content: "\f18a";
  margin-left: -1em;
}
.ph-duotone.ph-synagogue:before {
  content: "\f18b";
  opacity: 0.2;
}
.ph-duotone.ph-synagogue:after {
  content: "\f18c";
  margin-left: -1em;
}
.ph-duotone.ph-syringe:before {
  content: "\f18d";
  opacity: 0.2;
}
.ph-duotone.ph-syringe:after {
  content: "\f18e";
  margin-left: -1em;
}
.ph-duotone.ph-table:before {
  content: "\f18f";
  opacity: 0.2;
}
.ph-duotone.ph-table:after {
  content: "\f190";
  margin-left: -1em;
}
.ph-duotone.ph-tabs:before {
  content: "\f191";
  opacity: 0.2;
}
.ph-duotone.ph-tabs:after {
  content: "\f192";
  margin-left: -1em;
}
.ph-duotone.ph-tag-chevron:before {
  content: "\f193";
  opacity: 0.2;
}
.ph-duotone.ph-tag-chevron:after {
  content: "\f194";
  margin-left: -1em;
}
.ph-duotone.ph-tag:before {
  content: "\f195";
  opacity: 0.2;
}
.ph-duotone.ph-tag:after {
  content: "\f196";
  margin-left: -1em;
}
.ph-duotone.ph-tag-simple:before {
  content: "\f197";
  opacity: 0.2;
}
.ph-duotone.ph-tag-simple:after {
  content: "\f198";
  margin-left: -1em;
}
.ph-duotone.ph-target:before {
  content: "\f199";
  opacity: 0.2;
}
.ph-duotone.ph-target:after {
  content: "\f19a";
  margin-left: -1em;
}
.ph-duotone.ph-taxi:before {
  content: "\f19b";
  opacity: 0.2;
}
.ph-duotone.ph-taxi:after {
  content: "\f19c";
  margin-left: -1em;
}
.ph-duotone.ph-telegram-logo:before {
  content: "\f19d";
  opacity: 0.2;
}
.ph-duotone.ph-telegram-logo:after {
  content: "\f19e";
  margin-left: -1em;
}
.ph-duotone.ph-television:before {
  content: "\f19f";
  opacity: 0.2;
}
.ph-duotone.ph-television:after {
  content: "\f1a0";
  margin-left: -1em;
}
.ph-duotone.ph-television-simple:before {
  content: "\f1a1";
  opacity: 0.2;
}
.ph-duotone.ph-television-simple:after {
  content: "\f1a2";
  margin-left: -1em;
}
.ph-duotone.ph-tennis-ball:before {
  content: "\f1a3";
  opacity: 0.2;
}
.ph-duotone.ph-tennis-ball:after {
  content: "\f1a4";
  margin-left: -1em;
}
.ph-duotone.ph-tent:before {
  content: "\f1a5";
  opacity: 0.2;
}
.ph-duotone.ph-tent:after {
  content: "\f1a6";
  margin-left: -1em;
}
.ph-duotone.ph-terminal:before {
  content: "\f1a7";
  opacity: 0.2;
}
.ph-duotone.ph-terminal:after {
  content: "\f1a8";
  margin-left: -1em;
}
.ph-duotone.ph-terminal-window:before {
  content: "\f1a9";
  opacity: 0.2;
}
.ph-duotone.ph-terminal-window:after {
  content: "\f1aa";
  margin-left: -1em;
}
.ph-duotone.ph-test-tube:before {
  content: "\f1ab";
  opacity: 0.2;
}
.ph-duotone.ph-test-tube:after {
  content: "\f1ac";
  margin-left: -1em;
}
.ph-duotone.ph-text-aa:before {
  content: "\f1ad";
  opacity: 0.2;
}
.ph-duotone.ph-text-aa:after {
  content: "\f1ae";
  margin-left: -1em;
}
.ph-duotone.ph-text-align-center:before {
  content: "\f1af";
  opacity: 0.2;
}
.ph-duotone.ph-text-align-center:after {
  content: "\f1b0";
  margin-left: -1em;
}
.ph-duotone.ph-text-align-justify:before {
  content: "\f1b1";
  opacity: 0.2;
}
.ph-duotone.ph-text-align-justify:after {
  content: "\f1b2";
  margin-left: -1em;
}
.ph-duotone.ph-text-align-left:before {
  content: "\f1b3";
  opacity: 0.2;
}
.ph-duotone.ph-text-align-left:after {
  content: "\f1b4";
  margin-left: -1em;
}
.ph-duotone.ph-text-align-right:before {
  content: "\f1b5";
  opacity: 0.2;
}
.ph-duotone.ph-text-align-right:after {
  content: "\f1b6";
  margin-left: -1em;
}
.ph-duotone.ph-text-a-underline:before {
  content: "\f1b7";
  opacity: 0.2;
}
.ph-duotone.ph-text-a-underline:after {
  content: "\f1b8";
  margin-left: -1em;
}
.ph-duotone.ph-text-b:before, .ph-duotone.ph-text-bolder:before {
  content: "\f1b9";
  opacity: 0.2;
}
.ph-duotone.ph-text-b:after, .ph-duotone.ph-text-bolder:after {
  content: "\f1ba";
  margin-left: -1em;
}
.ph-duotone.ph-textbox:before {
  content: "\f1bb";
  opacity: 0.2;
}
.ph-duotone.ph-textbox:after {
  content: "\f1bc";
  margin-left: -1em;
}
.ph-duotone.ph-text-columns:before {
  content: "\f1bd";
  opacity: 0.2;
}
.ph-duotone.ph-text-columns:after {
  content: "\f1be";
  margin-left: -1em;
}
.ph-duotone.ph-text-h:before {
  content: "\f1bf";
  opacity: 0.2;
}
.ph-duotone.ph-text-h:after {
  content: "\f1c0";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-five:before {
  content: "\f1c1";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-five:after {
  content: "\f1c2";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-four:before {
  content: "\f1c3";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-four:after {
  content: "\f1c4";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-one:before {
  content: "\f1c5";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-one:after {
  content: "\f1c6";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-six:before {
  content: "\f1c7";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-six:after {
  content: "\f1c8";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-three:before {
  content: "\f1c9";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-three:after {
  content: "\f1ca";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-two:before {
  content: "\f1cb";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-two:after {
  content: "\f1cc";
  margin-left: -1em;
}
.ph-duotone.ph-text-indent:before {
  content: "\f1cd";
  opacity: 0.2;
}
.ph-duotone.ph-text-indent:after {
  content: "\f1ce";
  margin-left: -1em;
}
.ph-duotone.ph-text-italic:before {
  content: "\f1cf";
  opacity: 0.2;
}
.ph-duotone.ph-text-italic:after {
  content: "\f1d0";
  margin-left: -1em;
}
.ph-duotone.ph-text-outdent:before {
  content: "\f1d1";
  opacity: 0.2;
}
.ph-duotone.ph-text-outdent:after {
  content: "\f1d2";
  margin-left: -1em;
}
.ph-duotone.ph-text-strikethrough:before {
  content: "\f1d3";
  opacity: 0.2;
}
.ph-duotone.ph-text-strikethrough:after {
  content: "\f1d4";
  margin-left: -1em;
}
.ph-duotone.ph-text-t:before {
  content: "\f1d5";
  opacity: 0.2;
}
.ph-duotone.ph-text-t:after {
  content: "\f1d6";
  margin-left: -1em;
}
.ph-duotone.ph-text-underline:before {
  content: "\f1d7";
  opacity: 0.2;
}
.ph-duotone.ph-text-underline:after {
  content: "\f1d8";
  margin-left: -1em;
}
.ph-duotone.ph-thermometer-cold:before {
  content: "\f1d9";
  opacity: 0.2;
}
.ph-duotone.ph-thermometer-cold:after {
  content: "\f1da";
  margin-left: -1em;
}
.ph-duotone.ph-thermometer:before {
  content: "\f1db";
  opacity: 0.2;
}
.ph-duotone.ph-thermometer:after {
  content: "\f1dc";
  margin-left: -1em;
}
.ph-duotone.ph-thermometer-hot:before {
  content: "\f1dd";
  opacity: 0.2;
}
.ph-duotone.ph-thermometer-hot:after {
  content: "\f1de";
  margin-left: -1em;
}
.ph-duotone.ph-thermometer-simple:before {
  content: "\f1df";
  opacity: 0.2;
}
.ph-duotone.ph-thermometer-simple:after {
  content: "\f1e0";
  margin-left: -1em;
}
.ph-duotone.ph-thumbs-down:before {
  content: "\f1e1";
  opacity: 0.2;
}
.ph-duotone.ph-thumbs-down:after {
  content: "\f1e2";
  margin-left: -1em;
}
.ph-duotone.ph-thumbs-up:before {
  content: "\f1e3";
  opacity: 0.2;
}
.ph-duotone.ph-thumbs-up:after {
  content: "\f1e4";
  margin-left: -1em;
}
.ph-duotone.ph-ticket:before {
  content: "\f1e5";
  opacity: 0.2;
}
.ph-duotone.ph-ticket:after {
  content: "\f1e6";
  margin-left: -1em;
}
.ph-duotone.ph-tidal-logo:before {
  content: "\f1e7";
  opacity: 0.2;
}
.ph-duotone.ph-tidal-logo:after {
  content: "\f1e8";
  margin-left: -1em;
}
.ph-duotone.ph-tiktok-logo:before {
  content: "\f1e9";
  opacity: 0.2;
}
.ph-duotone.ph-tiktok-logo:after {
  content: "\f1ea";
  margin-left: -1em;
}
.ph-duotone.ph-timer:before {
  content: "\f1eb";
  opacity: 0.2;
}
.ph-duotone.ph-timer:after {
  content: "\f1ec";
  margin-left: -1em;
}
.ph-duotone.ph-tipi:before {
  content: "\f1ed";
  opacity: 0.2;
}
.ph-duotone.ph-tipi:after {
  content: "\f1ee";
  margin-left: -1em;
}
.ph-duotone.ph-toggle-left:before {
  content: "\f1ef";
  opacity: 0.2;
}
.ph-duotone.ph-toggle-left:after {
  content: "\f1f0";
  margin-left: -1em;
}
.ph-duotone.ph-toggle-right:before {
  content: "\f1f1";
  opacity: 0.2;
}
.ph-duotone.ph-toggle-right:after {
  content: "\f1f2";
  margin-left: -1em;
}
.ph-duotone.ph-toilet:before {
  content: "\f1f3";
  opacity: 0.2;
}
.ph-duotone.ph-toilet:after {
  content: "\f1f4";
  margin-left: -1em;
}
.ph-duotone.ph-toilet-paper:before {
  content: "\f1f5";
  opacity: 0.2;
}
.ph-duotone.ph-toilet-paper:after {
  content: "\f1f6";
  margin-left: -1em;
}
.ph-duotone.ph-toolbox:before {
  content: "\f1f7";
  opacity: 0.2;
}
.ph-duotone.ph-toolbox:after {
  content: "\f1f8";
  margin-left: -1em;
}
.ph-duotone.ph-tooth:before {
  content: "\f1f9";
  opacity: 0.2;
}
.ph-duotone.ph-tooth:after {
  content: "\f1fa";
  margin-left: -1em;
}
.ph-duotone.ph-tote:before {
  content: "\f1fb";
  opacity: 0.2;
}
.ph-duotone.ph-tote:after {
  content: "\f1fc";
  margin-left: -1em;
}
.ph-duotone.ph-tote-simple:before {
  content: "\f1fd";
  opacity: 0.2;
}
.ph-duotone.ph-tote-simple:after {
  content: "\f1fe";
  margin-left: -1em;
}
.ph-duotone.ph-trademark:before {
  content: "\f1ff";
  opacity: 0.2;
}
.ph-duotone.ph-trademark:after {
  content: "\f200";
  margin-left: -1em;
}
.ph-duotone.ph-trademark-registered:before {
  content: "\f201";
  opacity: 0.2;
}
.ph-duotone.ph-trademark-registered:after {
  content: "\f202";
  margin-left: -1em;
}
.ph-duotone.ph-traffic-cone:before {
  content: "\f203";
  opacity: 0.2;
}
.ph-duotone.ph-traffic-cone:after {
  content: "\f204";
  margin-left: -1em;
}
.ph-duotone.ph-traffic-signal:before {
  content: "\f205";
  opacity: 0.2;
}
.ph-duotone.ph-traffic-signal:after {
  content: "\f206";
  margin-left: -1em;
}
.ph-duotone.ph-traffic-sign:before {
  content: "\f207";
  opacity: 0.2;
}
.ph-duotone.ph-traffic-sign:after {
  content: "\f208";
  margin-left: -1em;
}
.ph-duotone.ph-train:before {
  content: "\f209";
  opacity: 0.2;
}
.ph-duotone.ph-train:after {
  content: "\f20a";
  margin-left: -1em;
}
.ph-duotone.ph-train-regional:before {
  content: "\f20b";
  opacity: 0.2;
}
.ph-duotone.ph-train-regional:after {
  content: "\f20c";
  margin-left: -1em;
}
.ph-duotone.ph-train-simple:before {
  content: "\f20d";
  opacity: 0.2;
}
.ph-duotone.ph-train-simple:after {
  content: "\f20e";
  margin-left: -1em;
}
.ph-duotone.ph-tram:before {
  content: "\f20f";
  opacity: 0.2;
}
.ph-duotone.ph-tram:after {
  content: "\f210";
  margin-left: -1em;
}
.ph-duotone.ph-translate:before {
  content: "\f211";
  opacity: 0.2;
}
.ph-duotone.ph-translate:after {
  content: "\f212";
  margin-left: -1em;
}
.ph-duotone.ph-trash:before {
  content: "\f213";
  opacity: 0.2;
}
.ph-duotone.ph-trash:after {
  content: "\f214";
  margin-left: -1em;
}
.ph-duotone.ph-trash-simple:before {
  content: "\f215";
  opacity: 0.2;
}
.ph-duotone.ph-trash-simple:after {
  content: "\f216";
  margin-left: -1em;
}
.ph-duotone.ph-tray:before {
  content: "\f217";
  opacity: 0.2;
}
.ph-duotone.ph-tray:after {
  content: "\f218";
  margin-left: -1em;
}
.ph-duotone.ph-tree:before {
  content: "\f219";
  opacity: 0.2;
}
.ph-duotone.ph-tree:after {
  content: "\f21a";
  margin-left: -1em;
}
.ph-duotone.ph-tree-evergreen:before {
  content: "\f21b";
  opacity: 0.2;
}
.ph-duotone.ph-tree-evergreen:after {
  content: "\f21c";
  margin-left: -1em;
}
.ph-duotone.ph-tree-palm:before {
  content: "\f21d";
  opacity: 0.2;
}
.ph-duotone.ph-tree-palm:after {
  content: "\f21e";
  margin-left: -1em;
}
.ph-duotone.ph-tree-structure:before {
  content: "\f21f";
  opacity: 0.2;
}
.ph-duotone.ph-tree-structure:after {
  content: "\f220";
  margin-left: -1em;
}
.ph-duotone.ph-trend-down:before {
  content: "\f221";
  opacity: 0.2;
}
.ph-duotone.ph-trend-down:after {
  content: "\f222";
  margin-left: -1em;
}
.ph-duotone.ph-trend-up:before {
  content: "\f223";
  opacity: 0.2;
}
.ph-duotone.ph-trend-up:after {
  content: "\f224";
  margin-left: -1em;
}
.ph-duotone.ph-triangle:before {
  content: "\f225";
  opacity: 0.2;
}
.ph-duotone.ph-triangle:after {
  content: "\f226";
  margin-left: -1em;
}
.ph-duotone.ph-trophy:before {
  content: "\f227";
  opacity: 0.2;
}
.ph-duotone.ph-trophy:after {
  content: "\f228";
  margin-left: -1em;
}
.ph-duotone.ph-truck:before {
  content: "\f229";
  opacity: 0.2;
}
.ph-duotone.ph-truck:after {
  content: "\f22a";
  margin-left: -1em;
}
.ph-duotone.ph-t-shirt:before {
  content: "\f22b";
  opacity: 0.2;
}
.ph-duotone.ph-t-shirt:after {
  content: "\f22c";
  margin-left: -1em;
}
.ph-duotone.ph-twitch-logo:before {
  content: "\f22d";
  opacity: 0.2;
}
.ph-duotone.ph-twitch-logo:after {
  content: "\f22e";
  margin-left: -1em;
}
.ph-duotone.ph-twitter-logo:before {
  content: "\f22f";
  opacity: 0.2;
}
.ph-duotone.ph-twitter-logo:after {
  content: "\f230";
  margin-left: -1em;
}
.ph-duotone.ph-umbrella:before {
  content: "\f231";
  opacity: 0.2;
}
.ph-duotone.ph-umbrella:after {
  content: "\f232";
  margin-left: -1em;
}
.ph-duotone.ph-umbrella-simple:before {
  content: "\f233";
  opacity: 0.2;
}
.ph-duotone.ph-umbrella-simple:after {
  content: "\f234";
  margin-left: -1em;
}
.ph-duotone.ph-unite:before {
  content: "\f235";
  opacity: 0.2;
}
.ph-duotone.ph-unite:after {
  content: "\f236";
  margin-left: -1em;
}
.ph-duotone.ph-unite-square:before {
  content: "\f237";
  opacity: 0.2;
}
.ph-duotone.ph-unite-square:after {
  content: "\f238";
  margin-left: -1em;
}
.ph-duotone.ph-upload:before {
  content: "\f239";
  opacity: 0.2;
}
.ph-duotone.ph-upload:after {
  content: "\f23a";
  margin-left: -1em;
}
.ph-duotone.ph-upload-simple:before {
  content: "\f23b";
  opacity: 0.2;
}
.ph-duotone.ph-upload-simple:after {
  content: "\f23c";
  margin-left: -1em;
}
.ph-duotone.ph-usb:before {
  content: "\f23d";
  opacity: 0.2;
}
.ph-duotone.ph-usb:after {
  content: "\f23e";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle:before {
  content: "\f23f";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle:after {
  content: "\f240";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle-gear:before {
  content: "\f241";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle-gear:after {
  content: "\f242";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle-minus:before {
  content: "\f243";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle-minus:after {
  content: "\f244";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle-plus:before {
  content: "\f245";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle-plus:after {
  content: "\f246";
  margin-left: -1em;
}
.ph-duotone.ph-user:before {
  content: "\f247";
  opacity: 0.2;
}
.ph-duotone.ph-user:after {
  content: "\f248";
  margin-left: -1em;
}
.ph-duotone.ph-user-focus:before {
  content: "\f249";
  opacity: 0.2;
}
.ph-duotone.ph-user-focus:after {
  content: "\f24a";
  margin-left: -1em;
}
.ph-duotone.ph-user-gear:before {
  content: "\f24b";
  opacity: 0.2;
}
.ph-duotone.ph-user-gear:after {
  content: "\f24c";
  margin-left: -1em;
}
.ph-duotone.ph-user-list:before {
  content: "\f24d";
  opacity: 0.2;
}
.ph-duotone.ph-user-list:after {
  content: "\f24e";
  margin-left: -1em;
}
.ph-duotone.ph-user-minus:before {
  content: "\f24f";
  opacity: 0.2;
}
.ph-duotone.ph-user-minus:after {
  content: "\f250";
  margin-left: -1em;
}
.ph-duotone.ph-user-plus:before {
  content: "\f251";
  opacity: 0.2;
}
.ph-duotone.ph-user-plus:after {
  content: "\f252";
  margin-left: -1em;
}
.ph-duotone.ph-user-rectangle:before {
  content: "\f253";
  opacity: 0.2;
}
.ph-duotone.ph-user-rectangle:after {
  content: "\f254";
  margin-left: -1em;
}
.ph-duotone.ph-users:before {
  content: "\f255";
  opacity: 0.2;
}
.ph-duotone.ph-users:after {
  content: "\f256";
  margin-left: -1em;
}
.ph-duotone.ph-users-four:before {
  content: "\f257";
  opacity: 0.2;
}
.ph-duotone.ph-users-four:after {
  content: "\f258";
  margin-left: -1em;
}
.ph-duotone.ph-user-square:before {
  content: "\f259";
  opacity: 0.2;
}
.ph-duotone.ph-user-square:after {
  content: "\f25a";
  margin-left: -1em;
}
.ph-duotone.ph-users-three:before {
  content: "\f25b";
  opacity: 0.2;
}
.ph-duotone.ph-users-three:after {
  content: "\f25c";
  margin-left: -1em;
}
.ph-duotone.ph-user-switch:before {
  content: "\f25d";
  opacity: 0.2;
}
.ph-duotone.ph-user-switch:after {
  content: "\f25e";
  margin-left: -1em;
}
.ph-duotone.ph-van:before {
  content: "\f25f";
  opacity: 0.2;
}
.ph-duotone.ph-van:after {
  content: "\f260";
  margin-left: -1em;
}
.ph-duotone.ph-vault:before {
  content: "\f261";
  opacity: 0.2;
}
.ph-duotone.ph-vault:after {
  content: "\f262";
  margin-left: -1em;
}
.ph-duotone.ph-vibrate:before {
  content: "\f263";
  opacity: 0.2;
}
.ph-duotone.ph-vibrate:after {
  content: "\f264";
  margin-left: -1em;
}
.ph-duotone.ph-video-camera:before {
  content: "\f265";
  opacity: 0.2;
}
.ph-duotone.ph-video-camera:after {
  content: "\f266";
  margin-left: -1em;
}
.ph-duotone.ph-video-camera-slash:before {
  content: "\f267";
  opacity: 0.2;
}
.ph-duotone.ph-video-camera-slash:after {
  content: "\f268";
  margin-left: -1em;
}
.ph-duotone.ph-video:before {
  content: "\f269";
  opacity: 0.2;
}
.ph-duotone.ph-video:after {
  content: "\f26a";
  margin-left: -1em;
}
.ph-duotone.ph-vignette:before {
  content: "\f26b";
  opacity: 0.2;
}
.ph-duotone.ph-vignette:after {
  content: "\f26c";
  margin-left: -1em;
}
.ph-duotone.ph-vinyl-record:before {
  content: "\f26d";
  opacity: 0.2;
}
.ph-duotone.ph-vinyl-record:after {
  content: "\f26e";
  margin-left: -1em;
}
.ph-duotone.ph-virtual-reality:before {
  content: "\f26f";
  opacity: 0.2;
}
.ph-duotone.ph-virtual-reality:after {
  content: "\f270";
  margin-left: -1em;
}
.ph-duotone.ph-virus:before {
  content: "\f271";
  opacity: 0.2;
}
.ph-duotone.ph-virus:after {
  content: "\f272";
  margin-left: -1em;
}
.ph-duotone.ph-voicemail:before {
  content: "\f273";
  opacity: 0.2;
}
.ph-duotone.ph-voicemail:after {
  content: "\f274";
  margin-left: -1em;
}
.ph-duotone.ph-volleyball:before {
  content: "\f275";
  opacity: 0.2;
}
.ph-duotone.ph-volleyball:after {
  content: "\f276";
  margin-left: -1em;
}
.ph-duotone.ph-wall:before {
  content: "\f277";
  opacity: 0.2;
}
.ph-duotone.ph-wall:after {
  content: "\f278";
  margin-left: -1em;
}
.ph-duotone.ph-wallet:before {
  content: "\f279";
  opacity: 0.2;
}
.ph-duotone.ph-wallet:after {
  content: "\f27a";
  margin-left: -1em;
}
.ph-duotone.ph-warehouse:before {
  content: "\f27b";
  opacity: 0.2;
}
.ph-duotone.ph-warehouse:after {
  content: "\f27c";
  margin-left: -1em;
}
.ph-duotone.ph-warning-circle:before {
  content: "\f27d";
  opacity: 0.2;
}
.ph-duotone.ph-warning-circle:after {
  content: "\f27e";
  margin-left: -1em;
}
.ph-duotone.ph-warning-diamond:before {
  content: "\f27f";
  opacity: 0.2;
}
.ph-duotone.ph-warning-diamond:after {
  content: "\f280";
  margin-left: -1em;
}
.ph-duotone.ph-warning:before {
  content: "\f281";
  opacity: 0.2;
}
.ph-duotone.ph-warning:after {
  content: "\f282";
  margin-left: -1em;
}
.ph-duotone.ph-warning-octagon:before {
  content: "\f283";
  opacity: 0.2;
}
.ph-duotone.ph-warning-octagon:after {
  content: "\f284";
  margin-left: -1em;
}
.ph-duotone.ph-watch:before {
  content: "\f285";
  opacity: 0.2;
}
.ph-duotone.ph-watch:after {
  content: "\f286";
  margin-left: -1em;
}
.ph-duotone.ph-waveform:before {
  content: "\f287";
  opacity: 0.2;
}
.ph-duotone.ph-waveform:after {
  content: "\f288";
  margin-left: -1em;
}
.ph-duotone.ph-wave-sawtooth:before {
  content: "\f289";
  opacity: 0.2;
}
.ph-duotone.ph-wave-sawtooth:after {
  content: "\f28a";
  margin-left: -1em;
}
.ph-duotone.ph-waves:before {
  content: "\f28b";
  opacity: 0.2;
}
.ph-duotone.ph-waves:after {
  content: "\f28c";
  margin-left: -1em;
}
.ph-duotone.ph-wave-sine:before {
  content: "\f28d";
  opacity: 0.2;
}
.ph-duotone.ph-wave-sine:after {
  content: "\f28e";
  margin-left: -1em;
}
.ph-duotone.ph-wave-square:before {
  content: "\f28f";
  opacity: 0.2;
}
.ph-duotone.ph-wave-square:after {
  content: "\f290";
  margin-left: -1em;
}
.ph-duotone.ph-wave-triangle:before {
  content: "\f291";
  opacity: 0.2;
}
.ph-duotone.ph-wave-triangle:after {
  content: "\f292";
  margin-left: -1em;
}
.ph-duotone.ph-webcam:before {
  content: "\f293";
  opacity: 0.2;
}
.ph-duotone.ph-webcam:after {
  content: "\f294";
  margin-left: -1em;
}
.ph-duotone.ph-webcam-slash:before {
  content: "\f295";
  opacity: 0.2;
}
.ph-duotone.ph-webcam-slash:after {
  content: "\f296";
  margin-left: -1em;
}
.ph-duotone.ph-webhooks-logo:before {
  content: "\f297";
  opacity: 0.2;
}
.ph-duotone.ph-webhooks-logo:after {
  content: "\f298";
  margin-left: -1em;
}
.ph-duotone.ph-wechat-logo:before {
  content: "\f299";
  opacity: 0.2;
}
.ph-duotone.ph-wechat-logo:after {
  content: "\f29a";
  margin-left: -1em;
}
.ph-duotone.ph-whatsapp-logo:before {
  content: "\f29b";
  opacity: 0.2;
}
.ph-duotone.ph-whatsapp-logo:after {
  content: "\f29c";
  margin-left: -1em;
}
.ph-duotone.ph-wheelchair:before {
  content: "\f29d";
  opacity: 0.2;
}
.ph-duotone.ph-wheelchair:after {
  content: "\f29e";
  margin-left: -1em;
}
.ph-duotone.ph-wheelchair-motion:before {
  content: "\f29f";
  opacity: 0.2;
}
.ph-duotone.ph-wheelchair-motion:after {
  content: "\f2a0";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-high:before {
  content: "\f2a1";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-high:after {
  content: "\f2a2";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-low:before {
  content: "\f2a3";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-low:after {
  content: "\f2a4";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-medium:before {
  content: "\f2a5";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-medium:after {
  content: "\f2a6";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-none-duotone:before {
  content: "\f2a7";
}
.ph-duotone.ph-wifi-slash:before {
  content: "\f2a8";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-slash:after {
  content: "\f2a9";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-x:before {
  content: "\f2aa";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-x:after {
  content: "\f2ab";
  margin-left: -1em;
}
.ph-duotone.ph-wind:before {
  content: "\f2ac";
  opacity: 0.2;
}
.ph-duotone.ph-wind:after {
  content: "\f2ad";
  margin-left: -1em;
}
.ph-duotone.ph-windows-logo:before {
  content: "\f2ae";
  opacity: 0.2;
}
.ph-duotone.ph-windows-logo:after {
  content: "\f2af";
  margin-left: -1em;
}
.ph-duotone.ph-wine:before {
  content: "\f2b0";
  opacity: 0.2;
}
.ph-duotone.ph-wine:after {
  content: "\f2b1";
  margin-left: -1em;
}
.ph-duotone.ph-wrench:before {
  content: "\f2b2";
  opacity: 0.2;
}
.ph-duotone.ph-wrench:after {
  content: "\f2b3";
  margin-left: -1em;
}
.ph-duotone.ph-x-circle:before {
  content: "\f2b4";
  opacity: 0.2;
}
.ph-duotone.ph-x-circle:after {
  content: "\f2b5";
  margin-left: -1em;
}
.ph-duotone.ph-x:before {
  content: "\f2b6";
  opacity: 0.2;
}
.ph-duotone.ph-x:after {
  content: "\f2b7";
  margin-left: -1em;
}
.ph-duotone.ph-x-square:before {
  content: "\f2b8";
  opacity: 0.2;
}
.ph-duotone.ph-x-square:after {
  content: "\f2b9";
  margin-left: -1em;
}
.ph-duotone.ph-yin-yang:before {
  content: "\f2ba";
  opacity: 0.2;
}
.ph-duotone.ph-yin-yang:after {
  content: "\f2bb";
  margin-left: -1em;
}
.ph-duotone.ph-youtube-logo:before {
  content: "\f2bc";
  opacity: 0.2;
}
.ph-duotone.ph-youtube-logo:after {
  content: "\f2bd";
  margin-left: -1em;
}
